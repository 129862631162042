import React, { useCallback, useEffect, useState } from "react";
import "./Adminkursdetails.css";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import TimeSelector from "../../../components/TimeSelector";
import AddInfoSection from "../../../components/admin/kursdetails/AddInfoSection";
import { useGetSchulungByIDQuery, useUpdateSchulungMutation, useDeleteSchulungMutation, useGetSchulungenQuery } from "../../../features/schulungen/admin/adminSchulungenSlice";
import { useDeleteInfoBereichMutation } from "../../../features/schulungen/admin/adminInfoSectionsSlice";
import { useLoading } from "../../../components/LoadingSpinner/LoadingContext";
import AddFahrerToSchulung from "../fahrerverwaltung/AddFahrerToSchulung";
import { useSelector } from "react-redux";
import { selectAllSchulungen } from "../../../features/schulungen/admin/adminSchulungenSlice";
import { useTranslation } from "react-i18next";
import AddSchulungsBeschreibung from "../../../components/admin/kursdetails/ChangeCertificateText";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
const AdminKursdetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    //Methods
    const { data: schulung, error, isLoading, isSuccess, isError } = useGetSchulungByIDQuery(id);
    const [updateSchulung, { isLoading: isUpdating }] = useUpdateSchulungMutation()
    const [deleteSchulung, { isLoading: isDeleting, isSuccess: isDeletingSuccess }] = useDeleteSchulungMutation()
    const [deleteInformationsbereich, { isLoading: isDeletingInformationsbereich }] = useDeleteInfoBereichMutation()


    //Modal
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isAddingModalOpen, setIsAddingModalOpen] = useState(false);
    const [editingInfoBereich, setEditingInfoBereich] = useState(null);
    const [editingMode, setEditingMode] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // Zustand für das Modal
    const [timer, setTimer] = useState(5); //Deleting timer
    const [showNameChangePopup, setShowNameChangePopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showEditBeschreibungPopup, setShowEditBeschreibungPopup] = useState(false);

    const [selectedInformationsbereich, setSelectedInformationsbereich] = useState(null);
    const [newSchulungsname, setNewSchulungsname] = useState("");
    const [gueltigkeitsdauer, setGueltigkeitsdauer] = useState("");
    const [informationsbereiche, setInformationsbereiche] = useState([]); // Zustand für die Informationsbereiche
    const [t] = useTranslation('common');

    //Loading context
    const { startLoading, stopLoading } = useLoading();

    useEffect(() => {
        if (isLoading || isUpdating || isDeleting || isDeletingInformationsbereich) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoading, isUpdating, isDeleting, isDeletingInformationsbereich, startLoading, stopLoading]);

    useEffect(() => {
        if (isError) {
            navigate("/admin");
        }
    }, [isError, navigate])

    useEffect(() => {
        if (isDeletingSuccess)
            navigate("/admin");
    }, [isDeletingSuccess, navigate])


    useEffect(() => {
        if (isSuccess)
            setInformationsbereiche(schulung.informationsbereiche)
    }, [isSuccess, schulung])


    useEffect(() => {
        if (schulung)
            setGueltigkeitsdauer(schulung.gueltigkeitsdauer)
    }, [schulung])

    const handleChangeGueltigkeitsDauer = async (newGueltigkeitsDauer) => {
        if (schulung) {
            updateSchulung({ ...schulung, gueltigkeitsdauer: newGueltigkeitsDauer });
        }
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(informationsbereiche);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        console.log('Reordered items:', items);  // Logging
        setInformationsbereiche(items);
    };


    const NameChangePopup = ({ onClose, onSave, isVorgehendeSchulung = false, vorgehendeSchulung }) => {
        const [localNewSchulungsname, setLocalNewSchulungsname] = useState("");
        const [selectedVorgehendeSchulung, setSelectedVorgehendeSchulung] = useState(vorgehendeSchulung || "default");
        const schulungen = useSelector(selectAllSchulungen);
        const handleSave = () => {
            if (isVorgehendeSchulung) {
                onSave(selectedVorgehendeSchulung);
            } else {
                onSave(localNewSchulungsname);
            }
            onClose();
        };

        return (
            <div className="akd-name-change-popup-overlay">
                <div className="akd-name-change-popup">
                    <div>
                        {isVorgehendeSchulung ? "Vorgehende Schulung ändern:" : t("admin.pages.pages-ape-name-aendern")}
                    </div>

                    {isVorgehendeSchulung && isSuccess ?
                        <select
                            value={selectedVorgehendeSchulung}
                            onChange={(e) => setSelectedVorgehendeSchulung(e.target.value)}
                        >
                            <option value="default" disabled>Vorgehende Schulung</option>
                            <option value="none">Keine</option>
                            {schulungen.filter(option => option.name !== schulung.name)
                                .map((option, index) => (
                                    <option key={index} value={option.id}>{option.name}</option>
                                ))}
                        </select>
                        :
                        <input
                            type="text"
                            value={localNewSchulungsname}
                            maxLength={110} // Setzt das Zeichenlimit auf 100
                            onChange={(e) => setLocalNewSchulungsname(e.target.value)}
                        />
                    }
                    <button onClick={handleSave}>{t("admin.pages.pages-afrv-Speichern")}</button>
                    <button onClick={onClose}>{t("admin.afrv.afrv-aq-Abbrechen")}</button>
                </div>
            </div>
        );
    };


    // eslint-disable-next-line
    const handleUpdateSchulungsname = useCallback((name) => {
        if (schulung) {
            updateSchulung({ ...schulung, name });
        }
    });

    // eslint-disable-next-line
    const handleUpdateVorgehendeSchulung = useCallback((id) => {
        if (schulung) {
            updateSchulung({ ...schulung, vorgehendeSchulung: { id } });
        }
    });




    const handleDeleteInformationsbereiche = (infoID) => {
        var informationsbereichToDelete = { schulungID: schulung.id, informationsbereichID: infoID };
        deleteInformationsbereich(informationsbereichToDelete);
    }


    // Funktion zum Öffnen des Bearbeitungsmodals und Setzen des zu bearbeitenden Informationsbereichs
    const handleEditInformationsbereich = (informationsbereich) => {
        setEditingInfoBereich(informationsbereich);
        setIsEditModalOpen(true);
    }


    const DeletePopup = ({ onClose, onDelete }) => {
        useEffect(() => {
            if (timer > 0) {
                const timeout = setTimeout(() => setTimer(timer - 1), 1000);
                return () => clearTimeout(timeout);
            }
        }, []);

        return (
            <div className="akd-delete-popup-overlay">
                <div className="akd-delete-popup">
                    <div>{t("admin.pages.pages-delete-schulung")}</div>
                    {timer > 0 ? <div>{t("admin.pages.pages-delte-wait")} {timer} {t("admin.pages.pages-delete-seconds")}</div> : <button onClick={onDelete}>{t("admin.pages.pages-akd-SchulungLoeschen")}</button>}
                    <button onClick={onClose}>{t("admin.afrv.afrv-aq-Abbrechen")}</button>
                </div>
            </div>
        );
    };


    if (isError) {
        return <div>{error}</div>
    }

    return (
        <div className="akd-admin-kursdetails">
            <Sidebar />
            <div className="akd-inner-window">
                <div className="akd-modul-comp">
                    <b className="akd-modulename"> {t("carrier.fka-Schulungsmodul")} : {schulung?.name}
                    </b>
                </div>
                <div className="akd-inner-section" >
                    <div className="akd-bereich-list" style={{ display: "flex", flexDirection: "column"}}>
                        <div className="akd-info-list-comp" onClick={() => navigate(`/admin/questions/${schulung?.id}`)}>
                            <b className="akd-modulename">{t("admin.pages.pages-akd-PruefungsfragenVerwalten")}</b>
                        </div>
                        <div className="akd-info-list-comp" onClick={() => {
                            setEditingMode("name");
                            setShowNameChangePopup(true);
                        }}>
                            <b className="akd-modulename">{t("admin.pages.pages-akd-SchulungsnameAendern")}</b>
                        </div>
                        <div className="akd-info-list-comp" onClick={() => {
                            setEditingMode("vorgehende");
                            setShowNameChangePopup(true);
                        }}>
                            <b className="akd-modulename">{t("admin.pages.pages-akd-VorgehendeSchulung")}</b>
                        </div>


                        {
                            showNameChangePopup && (
                                <NameChangePopup
                                    onClose={() => {
                                        setShowNameChangePopup(false);
                                        setEditingMode(null); // Optional: Modus zurücksetzen
                                    }}
                                    onSave={editingMode === "name" ? handleUpdateSchulungsname : handleUpdateVorgehendeSchulung}
                                    newSchulungsname={newSchulungsname}
                                    setNewSchulungsname={setNewSchulungsname}
                                    isVorgehendeSchulung={editingMode === "vorgehende"}
                                    vorgehendeSchulung={schulung?.vorhergehendeSchulung?.id}
                                />
                            )
                        }
                        <div className="akd-info-list-comp" onClick={() => { navigate(`/admin/details/results/${schulung?.id}`) }}>
                            <b className="akd-modulename">{t("admin.pages.pages-akd-Pruefungsergebnisse")}</b>
                        </div>
                        <div className="akd-info-list-comp" onClick={() => { setShowEditBeschreibungPopup(true) }}>
                            <b className="akd-modulename">{t("admin.pages.pages-akd-Description")}</b>
                        </div>
                        {showEditBeschreibungPopup && (
                            <AddSchulungsBeschreibung schulungsId={schulung?.id} isEditing={schulung.beschreibung !== null ? true : false} initialSchulung={schulung} onClose={() => { console.log("rsasdAasf"); setShowEditBeschreibungPopup(false); console.log(showEditBeschreibungPopup) }} />)
                        }

                        <div className="akd-info-list-comp" onClick={() => setIsAddingModalOpen(true)}>
                            <b className="akd-modulename">{t("admin.pages.pages-akd-FahrerHinzufuegenEntfernen")}</b>
                        </div>
                        <div className="akd-lower-details-section" style={{flex:1, marginBottom: "10px",}}>
                            <button className="akd-delete-btn" onClick={() => setShowDeletePopup(true)}>
                                <div className="akd-schulung-lschen">{t("admin.pages.pages-akd-SchulungLoeschen")}</div>
                            </button>

                            {showDeletePopup && (
                                <DeletePopup
                                    onClose={() => { setShowDeletePopup(false); setTimer(5); }}
                                    onDelete={() => { deleteSchulung(id); setShowDeletePopup(false); }}
                                />
                            )}
                            <div className="akd-date2">
                                <div className="akd-gltigkeitsdauer-ndern">
                                    {t("admin.pages.pages-akd-Gueltigkeitsdauer")}
                                </div>
                                <TimeSelector setDisplayText={setGueltigkeitsdauer} displayText={gueltigkeitsdauer} handleSave={handleChangeGueltigkeitsDauer} />
                            </div>

                        </div>

                    </div>
                    <div className="akd-actions-list" >
                        <div className="akd-upper-information-section">
                            <div className="akd-label">
                                <b className="akd-informationsbereiche">{t("admin.pages.pages-akd-Informationsbereich")}</b>
                            </div>
                        </div>
                        <div className="akd-frame-parent" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            <div className="akd-menuitems2" style={{ flex: 1, overflowY: "auto" }}>
                                {
                                    schulung && informationsbereiche ?
                                        [...informationsbereiche] // Erstellen Sie eine Kopie des Arrays, um das ursprüngliche Array unverändert zu lassen
                                            .sort((a, b) => a.id - b.id) // Sortieren Sie das Array basierend auf der `id` in aufsteigender Reihenfolge
                                            .map((informationsbereich, index) => (
                                                <div
                                                    onClick={() => {
                                                        if (selectedInformationsbereich === informationsbereich.id)
                                                            setSelectedInformationsbereich(null);
                                                        else
                                                            setSelectedInformationsbereich(informationsbereich.id);
                                                        console.log("change in informationsbereich: " + selectedInformationsbereich);
                                                    }}
                                                    className="akd-menuitem2-1"
                                                >
                                                    <div style={{
                                                        flex: 1,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        flexShrink: 0,
                                                        justifyContent: "center",
                                                        backgroundColor: "#e9e9e9", width: "90%",
                                                        marginBottom: 20,
                                                        boxSizing: "border-box",
                                                        padding: 10,
                                                        height: 80,
                                                        borderRadius: 10,
                                                    }}>
                                                        <div className="akd-grundlagen" style={{ width: "75%" }}>{informationsbereich.title}</div>
                                                        <div style={{ position: "absolute", right: "6%", display: "flex", flexDirection: "row", flex: 1, alignItems: "center", gap: 20 }}>
                                                            <FaEdit onClick={() => handleEditInformationsbereich(informationsbereich)} size={30} color="#434343" />
                                                            <MdDelete onClick={() => handleDeleteInformationsbereiche(informationsbereich.id)} size={30} color="#434343" />
                                                        </div>

                                                    </div>
                                                </div>
                                            )) : null
                                }
                            </div>
                            <button className="akd-delete-btn" onClick={() => setIsModalOpen(true)} style={{ alignSelf: "center", marginBottom: "20px", width: 250, height: 50 }}>
                                <div className="akd-schulung-lschen">{t("admin.pages.pages-akd-Hinzufuegen")}</div>
                            </button>


                        </div>
                    </div>
                </div>
            </div>
            {
                isModalOpen && (
                    <AddInfoSection id={schulung?.id} onClose={() => { setIsModalOpen(false); }} />
                )
            }

            {
                isEditModalOpen && (
                    <AddInfoSection id={schulung?.id} initalInfoBereich={editingInfoBereich} isEditing={true} onClose={() => { setIsEditModalOpen(false); }} />
                )
            }

            {
                isAddingModalOpen && (
                    <AddFahrerToSchulung schulungsId={schulung?.id} schulungsName={schulung?.name} onClose={() => { setIsAddingModalOpen(false); }} />
                )
            }
        </div >
    );
};

export default AdminKursdetails;