import React, { useState, useEffect, useMemo } from 'react';
import { createPortal } from "react-dom";
import Editor from './Editor'; // Ändern Sie den Pfad zu Ihrem Editor
import "./AddInfoSection.css";
import { useTranslation } from "react-i18next";
import { useUpdateSchulungMutation } from '../../../features/schulungen/admin/adminSchulungenSlice';
const AddSchulungsBeschreibung = ({ onClose, id, initialSchulung, isEditing = false }) => {

    const [editorValue, setEditorValue] = useState(isEditing ? initialSchulung.beschreibung : '');
    const [t] = useTranslation('common');
    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            onClose();
        }
    }



    const editorModules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ font: ['Verdana'] }],
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                    ["clean"],
                ],
            },
        };
    }, []);

    const [updateSchulung] = useUpdateSchulungMutation();

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line 
    }, []);

    const handleSave = () => {
        if (!editorValue) {
            alert('Bitte füllen Sie alle Felder aus!');
            return;
        }
        console.log("value", editorValue)
        updateSchulung({ ...initialSchulung, beschreibung: editorValue });
        onClose();
    };

    return createPortal(
        <div className="admin-modal-informationsbereic">
            <div style={{fontSize: 20, fontFamily: "Verdana", fontWeight: "bold"}}>
                {t("admin.pages.pages-akd-Description")}
            </div>
            <div className="ami-mid-section">
                <div className="ami-inner-section">
                    <Editor value={editorValue} isPlainText={true} onChange={setEditorValue} customModules={editorModules} />
                </div>
            </div>
            <div className="ami-buttons">
                <button className="ami-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={() => onClose()}>
                    <div className="ami-uebersichtsnav-btn-text">{t("admin.akd.akd-ais-Zuruek")}</div>
                </button>
                <button className="ami-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={handleSave}>
                    <div className="ami-uebersichtsnav-btn-text">{t("admin.akd.akd-ais-Speichern")}</div>
                </button>
            </div>
        </div>,
        document.getElementById('modal-root')
    );
};

export default AddSchulungsBeschreibung;
