import { useEffect, useState } from "react";
import { useAddNameMutation, useWhoamiQuery } from "../../features/authApiSlice";
import Modal from "react-modal";
import { useLoading } from "../LoadingSpinner/LoadingContext";
import "./Header.css";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        borderRadius: '10px',
        width: '300px',
        boxSizing: 'border-box'
    },
};

const Header = () => {
    const {
        data: user,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch
    } = useWhoamiQuery();
    const [addName, { isLoading: isAdding }] = useAddNameMutation();

    const { startLoading, stopLoading } = useLoading();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    useEffect(() => {
        if (isLoading) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoading, startLoading, stopLoading]);

    useEffect(() => {
        if (user?.username === "KEIN_NAME") {
            setIsOpen(true);
        }
    }, [user]);

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleSave = async () => {
        try {
            await addName({ vorname: firstName, nachname: lastName }).unwrap();
            setIsOpen(false);
            refetch();  // Refetch the whoami query
        } catch (error) {
            console.error("Failed to save name:", error);
        }
    };

    const today = new Date();
    const date = today.getDate().toString().padStart(2, '0');
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // January is 0
    const year = today.getFullYear().toString().substr(-2);

    return (
        <header className="header" style={{ alignItems: "center" }}>
            <div className="profile-section">
                <div className="profile">
                    <div className="profile-picture">
                        <div className="profile-picture-child" />
                        <img
                            className="profile-picture-icon"
                            alt=""
                            src="/profilepicture@2x.png"
                        />
                    </div>
                    <div className="profile-data">
                        <b className="username">{user?.username}</b>
                        <div className="userrole">{user?.authorities[0]}</div>
                        <div className="userrole">{`${date}.${month}.${year}`}</div>
                    </div>
                </div>
            </div>
            <div className="logo">
                <img className="medlog-logo-icon" alt="" src="/medloglogo@2x.png" />
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Enter Name"
            >
                <h2 style={{ textAlign: 'center' }}>Bitte geben Sie ihren Namen ein:</h2>
                <form style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label htmlFor="firstName" style={{ marginBottom: '5px' }}>Vorname:</label>
                        <input
                            type="text"
                            id="firstName"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label htmlFor="lastName" style={{ marginBottom: '5px' }}>Nachname:</label>
                        <input
                            type="text"
                            id="lastName"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <button type="button" onClick={handleSave} style={{ padding: '10px 20px', backgroundColor: '#ffb253', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Speichern</button>
                    </div>
                </form>
            </Modal>
        </header>
    );
};

export default Header;
