import { useEffect, useMemo, useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import "./Rollenverwaltung.css";
import { useTranslation } from "react-i18next";
import { useGetUsersWithRolesQuery } from "../../../features/roles/adminRoleSlice";
import { useLoading } from "../../../components/LoadingSpinner/LoadingContext";
import UserRoleTableComponent from "./UserRoleTableComponent";
import { AiFillCaretDown } from "react-icons/ai";
import { GrCircleInformation } from "react-icons/gr";
import { useWhoamiQuery } from "../../../features/authApiSlice";

const Rollenverwaltung = () => {

    const [fahrerFilter, setFahrerFilter] = useState("");

    const [t, i18n] = useTranslation("common");

    const { data, error, isLoading, isSuccess, isError } = useGetUsersWithRolesQuery()
    const { startLoading, stopLoading } = useLoading();


    const [selectedRole, setSelectedRole] = useState(""); // Changed from "ROLE_SUPER_ADMIN" to ""

    const [filteredUsers, setFilteredUsers] = useState([]);


    useEffect(() => {
        if (isSuccess) {
            setFilteredUsers(data);
        }
    }, [isSuccess, data]);

    const applyFilter = () => {
        const result = mutableData.filter(user => {
            const nameMatch = fahrerFilter ? user.name.toLowerCase().includes(fahrerFilter.toLowerCase()) : true;
            const roleMatch = selectedRole ? user.role === selectedRole : true;
            return nameMatch && roleMatch;
        });
        setFilteredUsers(result);
    };


    useEffect(() => {
        if (isLoading) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoading, startLoading, stopLoading]);

    const mutableData = isSuccess ? [...data] : [];

    const filterUsers = (users, filter, role) => {
        return users.filter(user => {
            if (!filter && !role) {
                return true;
            }

            // Filter by name if there is a fahrerFilter
            const nameMatch = filter ? user.name.toLowerCase().includes(filter.toLowerCase()) : true;
            // Filter by role if there is a selectedRole and no fahrerFilter
            const roleMatch = filter ? true : user.role === role;
            return nameMatch && roleMatch;
        });
    };

    // Get the filtered list of users based on the filters
    const resetFilter = () => {
        setFahrerFilter("");
        setSelectedRole("");
        setFilteredUsers(data);
    };


    return (
        <div className="rvw-container">
            <div className="rvw-inner-window">
                <h3 >{t("admin.rollenverwaltung.rollenverwaltung-ueberschrift")}</h3>

                <div className="rvw-upper-section">
                    <div className="rvw-filter-container">
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", gap: 30, }}>
                            <AutocompleteInput
                                suggestions={mutableData.map(user => user.name)}
                                placeholder={t("admin.rollenverwaltung.rollenverwaltung-filter-mitarbeiter")}
                                value={fahrerFilter}
                                onChange={e => setFahrerFilter(e.target.value)}
                                isFirst={true}
                            />
                        </div>
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", alignItems: "center", height: 30, position: "relative" }}>
                                <select
                                    className="ape-fahrer-input"
                                    style={{
                                        flex: 1, alignSelf: "center", width: "80%", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white", color: "black",
                                        appearance: "none", WebkitAppearance: "none", MozAppearance: "none", paddingRight: '30px', fontFamily: "Verdana" // adjust the right padding to make space for the icon
                                    }}
                                    onChange={e => setSelectedRole(e.target.value)}
                                    value={selectedRole}
                                >
                                    <option style={{ fontFamily: "Verdana" }} value="">Alle</option>
                                    <option style={{ fontFamily: "Verdana" }} value="ROLE_SUPER_ADMIN">Super Admin</option>
                                    <option style={{ fontFamily: "Verdana" }} value="ROLE_ADMIN">Admin</option>
                                    <option style={{ fontFamily: "Verdana" }} value="ROLE_USER">User</option>
                                </select>
                                <div style={{
                                    position: 'absolute',
                                    right: "15%",
                                    top: '55%',
                                    transform: 'translateY(-50%)',
                                    pointerEvents: 'none',
                                }}>
                                    <AiFillCaretDown style={{ color: 'black', fontSize: '16px' }} />
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: 1, display: "flex", alignItems: "end", flexDirection: "column", gap: 20, color: "white", }}>
                            <div className="ape-filter-btn ape-common-btn" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 180, height: 30, cursor: "pointer", fontFamily: "Verdana" }} onClick={applyFilter}>
                                <div className="ape-fahrer">{t("admin.pages.pages-ape-Filtern")}</div>
                            </div>
                            <div className="ape-filter-reset-btn ape-common-btn" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 180, height: 30, cursor: "pointer", fontFamily: "Verdana" }} onClick={resetFilter}>
                                <div className="ape-fahrer">{t("admin.pages.pages-ape-FilterZuruecksetzen")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rvw-section-header">
                    <div style={{ display: "flex", flex: 1, padding: 10 }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", borderRight: "solid 2px black", fontFamily: "Verdana" }}>
                            {t("admin.rollenverwaltung.rollenverwaltung-mitarbeitername")}
                        </div>
                        <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", borderRight: "solid 2px black", fontFamily: "Verdana" }}>
                            {t("admin.rollenverwaltung.rollenverwaltung-letzterZugriff")}
                        </div>
                        <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", borderRight: "solid 2px black", fontFamily: "Verdana" }}>
                            {t("admin.rollenverwaltung.rollenverwaltung-rolle")}
                            <div>
                                <GrCircleInformation className="icon" style={{ marginLeft: 5, color: 'black', fontSize: '24px', cursor: "pointer" }} />
                                <div className="tooltip">Super-Admin hat Zugriff auf die Rollenverwaltung und hat alle Rechte auf der Plattform. Super-Admin kann keine Phishing Mails erhalten.<br></br><br></br>
                                    Admin kann Schulungen erstellen und bearbeiten. Kann auch an Schulungen teilnehmen<br></br><br></br>
                                    User kann an Schulungen teilnehmen und kann nichts bearbeiten
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", fontFamily: "Verdana" }}>
                            {t("admin.rollenverwaltung.rollenverwaltung-Status")}
                        </div>
                    </div>
                </div>
                <div className="rvw-section" style={{ overflowY: "auto" }}>
                    {isSuccess && [...filteredUsers]
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sorting in ascending order by `username`
                        .map((user, index) => (
                            <UserRoleTableComponent user={user} key={user.id} index={index} />
                        ))}
                </div>
            </div>
            <Header />
            <Sidebar />
        </div >
    );
}



const AutocompleteInput = ({ suggestions, placeholder, value, onChange, isFirst }) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [userInput, setUserInput] = useState("");

    const onInputChange = e => {
        const userInput = e.target.value;

        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        setFilteredSuggestions(filteredSuggestions);
        setActiveSuggestion(0);
        setShowSuggestions(true);
        setUserInput(e.target.value);
        onChange(e);

    };

    const onSuggestionClick = suggestion => {
        setFilteredSuggestions([]);
        setActiveSuggestion(0);
        setShowSuggestions(false);
        setUserInput(suggestion);
        onChange({ target: { value: suggestion } });
    };

    return (
        <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", alignItems: "center", height: 30 }}>
            <input
                className="ape-fahrer-input"
                style={{ flex: 1, alignSelf: isFirst ? "start" : "center", width: "70%" }}
                type="text"
                onChange={onInputChange}
                value={value}
                placeholder={placeholder}
            />
            {showSuggestions && userInput && (
                <div className="ape-suggestions-container" style={{ border: "none" }}>
                    {filteredSuggestions.map((suggestion, index) => {
                        return (
                            <div
                                key={index}
                                className="ape-suggestion-item"
                                style={{ width: "75%" }}
                                onClick={() => {
                                    onSuggestionClick(suggestion);
                                }}
                            >
                                {suggestion}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};


export default Rollenverwaltung;
