import { useParams } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import FailedCourseActionsListContain from "./FailedCourseActionsListContain";
import "./PruefungBestanden.css";
import { useTranslation } from "react-i18next";
const FraechterPruefungBestanden = () => {
  const [t] = useTranslation("common");
  const { id } = useParams(); 

  return (
    <div className="fahrer-pruefung-bestanden">
      <div className="inner-window4">
        <FailedCourseActionsListContain
          courseResultMessage={t("carrier.pruefung.pb-KursBestanden")}
          actionButtonText={t("carrier.pruefung.pb-ZertifikatDrucken")}
          teilnehmenColor="#2eb22b"
          id={id}
        />
      </div>
      <Sidebar
      />
    </div>
  );
};

export default FraechterPruefungBestanden;
