import { useEffect, useRef, useState } from "react";
import "./AddQuestionPicture.css";
import { createPortal } from "react-dom";
import { usePostQuestionMutation, useUpdateQuestionMutation } from "../../../features/schulungen/admin/adminQuestionSlice";
import { useTranslation } from "react-i18next";
const AddQuestionPicture = ({ onClose, schulungID, pruefungsID, existingQuestion, isEditing = false }) => {
    //const { addQuestionToCurrentSchulung, updateQuestionInCurrentSchulung } = useSchulungen();
    const MAX_ANSWERS = 7;
    const [t] = useTranslation('common');
    const [question, setQuestion] = useState(existingQuestion?.frage || "");
    const [answers, setAnswers] = useState(existingQuestion?.answers || []);

    const imageInputRef = useRef(null);
    const questionImageInputRef = useRef(null);
    const [questionImage, setQuestionImage] = useState(existingQuestion?.image || null);

    const [postQuestion] = usePostQuestionMutation();
    const [updateQuestion] = useUpdateQuestionMutation();

    const [shouldFocus, setShouldFocus] = useState(false);
    const newestAnswerRef = useRef(null);

    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    const uploadQuestionImage = () => {
        questionImageInputRef.current.click();
    };

    const uploadImage = () => {
        imageInputRef.current.click();
    };

    const handleImageUpload = async (e, isQuestionImage) => {
        const file = e.target.files[0];

        if (file && /^image\//.test(file.type)) {
            saveToServer(file, isQuestionImage);
        } else {
            console.warn("You could only upload images.");
        }

    };


    const addImageAsAnswer = (imageUrl) => {
        setAnswers([...answers, { text: "", true: false, image: imageUrl }]);
    };

    const addNewTextAnswerOption = () => {
        if (answers.length < MAX_ANSWERS) {
            setAnswers(prevAnswers => [...prevAnswers, { text: "", true: false, image: null }]);
            setShouldFocus(true);  // Setze den Fokus-Flag, wenn eine neue Antwort hinzugefügt wird
        } else {
            alert(t("admin.afrv.afrv-aq-alertMax"));
        }
    };

    useEffect(() => {
        if (shouldFocus && newestAnswerRef.current) {
            newestAnswerRef.current.focus();
            setShouldFocus(false);  // Setze den Fokus-Flag zurück, nachdem der Fokus gesetzt wurde
        }
    }, [shouldFocus]);






    // ...

    const saveQuestionToSchulung = () => {

        if (answers.filter(answer => answer.true).length === 0) {
            setIsError(true);
            setErrorMessage(t("admin.afrv.afrv-aq-alertMinRichtig"));
            return;
        }

        if (answers.length < 2) {
            setIsError(true);
            setErrorMessage(t("admin.afrv.afrv-aq-alertMinAntwort"));
            return;
        }

        if (!question.trim()) {
            setIsError(true);
            setErrorMessage(t("admin.afrv.afrv-aq-alertFrage"));

            return;
        }

        if (answers.some(answer => answer.image === null && !answer.text.trim())) {
            setIsError(true);
            setErrorMessage(t("admin.afrv.afrv-aq-alertLeereAntwort"));
            return;
        }

        const newQuestion = {
            frage: question,
            image: questionImage,
            isImage: true,
            answers: answers,
        };

        setIsError(false);

        if (isEditing) {
            console.log("update question: ", { schulungID, question: newQuestion, questionID: existingQuestion.id });
            updateQuestion({ schulungID, question: newQuestion, id: existingQuestion.id });
        } else {
            console.log("posting question: ", { schulungID, id: pruefungsID, question: newQuestion });

            postQuestion({ schulungID, id: pruefungsID, question: newQuestion });
        }

        onClose();
    };


    const saveToServer = async (file, isQuestionImage) => {
        const fd = new FormData();
        fd.append("upload", file);

        try {
            const response = await fetch(process.env.REACT_APP_FILE_API_URL, {
                //const response = await fetch("http://localhost:8080/api/files", {
                method: "POST",
                body: fd
            });

            if (response.status === 201) {
                const { url } = await response.json();
                if (isQuestionImage) {
                    console.log("questionImage" + url);
                    setQuestionImage(url);
                } else {
                    console.log("answerImage" + url);
                    addImageAsAnswer(url);
                }
            }
        } catch (error) {
            console.error("Failed to upload the image:", error);
        }
    };

    const deleteAnswerOption = (indexToDelete) => {
        const updatedAnswers = answers.filter((_, index) => index !== indexToDelete);
        setAnswers(updatedAnswers);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    return createPortal(
        <div className="admin-modal-frage-hinzufgen">
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", gap: "30px" }}>
                <input style={{ flex: 3 }} type="text" className="amfh-input-frage" placeholder={t("admin.afrv.afrv-aq-PlaceholderFrage")} value={question} onChange={e => setQuestion(e.target.value)} />
                <div className="amfh-side-buttons">
                    <div style={{width: 300}}></div>
                </div>
            </div>
            <div className="amfh-mid-section">
                <div className="amfh-inner-section">
                    <div className="amfh-fragen-section">
                        {questionImage && (
                            <div className="amfh-frage-option">
                                <img className="amfb-image-align" width={350} src={questionImage} alt="Fragebild" />
                                <span className="amfh-delete-option" onClick={() => setQuestionImage(null)}>❌</span>
                            </div>
                        )}
                        {answers.map((answer, index) => (
                            <div key={index} className="amfh-frage-option">
                                <input
                                    className="amfh-checkbox"
                                    type="checkbox"
                                    checked={answer.true}
                                    onChange={() => {
                                        // Hier erstellen wir eine neues Array von Antworten. Für jede Antwort im ursprünglichen Array...
                                        const updatedAnswers = answers.map((ans, idx) => {
                                            // ...prüfen wir, ob es die Antwort ist, die wir aktualisieren wollen...
                                            if (idx === index) {
                                                // ...wenn ja, geben wir eine NEUE Kopie dieser Antwort zurück, mit `isTrue` invertiert...
                                                return { ...ans, true: !ans.true };
                                            } else {
                                                // ...wenn nicht, geben wir die unveränderte Antwort zurück.
                                                return ans;
                                            }
                                        });
                                        // Schließlich setzen wir den Zustand mit unserem neuen, aktualisierten Antworten-Array.
                                        setAnswers(updatedAnswers);
                                    }}
                                />
                                {answer.image ? (
                                    <img width={200} src={answer.image} alt={`Antwortbild ${index + 1}`} />
                                ) : (
                                    <input className="amfh-text-frame" ref={index === answers.length - 1 ? newestAnswerRef : null}
                                        value={answer.text} onChange={e => {
                                            const updatedAnswers = answers.map((ans, idx) => {
                                                if (idx === index) {
                                                    return { ...ans, text: e.target.value };
                                                } else {
                                                    return ans;
                                                }
                                            });
                                            setAnswers(updatedAnswers);

                                        }} />
                                )}
                                <span className="amfh-delete-option" onClick={() => deleteAnswerOption(index)}>❌</span>

                            </div>
                        ))}

                    </div>
                </div>
                <div className="amfh-side-buttons">
                    <button className="amfh-inputitem-comp" onClick={uploadQuestionImage}>
                        <div className="amfainputtext">{t("admin.afrv.afrv-aqp-BildFrage")}</div>
                        <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e, true)} style={{ display: 'none' }} ref={questionImageInputRef} />
                    </button>
                    <button className="amfh-inputitem-comp" onClick={uploadImage}>
                        <div className="amfainputtext">{t("admin.afrv.afrv-aqp-BildAntwort")}</div>
                        <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e, false)} style={{ display: 'none' }} ref={imageInputRef} />
                    </button>
                    <button className="amfh-inputitem-comp" onClick={() => { addNewTextAnswerOption() }}>
                        <div className="amfainputtext">{t("admin.afrv.afrv-aqp-TextAntwort")}</div>
                    </button>
                    <div>
                        <label>Optimale Bildmaße: 1024*1024</label>
                    </div>
                </div>
            </div>

            {isError && (
                <div style={{
                    color: "red", // This will make the text color red
                    animation: "flickerAnimation 3s infinite", // Apply the animation named flickerAnimation
                    textAlign: "center",
                    fontSize: "2rem",
                }}>
                    {errorMessage}
                </div>
            )}
            <div className="amfh-ueberschrift">
                <b className="amfh-text">{t("admin.afrv.afrv-aqp-infoAntwortAnkreuzen")}</b>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <button className="amfh-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={onClose}>
                        <div className="amfh-uebersichtsnav-btn-text">{t("admin.afrv.afrv-aqp-Abbrechen")}</div>
                    </button>
                    <button className="amfh-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={saveQuestionToSchulung}>
                        <div className="amfh-uebersichtsnav-btn-text">{t("admin.afrv.afrv-aqp-Speichern")}</div>
                    </button>
                </div>
            </div>
        </div >,
        document.getElementById("modal-root-3")
    );
};

export default AddQuestionPicture;
