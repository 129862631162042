import "./FahrerKursansicht.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetSchulungenForFahrerByIdQuery } from "../../../features/schulungen/fahrer/fahrerSchulungenSlice";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { FaLock, FaCheck } from 'react-icons/fa';

import { useLoading } from "../../../components/LoadingSpinner/LoadingContext";
import { useTranslation } from "react-i18next";
const FahrerKursansicht = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: zugeordneteSchulung, error, isLoading, isSuccess, isError, refetch } = useGetSchulungenForFahrerByIdQuery(id);
  const { startLoading, stopLoading } = useLoading();
  const [t] = useTranslation("common");
  const [disabled, setDisabled] = useState(true);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading, startLoading, stopLoading]);

  useEffect(() => {
    if (isSuccess) {
      if (zugeordneteSchulung.watchedInfos.length >= zugeordneteSchulung.schulung.informationsbereiche.length && zugeordneteSchulung.durchgefuehrtePruefungen.length < zugeordneteSchulung.schulung.pruefung.wiederholungen) {
        setDisabled(false);
      }
      if(zugeordneteSchulung.bestanden){
        setDisabled(true);
      }
    }
  }, [isSuccess, zugeordneteSchulung]);


  return (
    <div className="fahrer-kursansicht">
      <div className="inner-window6">
        <div className="modul-comp" style={{textAlign: "center", display: "flex", alignItems:"center", fontSize: 24, color: "#3a3a3a", opacity:"1"}}>
          <div style={{flex:1}}>
          {t("carrier.fka-Schulungsmodul")}{zugeordneteSchulung?.schulung?.name}
          </div>
        </div>
        <div style={{ fontSize: 24, fontFamily: "Verdana", marginLeft: 10, width: "48%", color: "#3a3a3a" }}>{t("carrierMaster.fr-kd-Informationsbereiche")}</div>
        <div className="inner-section">

          <div className="bereich-list">

            {zugeordneteSchulung && zugeordneteSchulung?.schulung?.informationsbereiche?.map((bereich, index) => {
              // Überprüfen, ob der aktuelle Bereich in der watchedInfos-Liste ist
              const isWatched = zugeordneteSchulung.watchedInfos.some(wInfo => wInfo.informationsbereich.id === bereich.id);

              // Den nächsten Informationsbereich aus der Liste erhalten
              const nextBereichId = zugeordneteSchulung?.schulung?.informationsbereiche[index + 1]?.id;

              return (
                <div
                  onClick={() => {
                    // Überprüfen, ob es einen nächsten Informationsbereich gibt, wenn ja, navigieren mit dem next Parameter
                    if (nextBereichId) {
                      navigate(`/carrier/${id}/info/${bereich.id}?next=${nextBereichId}`);
                    } else {
                      navigate(`/carrier/${id}/info/${bereich.id}`);
                    }
                  }}
                  key={index}
                  className="info-list-comp"
                  style={{ backgroundColor: isWatched ? "#ffa433" : "#e9e9e9", color: "#3a3a3a" }}
                >
                  <b className="schulungsmodul-f11">
                    {bereich.title}
                  </b>
                  <b>
                    {isWatched ? <FaCheck /> : ""}
                  </b>
                </div>
              );
            })}
          </div>
          <div className="actions-list1">
            <button onClick={() => {
              if (zugeordneteSchulung?.durchgefuehrtePruefungen.length === zugeordneteSchulung?.schulung?.pruefung.wiederholungen) {
                setErrorMessage("Sie haben die maximale Anzahl an Versuchen erreicht!");
              }else if(zugeordneteSchulung.bestanden){
                setErrorMessage("Sie haben die Schulung bereits bestanden!");
              } 
              else {
                navigate("/carrier/exam/" + id)
              }
            }
            }
              disabled={disabled}
              className="pruefung-btn" id="ModulVerw_Btn" style={{ backgroundColor: disabled ? "#3a3a3a" : "#ffa433" }}>
              <div className="pruefung-text" >{t("carrier.fka-PruefungStarten")} {disabled ? <FaLock /> : null}</div>
            </button>

            {
              disabled ? (
                <div className="infotext1" style={{color: "#3a3a3a"}}>
                  {
                    zugeordneteSchulung?.schulung?.pruefung.wiederholungen <= zugeordneteSchulung?.durchgefuehrtePruefungen.length ? (
                      <div>Sie haben die maximale Anzahl an Versuchen erreicht! <br></br><br></br>Bitte kontaktieren Sie die Disposition.</div>
                    ) : (
                      !zugeordneteSchulung?.bestanden ? 
                    (<div>{t("carrier.fka-HinweisPruefung")}</div>) : <div>Hinweis: Sie haben die Prüfung bereits bestanden</div>
                  )
                  }
                </div>
              ) : (
                <div className="infotext1">
                  {t("carrier.fka-InfoPruefungStarten")}
                  <br />
                  <br />
                  {t("carrier.fka-InfoVersuchanzahl1")} {zugeordneteSchulung?.schulung?.pruefung.wiederholungen - zugeordneteSchulung?.durchgefuehrtePruefungen.length} {t("carrier.fka-InfoVersuchanzahl2")}
                </div>
              )
            }

            {errorMessage && (
              <div style={{
                color: "red", // This will make the text color red
                animation: "flickerAnimation 3s infinite", // Apply the animation named flickerAnimation
                textAlign: "center",
                fontSize: "2rem",
              }}>
                Fehler: {errorMessage}
              </div>
            )}

            <div className="inner-container">
              <button onClick={() => { navigate("/carrier") }} className="uebersicht-btn1" id="ModulVerw_Btn">
                <div className="pruefung-text">{t("carrier.fka-ZuruekZurUebersicht")}</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Sidebar
      />
    </div>
  );
};

export default FahrerKursansicht;
