import { Routes, Route } from 'react-router-dom'
import Layout from "./components/Layout";
import Login from './pages/Login';
import AdminKursverwaltung from './pages/admin/kursverwaltung/AdminKursverwaltung';
import AdminFahrerverwaltung from './pages/admin/fahrerverwaltung/AdminFahrerverwaltung';
import AdminKursdetails from './pages/admin/kursdetails/Adminkursdetails';
import AdminFragenverwaltung from './pages/admin/fragenverwaltung/AdminFragenverwaltung';
import AdminPruefungsergebnisse from './pages/admin/pruefungsergebnisse/AdminPruefungsergebnisse';
import Redirect from './pages/RedirectLogin';
import { LoadingProvider } from './components/LoadingSpinner/LoadingContext';
import FahrerKursbersicht from './pages/carrier/kursuebersicht/FahrerKursbersicht';
import FahrerKursansicht from './pages/carrier/kursdetails/FahrerKursansicht';
import InfoBereichAnsicht from './pages/carrier/infobereich/InfoBereichAnsicht';
import FahrerPruefungsstart from './pages/carrier/pruefung/FahrerPruefungsstart';
import QuestionText from './pages/carrier/pruefung/durchfuehrung/QuestionText';
import PruefungBestanden from './pages/carrier/pruefung/ergebnis/PruefungBestanden';
import PruefungNichtBestanden from './pages/carrier/pruefung/ergebnis/PruefungNichtBestanden';
import FraechterKursverwaltung from './pages/carrierMaster/FR-Kursverwaltung';
import Settings from './pages/Settings';
import FraechterFahrerverwaltung from './pages/carrierMaster/FraechterFahrerverwaltung';
import FraechterKursdetails from './pages/carrierMaster/FR-Kursdetails';
import FraechterPruefungsergebnisse from './pages/carrierMaster/FR-Pruefungsergebnisse';
import AuthLayout from './components/AuthLayout';
import Adminkursarchiv from './pages/admin/kursarchiv/Adminkursarchiv';
import Wartung from './components/Wartungsmodus';
import FraechterKursansicht from './pages/carrierMaster/pruefungen/FR-Kursansicht';
import FraechterInfoBereichAnsicht from './pages/carrierMaster/pruefungen/FR-InfobereichAnsicht';
import FraechterPruefungsstart from './pages/carrierMaster/pruefungen/FR-Pruefungsstart';
import FraechterQuestionText from './pages/carrierMaster/pruefungen/FR-QuestionText';
import FraechterPruefungBestanden from './pages/carrierMaster/pruefungen/ergebnis/PruefungBestanden';
import FraechterPruefungNichtBestanden from './pages/carrierMaster/pruefungen/ergebnis/PruefungNichtBestanden';
import Rollenverwaltung from './pages/admin/rollenverwaltung/Rollenverwaltung';
import PhishingUebersicht from './pages/admin/phishing/PhishingUebersicht';
function App() {

  // return (<Routes>
  //   <Route path="*" element={<Wartung />} />
  // </Routes>
  // )

  return (
    <LoadingProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Login />} />
          <Route path="redirectLogin" element={<Redirect />} />

          <Route path='carrierMaster' element={<AuthLayout roles={["FRAECHTER"]} />}>
            <Route index element={<FraechterKursverwaltung />} />
            <Route path="search" element={<FraechterFahrerverwaltung />} />
            <Route path="settings" element={<Settings />} />
            <Route path='details/:id' element={<FraechterKursdetails />} />
            <Route path='details/results/:id' element={<FraechterPruefungsergebnisse />} />
            <Route path="exams/details/:id" element={<FraechterKursansicht />} />
            <Route path="exams/:sid/info/:id" element={<FraechterInfoBereichAnsicht />} />
            <Route path='exams/exam/:id' element={<FraechterPruefungsstart />} />
            <Route path='exams/exam/:id/start' element={<FraechterQuestionText />} />
            <Route path='exams/exam/passed/:id' element={<FraechterPruefungBestanden />} />
            <Route path='exams/exam/failed/:id' element={<FraechterPruefungNichtBestanden />} />

          </Route>

          <Route path="carrier" element={<AuthLayout roles={["FAHRER", "USER"]} />}>
            <Route index element={<FahrerKursbersicht />} />
            <Route path="details/:id" element={<FahrerKursansicht />} />
            <Route path=":sid/info/:id" element={<InfoBereichAnsicht />} />
            <Route path='exam/:id' element={<FahrerPruefungsstart />} />
            <Route path='exam/:id/start' element={<QuestionText />} />
            <Route path='exam/passed/:id' element={<PruefungBestanden />} />
            <Route path='exam/failed/:id' element={<PruefungNichtBestanden />} />
            <Route path="settings" element={<Settings />} />
          </Route>

          <Route path="admin" element={<AuthLayout roles={["ADMIN", "SUPER_ADMIN"]} />}>
            <Route path="settings" element={<Settings />} />
            <Route index element={<AdminKursverwaltung />} />
            <Route path="carrier" element={<AdminFahrerverwaltung />} />
            <Route path="details/:id" element={<AdminKursdetails />} />
            <Route path='details/restore/:id' element={<Adminkursarchiv />} />
            <Route path='questions/:id' element={<AdminFragenverwaltung />} />
            <Route path='details/results/:id' element={<AdminPruefungsergebnisse />} />
            <Route path='roles' element={<Rollenverwaltung />} />
          </Route>
          <Route path='admin/phishing' element={<AuthLayout roles={["SUPER_ADMIN"]} />}>
            <Route index element={<PhishingUebersicht />} />
          </Route>
        </Route>
      </Routes>
    </LoadingProvider>
  );
}
export default App;
