import React from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import "./global.css";
import { Provider } from 'react-redux'
import { store } from './app/store'
import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";

import DeTranslation from "./translations/DeTranslation.json";
import EnTranslation from "./translations/EnTranslate.json";
import RoTranslation from "./translations/RoTranslate.json";

const container = document.getElementById("root");
const root = createRoot(container);

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: localStorage.getItem('language') || "de", // Use saved language or default to 'de'
  resources: {
    de: {
      common: DeTranslation,
    },
    en: {
      common: EnTranslation,
    },
    ro: {
      common: RoTranslation,
    },
  },
});

function disableConsole() {
  if (process.env.NODE_ENV === 'production') { // oder eine andere Logik, um DEBUG zu setzen
      if (!window.console) window.console = {};
      const methods = ["log", "debug", "warn", "info", "dir", "dirxml", "trace", "profile"]; // Fügen Sie weitere Methoden hinzu, falls benötigt
      methods.forEach(method => {
          console[method] = function() {};
      });
  }
}

disableConsole();




root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18nextProvider>
  </Provider>
);