import "./QuestionText.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import QuestionSteps from "./QuestionSteps";
import { useGetSchulungenForFahrerByIdQuery, usePostPruefungMutation } from "../../../../features/schulungen/fahrer/fahrerSchulungenSlice";
import { useLoading } from "../../../../components/LoadingSpinner/LoadingContext";
import QuestionSection from "./QuestionSection";
import { useTranslation } from "react-i18next";

const QuestionText = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const { id } = useParams();
  const { data: zugeordneteSchulung, error, isLoading, isSuccess, isError } = useGetSchulungenForFahrerByIdQuery(id);
  const [postPruefung, { isLoading: isPostPruefungLoading }] = usePostPruefungMutation();
  const { startLoading, stopLoading } = useLoading();

  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);

  const [t] = useTranslation("common");
  useEffect(() => {
    if (isLoading || isPostPruefungLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading, isPostPruefungLoading, startLoading, stopLoading]);


  const beforeunloadHandler = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };



  useEffect(() => {
    if (isSuccess) {
      console.log("schulung: ", zugeordneteSchulung)
      if (zugeordneteSchulung?.schulung?.pruefung.questions?.length === 0) {
        navigate("/carrier/details/" + id);
      }
      if (zugeordneteSchulung?.schulung?.pruefung.fragenpool && zugeordneteSchulung?.schulung?.pruefung.questions) {
        const randomQuestions = selectRandomQuestions(zugeordneteSchulung?.schulung?.pruefung.questions, zugeordneteSchulung?.schulung?.pruefung.fragenpool);
        setSelectedQuestions(randomQuestions);
      }
    }

  }, [isSuccess, zugeordneteSchulung]);

  // ...



  const handleNextQuestion = () => {
    if (currentQuestionIndex < selectedQuestions.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      updateCorrectAnswersCount(allSelectedAnswers);
    } else {
      checkAnswersAndNavigate();
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prevIndex => prevIndex - 1);
    }
  };

  const [allSelectedAnswers, setAllSelectedAnswers] = useState([]);

  const handleSelectedAnswers = (selectedAnswers) => {
    setAllSelectedAnswers(prev => {
      const newAllSelectedAnswers = [...prev];
      newAllSelectedAnswers[currentQuestionIndex] = selectedAnswers;
      return newAllSelectedAnswers;
    });
  };

  const selectRandomQuestions = (questions, count) => {
    let realQuestions = questions;
    let shuffledQuestions = [...realQuestions].sort(() => 0.5 - Math.random());
    return shuffledQuestions.slice(0, count);
  }


  const updateCorrectAnswersCount = (selectedAnswers) => {
    let count = 0;
    selectedAnswers.forEach((answers, index) => {
      if (checkIfAnswerIsCorrect(answers, selectedQuestions[index])) {
        count++;
      }
    });
    setCorrectAnswersCount(count);
  };

  const checkIfAnswerIsCorrect = (selectedAnswers, question) => {
    return calculatePoints(question, selectedAnswers) === 10; // Beispiel
  };


  const checkAnswersAndNavigate = () => {
    const totalPoints = allSelectedAnswers.reduce((acc, selectedAnswers, index) => {
      const question = selectedQuestions[index];
      const questionPoints = calculatePoints(question, selectedAnswers);
      return acc + questionPoints;
    }, 0);

    // maximale Punktzahl ist einfach 10 Punkte mal die Anzahl der Fragen
    const maxPoints = (selectedQuestions.length || 0) * 10;

    console.log("totalPoints: ", totalPoints);
    console.log("maxPoints: ", maxPoints);

    // Prozentsatz der korrekten Antworten
    const percentageCorrect = (totalPoints / maxPoints) * 100;
    console.log("percentageCorrect: ", percentageCorrect);

    // Nun hast du die Gesamtpunktzahl in `totalPoints`. Du kannst sie weiterverarbeiten/navigieren etc.

    postPruefung({ invalidate_id: id, schulungs_id: id, ergebnis: percentageCorrect })
      .unwrap()
      .then((resp) => {
        if (resp.pruefung.bestanden) {
          navigate("/carrier/exam/passed/" + id);
        } else {
          navigate("/carrier/exam/failed/" + id);
        }
      })
      .catch((error) => {
        console.error("error: ", error);
      })
      .finally(() => {
        stopLoading();
      });



  };

  const calculatePoints = (question, selectedAnswers) => {
    const pointsPerAnswer = 10 / question.answers.length;
    let points = 0;

    question.answers.forEach((answer) => {
      const isAnswerCorrect = Boolean(answer.true) === Boolean(selectedAnswers[answer.id]);
      if (isAnswerCorrect) {
        points += pointsPerAnswer;
      }
    });

    return points;
  };  


  const currentAnswers = allSelectedAnswers[currentQuestionIndex] || {};
  const isAnswerSelectedForCurrentQuestion = Object.values(currentAnswers).some(Boolean);


  if (isSuccess) {



    return (
      <div className="fput-fahrer-pruefungsfrage-text">
        <div className="fput-inner-window2">
          <div className="fput-administrative-section1">
            <QuestionSteps totalSteps={selectedQuestions.length} activeStep={currentQuestionIndex + 1} />
            <div className="fput-modulbox">
              <b className="fput-modulname">{zugeordneteSchulung?.schulung?.name}</b>
            </div>
            <div> Richtige Antworten: {correctAnswersCount}/{selectedQuestions.length}
            </div>
          </div>
          {
            selectedQuestions[currentQuestionIndex] &&
            <QuestionSection
              question={selectedQuestions[currentQuestionIndex]}
              onSelectedAnswersChange={handleSelectedAnswers}
              currentSelectedAnswers={allSelectedAnswers[currentQuestionIndex] || {}}
            />}
          <div className="fput-button-group">
            <button
              onClick={handleNextQuestion}
              className="fput-teilnehmen-btn2"
              id="ModulVerw_Btn"
              disabled={!isAnswerSelectedForCurrentQuestion} // deaktiviere den Button, wenn keine Antwort ausgewählt wurde
            >
              <div className="fput-teilnehmen3">{currentQuestionIndex < selectedQuestions.length - 1 ? t("carrier.pruefung.qt-weiter") : t("carrier.pruefung.qt-abschließen")}</div>
            </button>
          </div>
        </div>
        <Sidebar navigationNotAllowed={true} />
      </div>
    );
  } else {
    <div className="fput-fahrer-pruefungsfrage-text">
      <div className="fput-inner-window2">
        <div className="fput-administrative-section1">
        </div>
      </div>
      <Sidebar navigationNotAllowed={true} />
    </div>
  }
};

export default QuestionText;