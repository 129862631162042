import { useNavigate } from "react-router-dom";
import "./ModuleComponent.css";
import { selectSchulungById, useUpdateSchulungMutation } from "../../../features/schulungen/admin/adminSchulungenSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const ModuleComponent = ({ id, isDeleted = false }) => {

  const schulung = useSelector(state => selectSchulungById(state, id))
  const [updateSchulung, { isLoading: isUpdating, isError: isUpdatingError, isSuccess: isUpdateSuccess }] = useUpdateSchulungMutation();
  const [t] = useTranslation('common');
  const handleRestoreSchulung = () => {
    if (schulung) {
      updateSchulung({ ...schulung, geloescht: false });
    }
  }



  const navigate = useNavigate(); // useNavigate() is a hook from react-router-dom

  return (
    <div className="akvw-list-component">
      <b className="akvw-list-component-text">{schulung.name}</b>
      <div style={{ display: "flex", flexDirection: "row", gap: '10px' }}>
        <button onClick={() => { isDeleted ? navigate(`/admin/details/restore/${id}`) : navigate(`/admin/details/${id}`) }} className="akvw-list-component-btn" id="ModulVerw_Btn">
          <div className="akvw-list-component-btn-text">{isDeleted ? t("admin.akv.akv-ModulAnsehen") : t("admin.akv.akv-ModulVerwalten")}</div>
        </button>
        {isDeleted &&
          <button onClick={handleRestoreSchulung} className="akvw-list-component-btn" id="ModulVerw_Btn">
            <div className="akvw-list-component-btn-text">{t("admin.pages.pages-akd-wiederherstellen")}</div>
          </button>
        }
      </div>
    </div>
  );
};

export default ModuleComponent;