import React from 'react';
import i18next from 'i18next';
import "./LanguageSelector.css";

const LanguageSelector = () => {
    const changeLanguage = (event) => {
        const language = event.target.value;
        i18next.changeLanguage(language);
        localStorage.setItem('language', language);
    };

    return (
        <select className="custom-select" onChange={changeLanguage} defaultValue={localStorage.getItem('language') || 'de'}>
            <option value="de">Deutsch</option>
            <option value="en">English</option>
            <option value="ro">Română</option>
        </select>
    );
};

export default LanguageSelector;
