import "./QuestionSteps.css";
const QuestionSteps = ({ totalSteps, activeStep }) => {
  // Verwende ein Array mit `totalSteps` Länge
  const steps = new Array(totalSteps).fill(null);

  return (
    <div className="fragen-box-list" style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '10px' }}>
      {steps.map((_, index) => (
        <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            className={`fragen-box ${activeStep === index + 1 ? 'active' : ''}`}
          >
            <b className="b">{index + 1}</b>
          </div>
        </div>
      ))}
    </div>
  );
  };

export default QuestionSteps;
