import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";

const schulungenAdapter = createEntityAdapter()

const initialState = schulungenAdapter.getInitialState()

export const adminSchulungenSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSchulungen: builder.query({
            query: () => ({
                url: '/admin/schulungen',
                method: 'GET'
            }),
            transformResponse: responseData => {
                console.log("responseData: ", responseData)
                return schulungenAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => [
                { type: 'Schulung', id: "LIST" },
                ...(result?.ids?.map(id => ({ type: 'Schulung', id })))
            ]            
        }),
        getSchulungByID: builder.query({
            query: id => ({
                url: `/admin/schulungen/${id}`,
                method: 'GET'
            }),
            providesTags: (result, error, id) => [{ type: 'Schulung', id }],
        })
        ,
        updateSchulung: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/admin/schulungen/${id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Schulung', id }],
        }),
        deleteSchulung: builder.mutation({
            query: id => ({
                url: `/admin/schulungen/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Schulung', id }],
        }),
        postSchulung: builder.mutation({
            query: initalSchulung => ({
                url: '/admin/schulungen',
                method: 'POST',
                body: {
                    ...initalSchulung,
                }
            }),
            invalidatesTags: [
                { type: 'Schulung', id: "LIST" }
            ]
        })
    })
})

export const {
    useGetSchulungenQuery,
    usePostSchulungMutation,
    useUpdateSchulungMutation,
    useGetSchulungByIDQuery,
    useDeleteSchulungMutation
} = adminSchulungenSlice


export const selectGetSchulungenResult = adminSchulungenSlice.endpoints.getSchulungen.select();

// Selektor zum Abruf der Daten aus dem 'getSchulungen' Ergebnis
export const selectSchulungenData = createSelector(
    // Verwende den oben definierten Selektor als Eingabe
    selectGetSchulungenResult,
    // Die zurückgegebene Datenstruktur ist in `data` enthalten
    getSchulungenResult => getSchulungenResult.data
);

export const {
    selectAll: selectAllSchulungen,
    selectById: selectSchulungById,
    selectIds: selectSchulungenIds
    // Pass in a selector that returns the posts slice of state
} = schulungenAdapter.getSelectors(state => selectSchulungenData(state) ?? initialState)
