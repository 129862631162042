import { useEffect, useState } from "react";
import { useLoading } from "../../../components/LoadingSpinner/LoadingContext";
import Sidebar from "../../../components/Sidebar/Sidebar";
import ErgebnisListItem from "../../../components/admin/fahrerverwaltung/ErgebnisListItem";
import { useGetPruefungsErgebnisseQuery } from "../../../features/schulungen/admin/adminPruefungenSlice";
import "./AdminFahrerverwaltung.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const AdminFahrerverwaltung = () => {


    const navigate = useNavigate();

    const { data, isLoading, isSuccess } = useGetPruefungsErgebnisseQuery();

    const [suggestedResults, setSuggestedResults] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);

    const [displayedData, setDisplayedData] = useState([])
    const [searchInput, setSearchInput] = useState("")

    const [t] = useTranslation('common');
    const { startLoading, stopLoading } = useLoading();

    const updateSuggestedResults = (searchInput) => {
        if (searchInput === "") {
            setSuggestedResults([]);
        } else {
            // Zuerst werden alle passenden Namen in ein neues Set eingefügt
            const namesSet = new Set();
            const suggestions = data?.filter((dataItem) => {

                if (dataItem.isGeloescht) {
                    return false;
                }

                let name;
                console.log("dataItem: ", dataItem);
                if (dataItem.fahrer === null) {
                    name = dataItem.fraechter.name.toLowerCase();
                } else {
                    name = dataItem.fahrer.name.toLowerCase();
                }

                const matchesInput = name.includes(searchInput.toLowerCase());
                // Wenn der Name zum ersten Mal auftritt und dem Suchbegriff entspricht, wird er zum Set hinzugefügt
                if (matchesInput && !namesSet.has(name)) {
                    namesSet.add(name);
                    return true;
                }
                return false;
            });
            setSuggestedResults(suggestions);
        }
    }


    useEffect(() => {
        if (isLoading) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoading, startLoading, stopLoading]);

    useEffect(() => {
        if (isSuccess) {
            console.log("ergebnisse: ", data)
        }
    }, [isSuccess, data]);

    //Search in data for searchInput in data.fahrer.name
    //setDisplayedData to filtered data
    //if searchInput is empty, setDisplayedData to data

    const setNeawDisplayedData = (searchInput) => {
        setSearchClicked(true);

        if (searchInput === "") {
            setDisplayedData([]);
        } else {

            console.log("loggedData: ", data);

            const filteredData = data?.filter((dataItem) => {
                let name;
                if (dataItem.fahrer === null) {
                    name = dataItem.fraechter.name.toLowerCase();
                } else {
                    name = dataItem.fahrer.name.toLowerCase();
                }
                // Überprüfen, ob der Fahrername dem Suchbegriff entspricht und ob geloescht nicht true ist
                const nameMatches = name === searchInput.toLowerCase();
                console.log("nameMatches fuer : ", name, " : ", nameMatches, " : ", " SearchInput: ", searchInput);
                return nameMatches && !dataItem.isGeloescht;
            });
            console.log("filteredData: ", filteredData); // filteredData is now filtered based on name and deletion status
            setDisplayedData(filteredData);
        }
    };


    return (
        <div className="afvw-fahrerverwaltung">
            <Sidebar />
            <div className="afvw-inner-window" style={{ alignItems: "center", justifyContent: searchClicked ? "flex-start" : "center" }}>
                <div className="afvw-carrier-input-container" style={{ alignItems: "center", justifyContent: "center" }}>
                    <div className="afvw-input-wrapper" style={{ width: "100%" }}>
                        <input
                            className="afvw-carrier-input"
                            placeholder={t("admin.pages.pages-afav-PlaceholderSuche")}
                            value={searchInput}
                            type="text"
                            onChange={(e) => {
                                setSearchInput(e.target.value);
                                updateSuggestedResults(e.target.value);
                            }}
                        />
                        {searchInput && suggestedResults.length > 0 && (
                            <div className="afvw-suggestions-container">
                                {suggestedResults.map((result, index) => (
                                    <div
                                        key={index}
                                        className="afvw-suggestion-item"
                                        onClick={() => {
                                            setSearchInput(result.fahrer ? result.fahrer.name : result.fraechter.name);
                                            setSuggestedResults([]);
                                            setNeawDisplayedData(result.fahrer ? result.fahrer.name : result.fraechter.name);
                                        }}
                                    >
                                        {result.fahrer ? result.fahrer.name : result.fraechter.name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <button onClick={() => setNeawDisplayedData(searchInput)} style={{ height: "100%" }} className="afvw-suchen-btn" id="Suchen-Btn">
                        <div className="afvw-suchen-btn-text">{t("admin.pages.pages-afav-SuchenBtn")}</div>
                    </button>
                </div>

                {
                    displayedData.length > 0 ? (
                        <>
                            <div className="afvw-list-item-header">
                                <div className="afvw-list-item-header-item-begin">{t("admin.pages.pages-afav-Status")}</div>
                                <div className="afvw-list-item-header-item">{t("admin.pages.pages-afav-Thema")}</div>
                                <div className="afvw-list-item-header-item">{t("admin.pages.pages-afav-Versuche")}</div>
                                <div className="afvw-list-item-header-item-end">{t("admin.pages.pages-afav-Aktionen")}</div>
                            </div>

                            <div style={{ width: "100%", overflowY: "scroll", overflowX: "hidden" }}>
                                {displayedData.map((data, index) => (
                                    <>
                                        <ErgebnisListItem
                                            thema={data.schulung.name}
                                            key={index}
                                            afvwListItemVersucheText={data.durchgefuehrtePruefungen.length}
                                            afvwListItemErgebnisseBtnBackgroundColor={"#b3b3b3"}
                                            bestanden={data.bestanden}
                                            onCertificateButtonClick={() => {
                                                const certificate_url = data.certificate_url;
                                                const link = document.createElement('a');
                                                link.href = certificate_url; // Verwenden Sie Ihren certificate_url
                                                link.download = certificate_url.split('/').pop(); // optional: Name der heruntergeladenen Datei
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                            }}
                                            afvwListItemFertigBtnBackgroundColor={data.bestanden ? "#34be39" : "#b3b3b3"}
                                            onButtonClick={() => { navigate(`/admin/details/results/${data.schulung.id}`) }}
                                        />

                                    </>
                                ))}
                            </div>
                        </>
                    ) : (
                        <div>{
                            searchClicked ? t("admin.pages.pages-afav-KeineSchulungGefunden") : null
                        }</div>
                    )
                }

            </div>
        </div>
    );
};

export default AdminFahrerverwaltung;
