import { apiSlice } from "../../../app/api/apiSlice";

export const adminPruefungenSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPruefungsErgebnisse: builder.query({
            query: () => ({
                url: `/admin/pruefungen`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [
                { type: 'FahrerPruefungen', id: "LIST" },
            ]
        }),
        updatePruefung: builder.mutation({
            query: ({ schulungsId, id, ...data }) => ({
                url: `/admin/pruefungen/${id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error, { schulungsId }) => [{ type: 'Schulung', schulungsId }],
        }),
        addPruefungsErgebnis: builder.mutation({
            query: ({ schulungs_id, fahrer_id }) => ({
                url: `/admin/pruefungen/schulung/${schulungs_id}/fahrer/${fahrer_id}`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, { pruefungsID }) => [{ type: 'FahrerPruefungen', id: "LIST" }],
        }),
        changePruefungsErgebnis: builder.mutation({
            query: ({ pruefungsID, bestanden }) => ({
                url: `/admin/pruefungen/result/${pruefungsID}`,
                method: 'PUT',
                body: { bestanden: bestanden }
            }),
            invalidatesTags: (result, error, { pruefungsID }) => [{ type: 'FahrerPruefungen', id: "LIST" }],
        }),
        resetPruefungsErgebnis: builder.mutation({
            query: ({ pruefungsID }) => ({
                url: `/admin/pruefungen/result/${pruefungsID}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { pruefungsID }) => [{ type: 'FahrerPruefungen', id: "LIST" }],
        }),
    })
})

export const {
    useUpdatePruefungMutation,
    useGetPruefungsErgebnisseQuery,
    useAddPruefungsErgebnisMutation,
    useChangePruefungsErgebnisMutation,   
    useResetPruefungsErgebnisMutation
} = adminPruefungenSlice
