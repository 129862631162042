import { Outlet, useNavigate } from 'react-router-dom';
import { useWhoamiQuery } from '../features/authApiSlice';
import { useLoading } from './LoadingSpinner/LoadingContext';
import { useEffect } from 'react';

const AuthLayout = ({ roles }) => { // Changed 'role' to 'roles' to indicate it's an array
    const navigate = useNavigate();
    const { data: user, isLoading, isSuccess } = useWhoamiQuery();

    const { startLoading, stopLoading } = useLoading();

    useEffect(() => {
        if (isLoading) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoading, startLoading, stopLoading]);

    if (isLoading) {
        return <div>Loading...</div>; // Or any other loading indicator of your choice
    }

    // Wait for the request to finish
    // If there's no user data or the user's role doesn't match any of the roles, navigate back to "/"
    // Updated to check if user's authorities include any of the roles in the 'roles' array
    if (!user || !user.authorities.some(authority => {
        const match = roles.includes(authority);
        console.log(`Checking authority: ${authority}, Match: ${match}`);
        return match;
    })) {
        console.log("User: ", user);
        console.log("Expected roles: ", roles);
        navigate('/');
        return null; // Early return to prevent rendering anything below in the component
    }
    
    return <Outlet />;
}

export default AuthLayout;
