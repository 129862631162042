import React, { useEffect, useState } from 'react';
import './LoadingSpinner.css';
import { useTranslation } from "react-i18next";

const LoadingSpinner = () => {
    const [t, i18n] = useTranslation('common');
    const [loadingWidth, setLoadingWidth] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingWidth((prev) => Math.min(prev + 10, 90)); // Setzen Sie den maximalen Wert auf 90 oder was auch immer Sie bevorzugen
        }, 200);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="loading-bar-container">
            <div className='loading-bar-container-label'>{t("ls.ls-text")}</div>

            <div className="loading-bar-background">
                <div
                    className="loading-bar"
                    style={{ width: `${loadingWidth}%` }}
                ></div>
            </div>
        </div>
    );
}

export default LoadingSpinner;
