import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import "./AdminPruefungsergebnisse.css";
import { useCallback, useEffect, useState } from "react";
import { useLoading } from "../../../components/LoadingSpinner/LoadingContext";
import { useAddPruefungsErgebnisMutation, useChangePruefungsErgebnisMutation, useGetPruefungsErgebnisseQuery, useResetPruefungsErgebnisMutation } from "../../../features/schulungen/admin/adminPruefungenSlice";
import { FaSync } from "react-icons/fa";
import { useGetSchulungByIDQuery } from "../../../features/schulungen/admin/adminSchulungenSlice";
import { useTranslation } from "react-i18next";
const AdminPruefungsergebnisse = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [t] = useTranslation('common');
    const { data, isLoading, isSuccess } = useGetPruefungsErgebnisseQuery();
    const [displayedData, setDisplayedData] = useState([])

    const { data: schulung, error, isLoading1, isSuccess1, isError } = useGetSchulungByIDQuery(id);

    const { startLoading, stopLoading } = useLoading();

    const [fahrerFilter, setFahrerFilter] = useState("");
    const [fraechterFilter, setFraechterFilter] = useState("");

    const [vonDate, setVonDate] = useState();
    const [bisDate, setBisDate] = useState();
    const [bestandenCheckbox, setBestandenCheckbox] = useState(false);
    const [nichtBestandenCheckbox, setNichtBestandenCheckbox] = useState(false);


    useEffect(() => {
        if (isLoading) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoading, startLoading, stopLoading]);


    useEffect(() => { if (isSuccess1) console.log("schulung: " + schulung) }, [isLoading1, isSuccess1, schulung]);


    useEffect(() => {
        if (isSuccess) {
            console.log("ergebnisse: ", data)
            console.log("schulungsname: ", id)
            const filteredData = data?.filter((data) => {
                return data.schulung.id === Number(id)
            })
            console.log("filteredData: ", filteredData) //filteredData is undefined
            resetFilter();
        }
    }, [isSuccess, data]);

    const getLastPruefung = (pruefungen) => {
        return [...pruefungen].sort((a, b) => new Date(b.zeitpunkt) - new Date(a.zeitpunkt))[0];
    }

    const fahrerNames = data
        ? data
            .filter(item => item.schulung.id === Number(id))
            .map(item => item.fahrer ? item.fahrer.name : item.fraechter.name) // Verwende "Ist Frächter", wenn kein Fahrer vorhanden ist
        : [];

    const fraechterNames = data
        ? data
            .filter(item => item.schulung.id === Number(id) && item.fahrer && item.fahrer.fraechter)
            .map(item => item.fahrer.fraechter.name)
        : [];



    const applyFilter = () => {
        if (data && Array.isArray(data)) {
            const filteredData1 = data.filter(item => {
                const schulungMatches = item.schulung.id === Number(id);
                console.log(fahrerFilter)
                const currentDate = new Date();
                const lastMonthDate = new Date();
                lastMonthDate.setMonth(currentDate.getMonth() - 1);

                const fahrerMatches = !fahrerFilter || (item.fahrer ? item.fahrer.name.includes(fahrerFilter) : item.fraechter && item.fraechter.name.includes(fahrerFilter));
                const fraechterMatches = !fraechterFilter || (item.fahrer && item.fahrer.fraechter && item.fahrer.fraechter.name.includes(fraechterFilter));

                const lastPruefung = getLastPruefung(item.durchgefuehrtePruefungen);
                const pruefungDate = lastPruefung ? new Date(lastPruefung.zeitpunkt) : currentDate; // Verwenden Sie das aktuelle Datum, wenn keine Prüfung vorhanden ist

                const dateVon = vonDate ? new Date(vonDate) : lastMonthDate;
                const dateBis = bisDate ? new Date(bisDate) : currentDate;
                dateVon.setHours(0, 0, 0, 0);

                dateBis.setHours(23, 59, 59, 999);

                const vonMatches = pruefungDate >= dateVon;
                const bisMatches = pruefungDate <= dateBis;


                console.log("*** VON DATE: " + vonDate)
                console.log("*** BIS DATE: " + bisDate)
                console.log("*** VON DATUM: " + dateVon)
                console.log("*** BIS DATUM: " + dateBis)
                console.log("*** PRUEFUNGSDATE: " + pruefungDate)
                console.log("*** LASTPRUEFUNG: " + lastPruefung?.zeitpunkt)
                console.log("*** VON MATCHES: " + vonMatches)
                console.log("*** BIS MATCHES: " + bisMatches)


                const bestanden = lastPruefung?.bestanden;
                const bestandenMatches = bestandenCheckbox ? bestanden : true;
                const nichtBestandenMatches = nichtBestandenCheckbox ? !bestanden : true;

                return schulungMatches && fahrerMatches && fraechterMatches && vonMatches && bisMatches && bestandenMatches && nichtBestandenMatches;
            });
            setDisplayedData(filteredData1);
        }
    };




    const resetFilter = () => {
        setFahrerFilter("");
        setFraechterFilter("");
        setVonDate("");
        setBisDate("");
        setBestandenCheckbox(false);
        setNichtBestandenCheckbox(false);
        const filteredData = data?.filter((data) => {
            return data.schulung.id === Number(id)
        })
        setDisplayedData(filteredData);
    };


    return (

        <div className="admin-pruefungsergebnisse">
            <Sidebar
            />
            <div className="ape-innerwindow">
                <b className="ape-text">
                    <p className="prfungsergebnisse-aller">{t("admin.pages.pages-ape-ueberschrift")} {t("admin.pages.pages-ape-Prüfungsmodul")} {schulung?.name}</p>
                </b>
                <div className="ape-upper-section" style={{ marginTop: "20px" }}>
                    <div className="ape-filter-container">
                        <div className="ape-date-inputs">
                            <div className="ape-filter-inputs">
                                <AutocompleteInput
                                    suggestions={fahrerNames}
                                    placeholder={t("admin.pages.pages-ape-FilterPlaceholderFahrer")}
                                    value={fahrerFilter}
                                    onChange={e => setFahrerFilter(e.target.value)}
                                />

                                <AutocompleteInput
                                    suggestions={fraechterNames}
                                    placeholder={t("admin.pages.pages-ape-FilterPlaceholderFraechter")}
                                    value={fraechterFilter}
                                    onChange={e => setFraechterFilter(e.target.value)}
                                />                            </div>
                        </div>
                        <div className="ape-date-inputs">
                            <div className="ape-filter-inputs">
                                <div className="ape-date-inputs">
                                    <label style={{ alignSelf: "center" }} htmlFor="vonDate">{t("admin.pages.pages-ape-FilterVon")}</label>
                                    <input className="ape-fahrer-input" type="date" id="vonDate" value={vonDate} onChange={e => setVonDate(e.target.value)} />
                                </div>
                                <div className="ape-frage-option">
                                    <input type="checkbox" className="ape-checkbox" checked={bestandenCheckbox} onChange={e => setBestandenCheckbox(e.target.checked)} />
                                    <div className="ape-fahrer">{t("admin.pages.pages-ape-FilterBestanden")}</div>
                                </div>
                            </div>
                            <div className="ape-filter-inputs">
                                <div className="ape-date-inputs">
                                    <label style={{ alignSelf: "center" }} htmlFor="bisDate">{t("admin.pages.pages-ape-FilterBis")}</label>
                                    <input className="ape-fahrer-input" type="date" id="bisDate" value={bisDate} onChange={e => setBisDate(e.target.value)} />
                                </div>
                                <div className="ape-frage-option">
                                    <input type="checkbox" className="ape-checkbox" checked={nichtBestandenCheckbox} onChange={e => setNichtBestandenCheckbox(e.target.checked)} />
                                    <div className="ape-fahrer">{t("admin.pages.pages-ape-FilterNichtBestanden")}</div>
                                </div>
                            </div>
                        </div>

                        <div className="ape-filter-buttons">
                            <div className="ape-filter-btn ape-common-btn" onClick={applyFilter}>
                                <div className="ape-fahrer">{t("admin.pages.pages-ape-Filtern")}</div>
                            </div>
                            <div className="ape-filter-reset-btn ape-common-btn" onClick={resetFilter}>
                                <div className="ape-fahrer">{t("admin.pages.pages-ape-FilterZuruecksetzen")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ape-list-component1" style={{ backgroundColor: "white", border: 0, height: "10%" }}>
                    <div className="ape-fahrer-table">{t("admin.pages.pages-ape-Fahrername")}</div>
                    <div className="ape-fahrer-table">{t("admin.pages.pages-ape-AnzahlWiederholungen")}</div>
                    <div className="ape-fahrer-table">{t("admin.pages.pages-ape-Pruefungsdatum")}</div>
                    <div className="ape-fahrer-table">{t("admin.pages.pages-ape-Punkteanzahl")}</div>

                    <div className="ape-fahrer-table">{t("admin.pages.pages-ape-Fraechter")}</div>

                    <div className="ape-fahrer-table">
                        <p className="prfungsergebnisse-aller" style={{ alignItems: "center" }}>{t("admin.pages.pages-ape-Bestanden-NichtBestanden")}<br />{t("admin.pages.pages-ape-Bestanden-NichtBestanden1")}</p>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "stretch", flexDirection: "column", width: "100%", overflowY: "auto" }}>
                    <ApeListComponent drivers={displayedData} id={id} />
                </div>
                <div className="ape-ueberschrift">
                    <button className="ape-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={() => navigate(-1)}>
                        <div className="ape-uebersichtsnav-btn-text">{t("admin.pages.pages-ape-Zuruek")}</div>
                    </button>
                </div>
            </div>
        </div>
    );


};

export default AdminPruefungsergebnisse;


function ApeListComponent({ drivers, id }) {

    const [timer, setTimer] = useState(2);

    const [change, { isLoading }] = useChangePruefungsErgebnisMutation();

    const [reset, { isLoading1 }] = useResetPruefungsErgebnisMutation();

    const [add, { isLoading2 }] = useAddPruefungsErgebnisMutation();

    const [showDeletePopup, setShowDeletePopup] = useState(-1);

    const { startLoading, stopLoading } = useLoading();

    const [results, setResults] = useState(
        drivers.map(driver =>
            driver.durchgefuehrtePruefungen.length > 0
                ? driver.durchgefuehrtePruefungen[driver.durchgefuehrtePruefungen.length - 1]?.bestanden
                    ? "bestanden"
                    : "nicht-bestanden"
                : "nicht-bestanden2"
        )
    );

    useEffect(() => {
        if (isLoading || isLoading1 || isLoading2) {
            console.log("start loading");
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoading, isLoading1, isLoading2, startLoading, stopLoading]);

    const getLastPruefung = (pruefungen) => {
        return [...pruefungen].sort((a, b) => new Date(b.zeitpunkt) - new Date(a.zeitpunkt))[0];
    }


    const { t } = useTranslation('common');
    const DeletePopup = ({ onClose, onDelete }) => {
        useEffect(() => {
            if (timer > 0) {
                const timeout = setTimeout(() => setTimer(timer - 1), 1000);
                return () => clearTimeout(timeout);
            }
        }, []);

        return (
            <div className="akd-delete-popup-overlay">
                <div className="akd-delete-popup">
                    <div>{t("admin.pages.pages-ape-InfoPruefungswiederholungenZurueck")}</div>
                    <p></p>
                    {timer > 0 ? <div>{t("admin.pages.pages-ape-WartenInfo-1")} {timer} {t("admin.pages.pages-ape-WartenInfo-2")} </div> : <button onClick={onDelete}>{t("admin.pages.pages-ape-ErgebnisZuruecksetzen")}</button>}
                    <button onClick={onClose}>{t("admin.pages.pages-ape-Abbrechen")}</button>
                </div>
            </div>
        );
    };


    function formatDate(isoString) {
        if (!isoString) return "";
        const date = new Date(isoString);
        return date.toLocaleDateString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    }




    return (
        <>
            {drivers.slice().sort((a, b) => {
                // Bestimme den anzuzeigenden Namen für A und B (Fahrer oder Frächter)
                const fraechterA = a.fahrer ? a.fahrer.fraechter ? a.fahrer.fraechter.name : "" : "Ist Frächter";
                const fraechterB = b.fahrer ? b.fahrer.fraechter ? b.fahrer.fraechter.name : "" : "Ist Frächter";
                if (fraechterA !== fraechterB) {
                    return fraechterA.localeCompare(fraechterB);
                }

                // Wenn der Frächtername gleich ist, nach dem Namen des Fahrers sortieren
                return a.fahrer && b.fahrer ? a.fahrer.name.localeCompare(b.fahrer.name) : 0;
            }).map((driver, index) => {
                const lastPruefung = getLastPruefung(driver.durchgefuehrtePruefungen);
                let pruefungStatus = t("admin.pages.pages-ape-NichtTeilgenommen");
                const certificate_url = driver.certificate_url;
                let btnClass = 'btn3';


                if (lastPruefung) {
                    pruefungStatus = lastPruefung.bestanden ? t("admin.pages.pages-ape-Bestanden") : t("admin.pages.pages-ape-NichtBestanden");
                    btnClass = lastPruefung.bestanden ? 'btn' : 'btn1';
                }

                const punkteAnzahlGes = driver.schulung.pruefung.questions.reduce((sum, question) => {
                    return sum + question.answers.length;
                }, 0);

                const punkteErreicht = Math.round(punkteAnzahlGes * (lastPruefung?.ergebnis / 100));

                const handleButtonClick = (fahrerId, pruefung) => {
                    if (isLoading || isLoading1 || isLoading2) return;
                    if (pruefung) {
                        change({ pruefungsID: pruefung.id, bestanden: !pruefung.bestanden });
                    } else {
                        add({ schulungs_id: id, fahrer_id: fahrerId, ergebnis: 100 });
                    }
                };

                return (
                    <div key={driver.fahrer ? driver.fahrer.id : driver.fraechter.id} className="ape-list-component1" >
                        <div className="ape-fahrer-table">{driver.fahrer ? driver.fahrer.name : driver.fraechter.name}</div>
                        <div className="ape-fahrer-table1">
                            <div className="ape-refresh-div"></div>
                            <div className="ape-refresh-div">{driver.durchgefuehrtePruefungen.length}</div>
                            <div title="Den letzten Prüfungsversuch löschen" onClick={() => { setShowDeletePopup(driver.fahrer ? driver.fahrer.id : driver.fraechter.id); console.log("SHOW DELTE POP UP", index) }} className="ape-refresh-button">
                                <FaSync />
                            </div>
                            {showDeletePopup === (driver.fahrer ? driver.fahrer.id : driver.fraechter.id) && (
                                <DeletePopup
                                    onClose={() => { setShowDeletePopup(null); setTimer(2); }}
                                    onDelete={() => {
                                        console.log(driver.fahrer.name);
                                        reset({ pruefungsID: lastPruefung?.id }); setShowDeletePopup(null);
                                    }}
                                />
                            )}
                        </div>
                        <div className="ape-fahrer-table">{formatDate(lastPruefung?.zeitpunkt)}</div>
                        <div className="ape-fahrer-table">{punkteErreicht ? punkteErreicht : 0} / {punkteAnzahlGes}</div>
                        <div className="ape-fahrer-table">{driver.fahrer ? driver.fahrer.fraechter ? driver.fahrer.fraechter.name : t("admin.pages.pages-ape-KeinFraechter") : "Ist Frächter"}</div>

                        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                            <div onClick={() => {
                                if (isLoading || isLoading1 || isLoading2) {
                                    return;
                                }
                                handleButtonClick(driver.fahrer ? driver.fahrer.id : driver.fraechter.id, lastPruefung);
                                console.log(driver.fahrer ? driver.fahrer.name : driver.fraechter.name);
                            }

                            } className={`ape-hinzufuegen-${btnClass}`}>
                                <div className="ape-pruefungsergebnis">{pruefungStatus}</div>
                            </div>
                            {btnClass === "btn" &&
                                <div onClick={() => {

                                    if (isLoading || isLoading1 || isLoading2) {
                                        return;
                                    }

                                    const link = document.createElement('a');
                                    link.href = certificate_url; // Verwenden Sie Ihren certificate_url
                                    link.download = certificate_url.split('/').pop(); // optional: Name der heruntergeladenen Datei
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                }}
                                    className={`ape-hinzufuegen-${btnClass}`}>
                                    <div className="ape-pruefungsergebnis">{t("admin.pages.pages-ape-Certificate")}</div>
                                </div>
                            }

                        </div>

                    </div >
                );
            })}
        </>
    );



}


const AutocompleteInput = ({ suggestions, placeholder, value, onChange }) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [userInput, setUserInput] = useState("");

    const onInputChange = e => {
        const userInput = e.target.value;

        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        setFilteredSuggestions(filteredSuggestions);
        setActiveSuggestion(0);
        setShowSuggestions(true);
        setUserInput(e.target.value);
        onChange(e); // Hinzugefügt, um die Elternkomponente zu informieren

    };

    const onSuggestionClick = suggestion => {
        setFilteredSuggestions([]);
        setActiveSuggestion(0);
        setShowSuggestions(false);
        setUserInput(suggestion);
        onChange({ target: { value: suggestion } }); // Hinzugefügt, um die Elternkomponente zu informieren
    };

    return (
        <div className="ape-input-wrapper">
            <input
                className="ape-fahrer-input"
                type="text"
                onChange={onInputChange}
                value={value}
                placeholder={placeholder}
            />
            {showSuggestions && userInput && (
                <div className="ape-suggestions-container">
                    {filteredSuggestions.map((suggestion, index) => {
                        return (
                            <div
                                key={index}
                                className="ape-suggestion-item"
                                onClick={() => {
                                    onSuggestionClick(suggestion);
                                }}
                            >
                                {suggestion}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
