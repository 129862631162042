import { apiSlice } from "../../../app/api/apiSlice";

export const adminInfoSectionsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addInfoBereich: builder.mutation({
            query: (data) => ({
                url: `admin/informationsbereich/${data.schulungID}`,
                method: 'POST',
                body: data.informationsbereich
            }),
            invalidatesTags: (result, error, { schulungID }) => [{ type: 'Schulung', id: schulungID }],
        }),
        updateInfoBereich: builder.mutation({
            query: (data) => ({
                url: `admin/informationsbereich/${data.schulungID}/${data.informationsbereichID}`,
                method: 'PUT',
                body: data.informationsbereich
            }),
            invalidatesTags: (result, error, { schulungID }) => [{ type: 'Schulung', id: schulungID }],
        }),
        deleteInfoBereich: builder.mutation({
            query: (data) => ({
                url: `admin/informationsbereich/${data.schulungID}/${data.informationsbereichID}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { schulungID }) => [{ type: 'Schulung', id: schulungID }],
        }),
    })
})

export const {
    useAddInfoBereichMutation,
    useDeleteInfoBereichMutation,
    useUpdateInfoBereichMutation
} = adminInfoSectionsSlice

