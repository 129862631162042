import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./FR-Pruefungsergebnisse.css";
import { useEffect, useState } from "react";
import { useLoading } from "../../components/LoadingSpinner/LoadingContext";
import { useGetPruefungsErgebnisseFraechterQuery } from "../../features/schulungen/carrierMaster/carrierMasterSchulungenSlice";
import { useTranslation } from "react-i18next";

const FraechterPruefungsergebnisse = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [t] = useTranslation('common');
    const { data, isLoading, isSuccess } = useGetPruefungsErgebnisseFraechterQuery();
    const [displayedData, setDisplayedData] = useState([])

    const { startLoading, stopLoading } = useLoading();

    useEffect(() => {
        if (isLoading) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoading, startLoading, stopLoading]);

    useEffect(() => {
        if (isSuccess) {
            console.log("ergebnisse: ", data)
            console.log("schulungsname: ", id)
            const filteredData = data?.filter((data) => {
                return data.schulung.id === Number(id)
            })
            console.log("filteredData: ", filteredData) //filteredData is undefined
            resetFilter();
        }
    }, [isSuccess, data]);


    const [fahrerFilter, setFahrerFilter] = useState("");
    const [fraechterFilter, setFraechterFilter] = useState("");
    const currentDate = new Date();
    const lastMonthDate = new Date();
    lastMonthDate.setMonth(currentDate.getMonth() - 1);

    const [vonDate, setVonDate] = useState(lastMonthDate.toISOString().split('T')[0]);
    const [bisDate, setBisDate] = useState(currentDate.toISOString().split('T')[0]);
    const [bestandenCheckbox, setBestandenCheckbox] = useState(false);
    const [nichtBestandenCheckbox, setNichtBestandenCheckbox] = useState(false);


    const getLastPruefung = (pruefungen) => {
        return [...pruefungen].sort((a, b) => new Date(b.zeitpunkt) - new Date(a.zeitpunkt))[0];
    }

    const fahrerNames = data
        ? data.filter(item => item.schulung.id === Number(id)).map(item => item.fahrer.name)
        : [];

    const fraechterNames = data
        ? data.filter(item => item.schulung.id === Number(id) && item.fahrer.fraechter).map(item => item.fahrer.fraechter.name)
        : [];

    const applyFilter = () => {
        if (data && Array.isArray(data)) {
            const currentDate = new Date(); // Aktuelles Datum
            const filteredData1 = data.filter(item => {
                const schulungMatches = item.schulung.id === Number(id);
                console.log(fahrerFilter)

                const fahrerMatches = !fahrerFilter || item.fahrer.name.includes(fahrerFilter);
                const fraechterMatches = !fraechterFilter || (item.fahrer.fraechter && item.fahrer.fraechter.name.includes(fraechterFilter));

                const lastPruefung = getLastPruefung(item.durchgefuehrtePruefungen);
                const pruefungDate = lastPruefung ? new Date(lastPruefung.zeitpunkt) : currentDate; // Verwenden Sie das aktuelle Datum, wenn keine Prüfung vorhanden ist

                const vonMatches = !vonDate || pruefungDate >= new Date(vonDate);
                const bisMatches = !bisDate || pruefungDate <= new Date(bisDate);

                const bestanden = lastPruefung?.bestanden;
                const bestandenMatches = bestandenCheckbox ? bestanden : true;
                const nichtBestandenMatches = nichtBestandenCheckbox ? !bestanden : true;

                return schulungMatches && fahrerMatches && fraechterMatches && vonMatches && bisMatches && bestandenMatches && nichtBestandenMatches;
            });
            setDisplayedData(filteredData1);
        }
    };




    const resetFilter = () => {
        setFahrerFilter("");
        setFraechterFilter("");
        setVonDate("");
        setBisDate("");
        setBestandenCheckbox(false);
        setNichtBestandenCheckbox(false);
        const filteredData = data?.filter((data) => {
            return data.schulung.id === Number(id)
        })
        setDisplayedData(filteredData);
    };


    return (

        <div className="admin-pruefungsergebnisse">
            <Sidebar />
            <div className="ape-innerwindow1">
                <b className="ape-text">
                    <p className="prfungsergebnisse-aller">{t("admin.pages.pages-ape-ueberschrift")} {t("admin.pages.pages-ape-Prüfungsmodul")} {displayedData[0]?.schulung?.name}</p>
                </b>
                <div className="ape-upper-section" style={{ marginTop: "20px" }}>
                    <div className="ape-filter-container">
                        <div className="ape-date-inputs">
                            <div className="ape-filter-inputs">
                                <AutocompleteInput
                                    suggestions={fahrerNames}
                                    placeholder={t("admin.pages.pages-ape-FilterPlaceholderFahrer")}
                                    value={fahrerFilter}
                                    onChange={e => setFahrerFilter(e.target.value)}
                                />

                            </div>
                        </div>
                        <div className="ape-date-inputs">
                            <div className="ape-filter-inputs">
                                <div className="ape-date-inputs">
                                    <label style={{ alignSelf: "center" }} htmlFor="vonDate">{t("admin.pages.pages-ape-FilterVon")}</label>
                                    <input className="ape-fahrer-input" type="date" id="vonDate" value={vonDate} onChange={e => setVonDate(e.target.value)} />
                                </div>
                                <div className="ape-frage-option">
                                    <input type="checkbox" className="ape-checkbox" checked={bestandenCheckbox} onChange={e => setBestandenCheckbox(e.target.checked)} />
                                    <div className="ape-fahrer">{t("admin.pages.pages-ape-FilterBestanden")}</div>
                                </div>
                            </div>
                            <div className="ape-filter-inputs">
                                <div className="ape-date-inputs">
                                    <label style={{ alignSelf: "center" }} htmlFor="bisDate">{t("admin.pages.pages-ape-FilterBis")}</label>
                                    <input className="ape-fahrer-input" type="date" id="bisDate" value={bisDate} onChange={e => setBisDate(e.target.value)} />
                                </div>
                                <div className="ape-frage-option">
                                    <input type="checkbox" className="ape-checkbox" checked={nichtBestandenCheckbox} onChange={e => setNichtBestandenCheckbox(e.target.checked)} />
                                    <div className="ape-fahrer">{t("admin.pages.pages-ape-FilterNichtBestanden")}</div>
                                </div>
                            </div>
                        </div>

                        <div className="ape-filter-buttons">
                            <div className="ape-filter-btn ape-common-btn" onClick={applyFilter}>
                                <div className="ape-fahrer">{t("admin.pages.pages-ape-Filtern")}</div>
                            </div>
                            <div className="ape-filter-reset-btn ape-common-btn" onClick={resetFilter}>
                                <div className="ape-fahrer">{t("admin.pages.pages-ape-FilterZuruecksetzen")}</div>
                            </div>
                        </div>
                    </div>
                </div>                <div className="ape-list-component1" style={{ backgroundColor: "white", border: 0, height: "10%" }}>
                    <div className="ape-fahrer-table">{t("carrierMaster.fr-pe-Fahrername")}</div>
                    <div className="ape-fahrer-table">{t("carrierMaster.fr-pe-AnzahlWiederholungen")}</div>
                    <div className="ape-fahrer-table">{t("carrierMaster.fr-pe-Pruefungsdatum")}</div>
                    <div className="ape-fahrer-table">{t("carrierMaster.fr-pe-Punkteanzahl")}</div>

                    <div className="ape-fahrer-table">{t("carrierMaster.fr-pe-Fraechter")}</div>

                    <div className="ape-fahrer-table">
                        <p className="prfungsergebnisse-aller">{t("carrierMaster.fr-pe-Bestanden-NichtBestanden")}</p>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "stretch", flexDirection: "column", width: "100%", overflowY: "auto" }}>
                    <FraechterApeListComponent drivers={displayedData} />
                </div>
                <div className="ape-ueberschrift">
                    <button className="ape-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={() => navigate("/carrierMaster/details/" + id)}>
                        <div className="ape-uebersichtsnav-btn-text">{t("carrierMaster.fr-pe-Zuruek")}</div>
                    </button>
                </div>
            </div>
        </div>
    );


};

export default FraechterPruefungsergebnisse;


function FraechterApeListComponent({ drivers }) {


    const [t] = useTranslation('common');
    const [results, setResults] = useState(
        drivers.map(driver =>
            driver.durchgefuehrtePruefungen.length > 0
                ? driver.durchgefuehrtePruefungen[driver.durchgefuehrtePruefungen.length - 1]?.bestanden
                    ? "bestanden"
                    : "nicht-bestanden"
                : "nicht-bestanden2"
        )
    );

    function formatDate(isoString) {
        if (!isoString) return "";
        const date = new Date(isoString);
        return date.toISOString().replace('T', ' ').slice(0, 10); // Ersetzt 'T' durch ein Leerzeichen und entfernt das abschließende 'Z'
    }

    return (
        <>
            {drivers.map((driver, index) => {
                const lastPruefungIndex = driver.durchgefuehrtePruefungen.length > 0 ? driver.durchgefuehrtePruefungen.length - 1 : 0;
                const lastPruefung = driver.durchgefuehrtePruefungen[lastPruefungIndex];

                return (
                    <div key={index} className="ape-list-component1">
                        <div className="ape-fahrer-table">{driver.fahrer.name}</div>
                        <div className="ape-fahrer-table">{driver.durchgefuehrtePruefungen.length}</div>
                        <div className="ape-fahrer-table">{formatDate(lastPruefung?.zeitpunkt)}</div>
                        <div className="ape-fahrer-table">{lastPruefung?.ergebnis}%</div>
                        <div className="ape-fahrer-table">{driver.fahrer.fraechter ? driver.fahrer.fraechter.name : t("admin.pages.pages-ape-KeinFraechter")}</div>
                        <div className={`ape-hinzufuegen-${lastPruefung?.bestanden === true ? 'btn' : 'btn1'}`}>
                            <div className="ape-pruefungsergebnis">{lastPruefung?.bestanden ? t("admin.pages.pages-ape-FilterBestanden") : t("admin.pages.pages-ape-FilterNichtBestanden")}</div>
                        </div>
                    </div>
                );
            })}
        </>
    );

}


const AutocompleteInput = ({ suggestions, placeholder, value, onChange }) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [userInput, setUserInput] = useState("");

    const onInputChange = e => {
        const userInput = e.target.value;

        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        setFilteredSuggestions(filteredSuggestions);
        setActiveSuggestion(0);
        setShowSuggestions(true);
        setUserInput(e.target.value);
        onChange(e); // Hinzugefügt, um die Elternkomponente zu informieren

    };

    const onSuggestionClick = suggestion => {
        setFilteredSuggestions([]);
        setActiveSuggestion(0);
        setShowSuggestions(false);
        setUserInput(suggestion);
        onChange({ target: { value: suggestion } }); // Hinzugefügt, um die Elternkomponente zu informieren
    };

    return (
        <div className="ape-input-wrapper">
            <input
                className="ape-fahrer-input"
                type="text"
                onChange={onInputChange}
                value={value}
                placeholder={placeholder}
            />
            {showSuggestions && userInput && (
                <div className="ape-suggestions-container">
                    {filteredSuggestions.map((suggestion, index) => {
                        return (
                            <div
                                key={index}
                                className="ape-suggestion-item"
                                onClick={() => {
                                    onSuggestionClick(suggestion);
                                }}
                            >
                                {suggestion}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
