import { useNavigate } from "react-router-dom";
import "./SchulungsmodulCard.css";
import { useTranslation } from "react-i18next";
const SchulungsmodulCard = ({title, certificate_url, id}) => {

  const [t] = useTranslation('common');
  const navigate = useNavigate();

  return (
    <div className="modul-list-comp5">
      <b className="modulname3">{title}</b>
      <div className="buttons-frame">
        <button className="zertifikat-btn" id="ModulVerw_Btn" onClick={() => {
            const link = document.createElement('a');
            link.href = certificate_url; // Verwenden Sie Ihren certificate_url
            link.download = certificate_url.split('/').pop(); // optional: Name der heruntergeladenen Datei
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);  
        }}>
          <div className="text23">{t("carrier.smc-ZertifikatHerunterladen")}</div>
        </button>
        <button className="teilnehmen-btn4" id="ModulVerw_Btn" onClick={() => navigate(`/carrier/details/${id}`)}>
          <div className="text33">{t("carrier.smc-Bestanden")} <br></br> (Ansehen)</div>
        </button>
      </div>
    </div>
  );
};

export default SchulungsmodulCard;
