import { useEffect } from "react";
import { useLoading } from "../../../components/LoadingSpinner/LoadingContext";
import Sidebar from "../../../components/Sidebar/Sidebar";
import "./InfoBereichAnsicht.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetSchulungenForFahrerByIdQuery, useWatchInfoSectionMutation } from "../../../features/schulungen/fahrer/fahrerSchulungenSlice";
import { useTranslation } from "react-i18next";
const InfoBereichAnsicht = () => {

  const { sid, id } = useParams();
  const { data: zugeordneteSchulung, error, isLoading, isSuccess, isError } = useGetSchulungenForFahrerByIdQuery(sid);
  const [t] = useTranslation('common');
  const { startLoading, stopLoading } = useLoading();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const nextId = Number(queryParams.get('next'));
  const [watch, { isLoading2 }] = useWatchInfoSectionMutation();

  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading, startLoading, stopLoading])


  useEffect(() => {
    const currentIndex = zugeordneteSchulung?.schulung?.informationsbereiche.findIndex(bereich => Number(bereich.id) === Number(id));

    const isWatched = zugeordneteSchulung.watchedInfos.some(wInfo => Number(wInfo.informationsbereich.id) === Number(id));
    // Überprüfen Sie, ob es einen nächsten informationsbereich gibt
    if (!isWatched && !isLoading2) {
      watch({ schulungs_id: sid, bereich_id: id });
    }

  }, [zugeordneteSchulung, id, sid, watch]);


  let content;
  if (isSuccess) {
    content = <div style={{ textAlign: "left", marginBottom: 50 }} dangerouslySetInnerHTML={{ __html: zugeordneteSchulung?.schulung.informationsbereiche.find(bereich => Number(bereich.id) === Number(id)).inhalt }} />
    console.log(content)
  } else if (isError) {
    content = <p>{error}</p>;
  }

  const navigateToNextInfoBereich = (currentSid, currentId) => {
    // Finden Sie den Index des aktuellen informationsbereich
    const currentIndex = zugeordneteSchulung?.schulung?.informationsbereiche.findIndex(bereich => Number(bereich.id) === Number(currentId));
    const nextBereich = zugeordneteSchulung?.schulung?.informationsbereiche[currentIndex + 1];
    // Wenn es einen nächsten Bereich gibt, verwenden Sie dessen ID als den next-Parameter
    if (nextBereich) {
      navigate(`/carrier/${currentSid}/info/${nextBereich.id}?next=${zugeordneteSchulung?.schulung?.informationsbereiche[currentIndex + 2]?.id || -1}`);
    } else {
      if (zugeordneteSchulung.watchedInfos.length >= zugeordneteSchulung.schulung.informationsbereiche.length && !zugeordneteSchulung.bestanden) {
        navigate("/carrier/exam/" + sid)
      }
    }

  }


  return (
    <div className="fdans-fahrer-kursansicht">
      <div className="fdans-inner-window6" >
        {content}
      </div>
      <div>
        <button className="fdans-btn-zueruck" style={{ left: 270 }} onClick={() => navigate("/carrier/details/" + sid)}>{t("carrier.iba-ZuruekZurUebersicht")}</button>
        <button className="fdans-btn-weiter" style={{ right: 50 }} onClick={() => navigateToNextInfoBereich(sid, id, nextId)}>{nextId == -1 ? t("carrier.iba-WeiterPruefung") : t("carrier.iba-Weiter")}</button>
      </div>
      <Sidebar
      />
    </div>
  );
};

export default InfoBereichAnsicht;
