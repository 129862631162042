import React, { useEffect, useState } from 'react';
import { createPortal } from "react-dom";
import "./AddSchulung.css";
import TimeSelector from '../../../components/TimeSelector';
import { useSelector } from 'react-redux';
import { selectAllSchulungen, usePostSchulungMutation } from '../../../features/schulungen/admin/adminSchulungenSlice';
import { useLoading } from '../../../components/LoadingSpinner/LoadingContext';
import { useTranslation } from 'react-i18next';

const AddSchulung = ({ onClose }) => {
    const [schulungsName, setSchulungsName] = useState("");
    const [vorgehendeSchulung, setVorhergehendeSchulung] = useState(null);
    const [gueltigkeitsdauer, setGueltigkeitsdauer] = useState("");
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [t] = useTranslation('common');
    const schulungen = useSelector(selectAllSchulungen);
    const [addSchulung, { isLoading, isSuccess }] = usePostSchulungMutation();
    const { startLoading, stopLoading } = useLoading();

    useEffect(() => {
        if (isLoading) {
            console.log("start loading: " + isLoading);
            startLoading();
        } else {
            console.log("stop loading: " + isLoading);
            stopLoading();
        }
    }, [isLoading, startLoading, stopLoading]);


    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose])

    const handleSchulungAnlegen = () => {
        if (!schulungsName || !gueltigkeitsdauer) {
            setIsError(true);
            setErrorMessage("Bitte füllen Sie alle Felder aus!");
            return;
        }

        if (schulungen.find(schulung => schulung.name === schulungsName)) {
            setIsError(true);
            setErrorMessage("Schulung mit diesem Namen existiert bereits!");
            return;
        }
        const neueSchulung = {
            name: schulungsName,
            vorhergehendeSchulung: vorgehendeSchulung ? { id: vorgehendeSchulung } : null,
            gueltigkeitsdauer: gueltigkeitsdauer,
            informationsbereiche: [],
            beschreibung: "",
            geloescht: false,
            pruefung: {
                questions: [],
                wiederholungen: 0,
                fragenpool: 0
            }
        };
        addSchulung(neueSchulung);
    }

    return createPortal(
        <div className="admin-modal-schulung-hinzufueg">
            <div style={{fontSize: 20, fontWeight: "bold", fontFamily: "Verdana", }}>{t("admin.pages.pages-akv-SchulungHinzufuegen")}</div>
            <div className="amsh">
                <div className='amsh-input-container'>
                    <label htmlFor="schulungsName">{t("admin.pages.pages-as-Schulungsname")}</label>
                    <input
                        id="schulungsName"
                        onChange={(e) => setSchulungsName(e.target.value)}
                        value={schulungsName}
                        className="amsh-inputitem-comp"
                        placeholder={t("admin.pages.pages-as-Schulungsname")}
                        type="text"
                        maxLength={110} // Setzt das Zeichenlimit auf 100
                    />
                </div>
                <div className='amsh-input-container'>
                    <label htmlFor="vorgehendeSchulung">{t("admin.pages.pages-as-Vorgehende Schulung")}</label>
                    <select
                        id="vorgehendeSchulung"
                        onChange={(e) => setVorhergehendeSchulung(e.target.value)}
                        value={vorgehendeSchulung}
                        className="amsh-inputitem-comp2"
                    >
                        <option value="default" disabled>Vorgehende Schulung</option>
                        <option value={null}>{t("admin.pages.pages-as-KeineVorgehendeSchulung")}</option>

                        {schulungen.map((schulung, index) => (
                            <option key={index} value={schulung.id}>{schulung.name}</option>
                        ))}

                    </select>
                </div>
                <div className='amsh-input-container'>
                    <label htmlFor="gueltigkeitsdauer">{t("admin.pages.pages-as-Gueltigkeitsdauer")}</label>
                    <TimeSelector setDisplayText={setGueltigkeitsdauer} displayText={gueltigkeitsdauer} handleSave={(test) => console.log(test)} />
                </div>
            </div>
            {isError && (
                <div style={{
                    color: "red", // This will make the text color red
                    animation: "flickerAnimation 2s infinite", // Apply the animation named flickerAnimation
                    textAlign: "center",
                    fontSize: "2rem",
                }}>
                    {errorMessage}
                </div>
            )}

            <div className="amsh-uebersicht-btn">
                <button onClick={onClose} className="amsh-uebersichtsnav-btn" id="ModulVerw_Btn">
                    <div className="amsh-uebersichtsnav-btn-text">{t("admin.pages.pages-as-FilterZuruecksetzen")}</div>
                </button>
                <button onClick={(e) => { e.preventDefault(); handleSchulungAnlegen(); }} disabled={isLoading} className="amsh-uebersichtsnav-btn" id="ModulVerw_Btn">
                    <div className="amsh-uebersichtsnav-btn-text">{t("admin.pages.pages-as-Speichern")}</div>
                </button>
            </div>
        </div>,
        document.getElementById('modal-root-4')
    );
};

export default AddSchulung;
