import { useTranslation } from "react-i18next";
import Sidebar from "../../../components/Sidebar/Sidebar";
import "./FahrerPruefungsstart.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSchulungenForFahrerByIdQuery } from "../../../features/schulungen/fahrer/fahrerSchulungenSlice";
const FahrerPruefungsstart = () => {

  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { id } = useParams();
  const { data: zugeordneteSchulung, error, isLoading, isSuccess, isError } = useGetSchulungenForFahrerByIdQuery(id);

  return (
    <div className="fpu-fahrer-pruefungsstart">
      <div className="fpu-inner-window5">
        <div className="fpu-actions-list-1">
          <button onClick={() => { navigate(`/carrier/exam/${id}/start`) }} className="fpu-starten-btn" id="ModulVerw_Btn">
            <div className="fpu-teilnehmen4">{t("carrier.pruefung.fps-PruefungStarten")}</div>
          </button>
          <div className="fpu-infotext">{t("carrier.pruefung.fps-InfoPruefungStarten")}</div>
          <div className="fpu-infotext" style={{fontSize: 30}}>{t("carrier.fka-InfoVersuchanzahl1")} {zugeordneteSchulung?.schulung?.pruefung.wiederholungen - zugeordneteSchulung?.durchgefuehrtePruefungen.length} {t("carrier.fka-InfoVersuchanzahl2")}
          </div>
          <button onClick={() => { navigate("/carrier/details/" + id) }} className="fpu-uebersicht-btn" id="ModulVerw_Btn">
            <div className="fpu-teilnehmen4">{t("carrier.pruefung.fps-ZuruekZurUebersicht")}</div>
          </button>
        </div>
      </div>
      <Sidebar />
    </div>
  );
};

export default FahrerPruefungsstart;
