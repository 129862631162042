import { useMemo } from "react";
import "./ErgebnisListItem.css";
import { useTranslation } from "react-i18next";
const ErgebnisListItem = ({
    afvwListItemFertigBtnBackgroundColor,
    afvwListItemVersucheText,
    afvwListItemErgebnisseBtnBackgroundColor,
    bestanden,
    thema,
    onButtonClick,
    onCertificateButtonClick
}) => {

    const [t] = useTranslation('common');
    const afvwListItemFertigBtnStyle = useMemo(() => {
        return {
            backgroundColor: afvwListItemFertigBtnBackgroundColor,
        };
    }, [afvwListItemFertigBtnBackgroundColor]);

    const afvwListItemErgebnisseBtnStyle = useMemo(() => {
        return {
            backgroundColor: afvwListItemErgebnisseBtnBackgroundColor,
        };
    }, [afvwListItemErgebnisseBtnBackgroundColor]);

    return (
        <div className="afvw-list-item" >
            <div className="afvw-list-item-item-begin" style={{cursor: "crosshair"}}>
                <div className="afvw-list-item-fertig-btn1"  style={afvwListItemFertigBtnStyle}>
                    {bestanden ? t("admin.afav.afav-Fertig") : t("admin.afav.afav-Offen")}
                </div>
            </div>
            <div className="afvw-list-item-item" >
                {thema}
            </div>
            <div className="afvw-list-item-item">
                {afvwListItemVersucheText}
            </div>
            <div className="afvw-list-item-item-end" style={{ gap: "10px", display: "flex", flexDirection: "column" }}>
                <div
                    className="afvw-list-item-fertig-btn"
                    id="Ergebnis-Btn"
                    style={afvwListItemFertigBtnStyle}
                    onClick={() => { onButtonClick() }}
                >
                    {t("admin.afav.afav-Ergebnis")}
                </div>

                {bestanden && (
                    <div
                        className="afvw-list-item-fertig-btn"
                        id="Ergebnis-Btn"
                        style={afvwListItemFertigBtnStyle}
                        onClick={() => { onCertificateButtonClick() }}
                    >
                        {t("admin.pages.pages-ape-Certificate")}
                    </div>
                )
                }

            </div >
        </div >
    );
};

export default ErgebnisListItem;
