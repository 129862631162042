import { useMemo } from "react";
import "./AMFListComponent.css";

const AMFListComponent = ({
  personName,
  fraechtername,
  aMFHinzufuegenBtnBackgroundColor,
  aMFBTNText,
  aMFBTNTextColor,
  onButtonClick,
}) => {
  const aMFHinzufuegenBtnStyle = useMemo(() => ({
    backgroundColor: aMFHinzufuegenBtnBackgroundColor,
  }), [aMFHinzufuegenBtnBackgroundColor]);

  const aMFBTNTextStyle = useMemo(() => ({
    color: aMFBTNTextColor,
    alignSelf: "center",
  }), [aMFBTNTextColor]);

  // return (
  //   <div className="amf-list-component">
  //     <div className="amf-person-container">
  //       <div className="amf-personname">{personName}</div>
  //       <div className="amf-stroke" />
  //       <div className="amf-personname">{fraechtername}</div>
  //     </div>
      // <button 
      //   onClick={onButtonClick} 
      //   className="amf-hinzufuegen-btn" 
      //   style={aMFHinzufuegenBtnStyle}
      // >
      //   <div className="amf-btn-text2" style={aMFBTNTextStyle}>
      //     {aMFBTNText}
      //   </div>
      // </button>
  //   </div>
  // );

  return (
    <div className="afhb-list-component1">
      <div className="afhb-fahrer-table" >{personName}</div>
      <div className="afhb-fahrer-table" style={{textAlign: "center"}} >{fraechtername}</div>
      <div className="afhb-fahrer-table" >
      <div 
        onClick={onButtonClick} 
        className="amf-hinzufuegen-btn" 
        style={aMFHinzufuegenBtnStyle}
      >
        <div  style={aMFBTNTextStyle}>
          {aMFBTNText}
        </div>
      </div>
      </div>
    </div>
  );


};

export default AMFListComponent;
