import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { GrCircleInformation } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import PhishingAddMailTemplateModal from "./PhishingAddMailTemplateModal";
import PhishingSendMailModal from "./PhishingSendMailModal";
import { useDeleteEmailTemplateMutation, useGetPhishingSettingsQuery, useUpdatePhishingSettingsMutation } from "../../../features/phishing/adminPhishingSlice";
import { useLoading } from "../../../components/LoadingSpinner/LoadingContext";
import { use } from "i18next";



const mailTemplates = [
    {
        id: 1,
        title: "Mail 1",
        content: "Mail 1 Content"
    },
    {
        id: 2,
        title: "Mail 2",
        content: "Mail 2 Content"
    },
    {
        id: 3,
        title: "Mail 3",
        content: "Mail 3 Content"
    },
];


const PhishingAlgorithmUebersicht = () => {


    const [t, i18n] = useTranslation("common");

    const { data: phishingSettings, error, isLoading, isSuccess, isError } = useGetPhishingSettingsQuery()
    const { startLoading, stopLoading } = useLoading();
    const [phishingSettingsMinMails, setPhishingSettingsMinMails] = useState(0);
    const [updatePhishingSettings] = useUpdatePhishingSettingsMutation();
    const [deleteEmailTemplate] = useDeleteEmailTemplateMutation();

    useEffect(() => {
        console.log('Is loading:', isLoading);
        console.log('Is success:', isSuccess);
        console.log('Error:', error);
        console.log('Phishing Settings:', phishingSettings);
        if (isSuccess && phishingSettings) {
            console.log("Minimum Emails Per Month:", phishingSettings.minimumEmailsPerMonth);
            setPhishingSettingsMinMails(phishingSettings.minimumEmailsPerMonth);
        }
    }, [isLoading, isSuccess, phishingSettings, error]);


    useEffect(() => {
        if (isLoading) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoading, startLoading, stopLoading]);


    const handleEditmailTemplate = (mailTemplate) => {
        setIsEditTemplateModalOpen(true);
        setEditableTemplate(mailTemplate);
    }

    const handleDeletemailTemplatee = (mailTemplateId) => {
        deleteEmailTemplate(mailTemplateId)
    }

    const handleSaveMinMails = () => {
        console.log('Save Minimum Emails Per Month:', phishingSettingsMinMails);
        updatePhishingSettings({ minimumEmailsPerMonth: phishingSettingsMinMails });
    }

    const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
    const [isEditTemplateModalOpen, setIsEditTemplateModalOpen] = useState(false);
    const [isSendMailModalOpen, setIsSendMailModalOpen] = useState(false);
    const [editableTemplate, setEditableTemplate] = useState({});

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            height: '100%', // Ensure it fills the parent's height
            marginTop: 20,
        }}>
            <div style={{ display: "flex", border: "1px solid black", flex: 1, flexDirection: "column", padding: 30, justifyContent: "center", gap: 30 }}>
                <div style={{
                    height: 70,
                    backgroundColor: "gray",
                    display: "flex",  // Change display to flex
                    justifyContent: "center",  // Center horizontally
                    alignItems: "center",  // Center vertically
                    textAlign: "center",  // Text alignment to center
                    color: "white",  // Set text color to white for better visibility on blue background
                    fontSize: 25,
                    fontFamily: "Verdana",
                    cursor: "pointer"
                }}
                    onClick={() => setIsSendMailModalOpen(true)}
                >
                    {t("admin.pages.pages-akd-pishing-send-employee")}
                </div>
                <div style={{
                    height: 70,
                    display: "flex",  // Change display to flex
                    bottom: 30,
                    position: "absolute",
                    alignSelf: "center",
                    flexDirection: "column",
                    justifyContent: "center",  // Center horizontally
                    alignItems: "center",  // Center vertically
                    textAlign: "center",  // Text alignment to center
                    color: "black",  // Set text color to white for better visibility on blue background
                    fontSize: 18,
                    fontFamily: "Verdana"
                }}>
                    <div style={{
                        flex: 1,
                        justifyContent: "center",  // Center horizontally
                        alignItems: "center",  // Center vertically
                        textAlign: "center",  // Text alignment to center
                        display: "flex"
                    }}>                    {t("admin.pages.pages-akd-pishing-settings-min-mails")}

                        <GrCircleInformation className="icon" style={{ marginLeft: 5, color: 'black', fontSize: '24px', cursor: "pointer" }} />
                        <div className="tooltip">
                            Die Mindestanzahl an Phishingmails, die an einen Mitarbeiter pro Monat gesendet werden sollen.
                        </div>

                    </div>
                    <div style={{ width: "100%", flex: 1, display: "flex", flexDirection: "row", border: "1px solid black" }}>
                        <input
                            value={phishingSettingsMinMails}
                            onChange={(e) => {
                                const value = Math.max(0, Math.min(100, Number(e.target.value)));
                                setPhishingSettingsMinMails(value);
                            }}

                            placeholder="0"
                            type="number"
                            min="0"
                            max="10"
                            style={{ width: "100%", height: "90%", border: "none", textAlign: "center", borderRadius: 0 }}
                            className="afw-input-new"
                        />

                        <div style={{ width: "30%", height: "100%", borderLeft: "1px solid black", cursor: "pointer" }} onClick={handleSaveMinMails}>
                            <FaCheck style={{ color: "green", fontSize: 30 }} />

                        </div>

                    </div>
                </div>
            </div>
            <div className="akd-actions-list">
                <div className="akd-upper-information-section">
                    <div className="akd-label">
                        <b className="akd-informationsbereiche">{t("admin.pages.pages-akd-pishing-title-mails")}
                        </b>
                    </div>
                </div>
                <div className="akd-frame-parent" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <div className="akd-menuitems2" style={{ flex: 1, overflowY: "auto" }}>
                        {
                            isSuccess && phishingSettings.templates ?
                                [...phishingSettings.templates] // Erstellen Sie eine Kopie des Arrays, um das ursprüngliche Array unverändert zu lassen
                                    .sort((a, b) => a.id - b.id) // Sortieren Sie das Array basierend auf der `id` in aufsteigender Reihenfolge
                                    .map((mailTemplate, index) => (
                                        <div className="akd-menuitem2-1" key={mailTemplate.id}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                backgroundColor: "#e9e9e9",
                                                width: "90%",
                                                marginBottom: 20,
                                                boxSizing: "border-box",
                                                padding: 10,
                                                height: 80,
                                                borderRadius: 10,
                                                position: "relative",
                                                cursor: "pointer"
                                            }}>
                                                <div style={{
                                                    position: "absolute",
                                                    left: "50%",
                                                    transform: "translateX(-50%)",
                                                    width: "70%",
                                                    textAlign: "center",
                                                    color: "black",
                                                    fontSize: 22,
                                                }}>
                                                    {mailTemplate.title}
                                                </div>
                                                <div style={{ marginLeft: "auto", display: "flex", flexDirection: "row", alignItems: "center", gap: 20 }}>
                                                    <FaEdit onClick={() => handleEditmailTemplate(mailTemplate)} size={30} color="#434343" />
                                                    <MdDelete onClick={() => handleDeletemailTemplatee(mailTemplate.id)} size={30} color="#434343" />
                                                </div>
                                            </div>
                                        </div>
                                    )) : null
                        }
                    </div>
                    {/* <button className="akd-delete-btn" onClick={() => setIsModalOpen(true)} style={{ alignSelf: "center", marginBottom: "20px", width: 250, height: 50 }}> */}

                    <button className="akd-delete-btn" onClick={() => setIsTemplateModalOpen(true)} style={{ alignSelf: "center", marginBottom: "20px", width: 250, height: 50 }}>
                        <div className="akd-schulung-lschen">{t("admin.pages.pages-akd-pishing-add-mails")}</div>

                    </button>

                    {isTemplateModalOpen && (
                        <PhishingAddMailTemplateModal open={isTemplateModalOpen} onClose={() => setIsTemplateModalOpen(false)} />
                    )}

                    {isEditTemplateModalOpen && (
                        <PhishingAddMailTemplateModal initialTemplate={editableTemplate} open={isEditTemplateModalOpen} isEditing={true} onClose={() => setIsEditTemplateModalOpen(false)} />
                    )}

                    {isSendMailModalOpen && (
                        <PhishingSendMailModal open={isSendMailModalOpen} onClose={() => setIsSendMailModalOpen(false)} />
                    )}

                </div>
            </div>
        </div>
    );
}

export default PhishingAlgorithmUebersicht;