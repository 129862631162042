import { useEffect, useRef, useState } from "react";
import "./AnswerComp.css";

const AnswerComp = ({ text, id, onAnswerToggle, isInitiallyChecked, image }) => {
  const checkboxRef = useRef(null);
  const [isChecked, setIsChecked] = useState(isInitiallyChecked || false);

  const handleDivClick = () => {
    setIsChecked((prevCheck) => {
      const newCheckedStatus = !prevCheck;
      onAnswerToggle(id, newCheckedStatus);
      return newCheckedStatus;
    });
  };

  useEffect(() => {
    setIsChecked(isInitiallyChecked);
  }, [isInitiallyChecked]);


  const handleCheckboxClick = (e) => {
    e.stopPropagation();
    setIsChecked((prevCheck) => {
      const newCheckedStatus = !prevCheck;
      onAnswerToggle(id, newCheckedStatus);
      return newCheckedStatus;
    });
  };

  return (
    <div className="antwort-comp" onClick={handleDivClick}>
      <div className="frage">
        {image ? (
          <img src={image} alt={text} width={200} className="antwort-bild" />
        ) : (
          <div className="die-3-kummernummer">{text}</div>
        )}
      </div>
      <input
        ref={checkboxRef}
        className="buttons"
        type="checkbox"
        checked={isChecked}
        onClick={handleCheckboxClick}
      />
    </div>
  );
};

export default AnswerComp;
