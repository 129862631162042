import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation, useLazyWhoamiQuery } from '../features/authApiSlice';
import { useDispatch } from 'react-redux';
import { setCredentials } from "../features/authSlice";
import { useLoading } from '../components/LoadingSpinner/LoadingContext';

function Redirect() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [login, { isLoading: isLoginLoading }] = useLoginMutation();
    const [fetchWhoami, { isLoading: isWhoamiLoading }] = useLazyWhoamiQuery();

    const handleLogin = useCallback(async () => {
        const asyncLogin = async () => {

            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const state = urlParams.get('state');

            const localState = window.sessionStorage.getItem('oauth2state');
            if (localState !== state) {
                console.error('States do not match!');
                return;
            }

            const codeVerifier = window.localStorage.getItem('code_verifier');

            console.log("running handleLogin")

            if (code) {
                const requestBody = {
                    grant_type: 'authorization_code',
                    client_id: 'mts',
                    code_verifier: codeVerifier,
                    code: code,
                    scope: 'carrier%20medlog',
                };
                try {
                    const loginResponse = await login(requestBody).unwrap();
                    dispatch(setCredentials({ ...loginResponse, user: null }));
                    const whoamiData = await fetchWhoami().unwrap();
                    dispatch(setCredentials({ ...loginResponse, user: whoamiData }));

                    if (whoamiData.authorities.some(authority => ["ADMIN", "SUPER_ADMIN"].includes(authority))) {
                        navigate('/admin');
                    }else if(whoamiData.authorities.some(authority => ["FAHRER", "USER"].includes(authority))){
                        navigate('/carrier');
                    }else{
                        navigate("/carrierMaster")
                    }
                } catch (loginError) {
                    console.error('Login failed:', loginError);
                }
            }
        };
        asyncLogin();
    }, [login, fetchWhoami, dispatch, navigate]);

    useEffect(() => {
        handleLogin();
    }, [handleLogin]);

    const { startLoading, stopLoading } = useLoading();

    useEffect(() => {
        if (isLoginLoading || isWhoamiLoading) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoginLoading, isWhoamiLoading, startLoading, stopLoading]);

    if (isLoginLoading || isWhoamiLoading) {
        return <div>"loading..."</div>;
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100vh',
            }}
        >
        </div>
    );
}

export default Redirect;
