import { createPortal } from "react-dom";
import AMFListComponent from "./AMFListComponent";
import "./AddFahrerToSchulung.css";
import { useGetFahrerListQuery, usePostFahrerToSchulungMutation, useDeleteFahrerFromSchulungMutation } from "../../../features/schulungen/admin/adminFahrerSlice";
import { useLoading } from "../../../components/LoadingSpinner/LoadingContext";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const AddFahrerToSchulung = ({ onClose, schulungsId, schulungsName }) => {

  const { data, isLoading, isSuccess } = useGetFahrerListQuery(schulungsId);
  const [postFahrerToSchulung, { isLoading: isAdding }] = usePostFahrerToSchulungMutation();
  const [deleteFahrerFromSchulung, { isLoading: isDeleting }] = useDeleteFahrerFromSchulungMutation();
  const [fahrerFilter, setFahrerFilter] = useState("");
  const [fraechterFilter, setFraechterFilter] = useState("");
  const [displayedData, setDisplayedData] = useState([]);
  const [filterHinzugefuegt, setFilterHinzugefuegt] = useState(false);
  const { startLoading, stopLoading } = useLoading();
  const [filterType, setFilterType] = useState('Alle');
  const [t] = useTranslation('common');
  useEffect(() => {
    if (isLoading || isAdding || isDeleting) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading, isAdding, isDeleting, startLoading, stopLoading]);




  const applyFilter = () => {
    if (data && Array.isArray(data)) {
      let filteredData = data;
      if (filterType === 'Fahrer') {
        filteredData = filteredData.filter(item => item.fahrer);
      } else if (filterType === 'Frächter') {
        filteredData = filteredData.filter(item => item.fraechter);
      } else if (filterType === 'Mitarbeiter') {
        // First, filter to include only items where 'fahrer' is true and 'fraechter' is not null
        filteredData = filteredData.filter(item => item.fahrer && item.fahrer.fraechter);

        // Then, filter to those where the 'fraechter's name is "medlogusers"
        filteredData = filteredData.filter(item => item.fahrer.fraechter.name === "medlogusers");
      }


      if (filterHinzugefuegt) {
        filteredData = filteredData.filter(item => item.istZugeordnet);
      }


      const exaccData = filteredData.filter(d => {
        const fahrerMatches = d.fahrer ? d.fahrer.name.toLowerCase().includes(fahrerFilter.toLowerCase()) : d.fraechter.name.toLowerCase().includes(fahrerFilter.toLowerCase());
        const fraechterNameMatches = fraechterFilter ?
          d.fahrer?.fraechter?.name?.toLowerCase().includes(fraechterFilter.toLowerCase()) : true;

        return fahrerMatches && fraechterNameMatches;
      });
      setDisplayedData(exaccData);
    }
  };


  useEffect(() => {
    applyFilter();
  }, [data]);



  const renderListComponents = (data) => {
    return data.map((item) => {
      const isFahrer = !!item.fahrer;
      const name = isFahrer ? item.fahrer.name : item.fraechter.name;
      const id = isFahrer ? item.fahrer.id : item.fraechter.id;
      const assigned = item.istZugeordnet;
      const actionText = assigned ? t("admin.pages.pages-afts-Hinzugefuegt") : t("admin.pages.pages-afts-Hinzufuegen");
      const actionColor = assigned ? "#918f8f" : "#d9d9d9";
      const action = assigned
        ? () => { deleteFahrerFromSchulung({ schulungs_id: schulungsId, fahrer_id: id }) }
        : () => { console.log("postFahrerToSchulung", { schulungs_id: schulungsId, fahrer_id: id }); postFahrerToSchulung({ schulungs_id: schulungsId, fahrer_id: id }); }

      return (
        <AMFListComponent
          key={id}
          personName={name}
          fraechtername={isFahrer ? (item.fahrer.fraechter ? item.fahrer.fraechter.name : t("admin.pages.pages-afts-KeinFraechter")) : "Ist Frächter"}
          aMFHinzufuegenBtnBackgroundColor={actionColor}
          aMFBTNText={actionText}
          aMFBTNTextColor="#000"
          onButtonClick={action}
        />
      );
    });
  };

  const FilterTypeSelector = () => (
    <div style={{ display: "flex", alignSelf: "flex-start", gap: "10px" }}>
      <label>
        <input
          type="radio"
          value="Alle"
          checked={filterType === 'Alle'}
          onChange={(e) => setFilterType(e.target.value)}
        />
        {t("admin.pages.pages-filter-all")}
      </label>
      <label>
        <input
          type="radio"
          value="Fahrer"
          checked={filterType === 'Fahrer'}
          onChange={(e) => setFilterType(e.target.value)}
        />
        {t("admin.pages.pages-filter-fahrer")}
      </label>
      <label>
        <input
          type="radio"
          value="Frächter"
          checked={filterType === 'Frächter'}
          onChange={(e) => setFilterType(e.target.value)}
        />
        {t("admin.pages.pages-filter-fraechter")}
      </label>
      <label>
        <input
          type="radio"
          value="Mitarbeiter"
          checked={filterType === 'Mitarbeiter'}
          onChange={(e) => setFilterType(e.target.value)}
        />
        {t("admin.pages.pages-filter-medlog")}
      </label>
      <label>
        <input
          type="checkbox"
          value="hinzugefuegt"
          checked={filterHinzugefuegt}
          onChange={() => setFilterHinzugefuegt(!filterHinzugefuegt)}
        />
        {t("admin.pages.pages-filter-added")}
      </label>

    </div>
  );


  // Stellen Sie sicher, dass Sie sowohl Fahrer als auch Frächter Namen extrahieren.
  const namen = data
    ? [
      ...new Set(
        data
          .map((item) => item.fahrer?.name)
          .concat(
            data.map((item) => item.fraechter?.name)
          )
          .filter((name) => name != null)
      ),
    ]
    : [];

  const fahrerNames = data ? data.map(item => item.fahrer?.name).filter(name => name != null) : [];
  const fraechterNames = data ? data.map(item => item.fraechter?.name).filter(name => name != null) : [];

  const resetFilter = () => {
    setFahrerFilter("");
    setFraechterFilter("");
    setFilterType('Alle');
    setFilterHinzugefuegt(false);
    // Stellen Sie sicher, dass das Zurücksetzen die Daten nicht verfälscht, 
    // falls sie inzwischen aktualisiert wurden.
    setDisplayedData(data);
  };

  return createPortal(
    <div className="addfahrertoschulung">
      <div className="amf-ueberschrift">
        <b className="amf-text-ueberschrift">
          {t("admin.pages.pages-afts-ueberschrift-1")} {schulungsName} {t("admin.pages.pages-afts-ueberschrift-2")}
        </b>
      </div>
      <div className="amf-innerframe">
        <div className="amf-ueberschrift">
          <b className="amf-text">{t("admin.pages.pages-afts-Liste")}</b>
        </div>
        <div className="amf-actioncontainer" >
          <div className="amf-fahrerlist" style={{ maxHeight: "50vh" }}>
            {/* <div className="amf-list-headlines">
              <div className="amf-ueberschrift-name" style={{ marginLeft: "30px" }}><div>
              </div>     {t("admin.pages.pages-afts-Fahrername")}</div>
              <div className="amf-ueberschrift-name">{t("admin.pages.pages-afts-Fraechtername")}</div>
              <div className="amf-emtpy">fasdsadasdd</div>
            </div> */}

            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
              <div className="amf-ueberschrift-name" style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                {t("admin.pages.pages-afts-Fahrername")}
              </div>
              <div className="amf-ueberschrift-name" style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                {t("admin.pages.pages-afts-Fraechtername")}
              </div>
              <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>

              </div>
            </div>

            <div style={{ display: "flex", alignItems: "stretch", flexDirection: "column", width: "90%" }}>
              {renderListComponents(displayedData)}
            </div>
          </div>
          <div className="amf-filter-container" style={{ maxHeight: "50vh" }}>
            <div className="amf-filter-headline" style={{ alignSelf: "center" }}>{t("admin.pages.pages-afts-FilterText")}</div>
            <div className="amf-filter-container1">
              <div style={{ display: "flex", flexDirection: "row", alignSelf: "stretch", marginBottom: 10 }}>
                <div className="amf-filter-inputs">
                  <AutocompleteInput
                    suggestions={namen}
                    placeholder={"Name"}
                    value={fahrerFilter}
                    onChange={e => setFahrerFilter(e.target.value)}
                  />
                  <AutocompleteInput
                    suggestions={fraechterNames}
                    placeholder={t("admin.pages.pages-afts-Fraechter") + " Name"}
                    value={fraechterFilter}
                    onChange={e => setFraechterFilter(e.target.value)}
                  />
                </div>
                <div style={{ display: "flex", alignSelf: "flex-start", flexDirection: "column", gap: 10 }}>
                  <button className="amf-filter-btn" onClick={applyFilter}>
                    <div className="amf-btn-text">{t("admin.pages.pages-afts-Filtern")}</div>
                  </button>
                  <button className="amf-filter-reset-btn" onClick={() => { resetFilter(); }}>
                    <div className="amf-btn-text1">{t("admin.pages.pages-afts-FilterZuruecksetzen")}</div>
                  </button>
                </div>
              </div>
              <FilterTypeSelector />
            </div>

          </div>
        </div>
      </div>
      <div className="amf-uebersicht-btn">
        <button onClick={() => onClose()} className="amf-uebersichtsnav-btn" id="ModulVerw_Btn">
          <div className="amf-uebersichtsnav-btn-text">
            {t("admin.pages.pages-afts-ZuruekZurUebersicht")}
          </div>
        </button>
      </div>
    </div>
    , document.getElementById("modal-root-2"));
};

export default AddFahrerToSchulung;


const AutocompleteInput = ({ suggestions, placeholder, value, onChange }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState("");

  const onInputChange = e => {
    const userInput = e.target.value;

    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setFilteredSuggestions(filteredSuggestions);
    setActiveSuggestion(0);
    setShowSuggestions(true);
    setUserInput(e.target.value);
    onChange(e); // Hinzugefügt, um die Elternkomponente zu informieren

  };

  const onSuggestionClick = suggestion => {
    setFilteredSuggestions([]);
    setActiveSuggestion(0);
    setShowSuggestions(false);
    setUserInput(suggestion);
    onChange({ target: { value: suggestion } }); // Hinzugefügt, um die Elternkomponente zu informieren
  };

  return (
    <div className="ape-input-wrapper">
      <input
        className="amf-fahrer-input"
        type="text"
        onChange={onInputChange}
        value={value}
        placeholder={placeholder}
      />
      {showSuggestions && userInput && (
        <div className="ape-suggestions-container">
          {filteredSuggestions.map((suggestion, index) => {
            return (
              <div
                key={index}
                className="ape-suggestion-item"
                onClick={() => {
                  onSuggestionClick(suggestion);
                }}
              >
                {suggestion}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};