import React, { createContext, useContext, useEffect, useState } from 'react';
import LoadingSpinner from './LoadingSpinner';

const LoadingContext = createContext();

export const useLoading = () => {
    return useContext(LoadingContext);
};

export const LoadingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [delayedLoading, setDelayedLoading] = useState(false);

    useEffect(() => {
        let timerId;
        if (isLoading) {
            timerId = setTimeout(() => setDelayedLoading(true), 800); // 800ms Verzögerung
        } else {
            setDelayedLoading(false);
        }

        return () => {
            // Timer aufräumen, wenn die Komponente unmountet wird oder isLoading sich ändert
            clearTimeout(timerId);
        };
    }, [isLoading]);

     const contextValue = {
        startLoading: () => setIsLoading(true),
        stopLoading: () => setIsLoading(false),
    };

    return (
        <LoadingContext.Provider value={contextValue}>
            {children}
            {delayedLoading && <LoadingSpinner />}
        </LoadingContext.Provider>
    );
};
