import React, { useState } from 'react';
import "./TimeSelector.css";
import { useTranslation } from "react-i18next";
const TimeSelector = ({ displayText, setDisplayText, handleSave }) => {

    const [t] = useTranslation('common');
    const [days, setDays] = useState(0);
    const [months, setMonths] = useState(0);
    const [years, setYears] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const calculateTime = () => {
        let totalMonths = months + Math.floor(days / 30) + years * 12;
        let displayYears = Math.floor(totalMonths / 12);
        let displayMonths = totalMonths % 12;
        let displayDays = days % 30; // Verbleibende Tage nach Monatsberechnung

        let yearText = displayYears === 1 ? ' Jahr ' : ' Jahre ';
        let monthText = displayMonths === 1 ? ' Monat ' : ' Monate ';
        let dayText = displayDays === 1 ? ' Tag' : ' Tage';

        let sentDisplayText = `${displayYears > 0 ? displayYears + yearText : ''}` +
            `${displayMonths > 0 ? displayMonths + monthText : ''}` +
            `${displayDays > 0 ? displayDays + dayText : ''}`;

        setDisplayText(sentDisplayText);
        handleSave(sentDisplayText);
        setIsOpen(false);
    };



    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            calculateTime();
        }
    };


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    // return (
    //     <div className="time-selector">
    //         <div className="time-selector-display" onClick={toggleDropdown}>
    //             {displayText || t("timeSel.timeSel-Zeitauswahl")}
    //         </div>
    //     </div>
    // );


    return (
        <div className="time-selector-display" style={{ widht: "100%", alignSelf: "stretch" }}>
            <div onClick={toggleDropdown}>
                {displayText || t("timeSel.timeSel-Zeitauswahl")}
            </div>
            {isOpen && (
                <div className='time-inputs' >
                    <label>
                        {t("timeSel.timeSel-Tage")}:
                        <input
                            type="number"
                            className='time-input'
                            value={days}
                            min={0}
                            onChange={(e) => setDays(parseInt(e.target.value))}
                            onKeyDown={handleKeyDown}
                        />
                    </label>
                    <label>
                        {t("timeSel.timeSel-Monate")}:
                        <input
                            type="number"
                            className='time-input'
                            value={months}
                            min={0}
                            onChange={(e) => setMonths(parseInt(e.target.value))}
                            onKeyDown={handleKeyDown}
                        />
                    </label>
                    <label>
                        {t("timeSel.timeSel-Jahre")}:
                        <input
                            type="number"
                            className='time-input'
                            value={years}
                            min={0}
                            onChange={(e) => setYears(parseInt(e.target.value))}
                            onKeyDown={handleKeyDown}
                        />
                    </label>
                    <button onClick={calculateTime}>{t("timeSel.timeSel-Aendern")}</button>
                </div>
            )}
        </div>
    )
};
export default TimeSelector;