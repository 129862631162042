import { useEffect, useMemo } from "react";
import "./FailedCourseActionsListContain.css";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../../../../components/LoadingSpinner/LoadingContext";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useGetSchulungenForFraechterByIdQuery } from "../../../../features/schulungen/carrierMaster/carrierMasterPruefugenSlice";
const FailedCourseActionsListContain = ({
  courseResultMessage,
  actionButtonText,
  teilnehmenColor,
  id
}) => {

  const [t] = useTranslation("common");
  const { data, error, isLoading, isSuccess, isError } = useGetSchulungenForFraechterByIdQuery(id)

  const { startLoading, stopLoading } = useLoading();

  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading, startLoading, stopLoading]);

  useEffect(() => {
    if (isSuccess) {
      console.log("data: ", data)
    }
  }, [isSuccess, data]);


  const infoTextStyle = useMemo(() => {
    return {
      color: teilnehmenColor,
    };
  }, [teilnehmenColor]);

  const navigate = useNavigate();

  return (
    <div className="actions-list2">
      <div className="infotext2" style={infoTextStyle}>
        {courseResultMessage}
      </div>
      <button onClick={() => {
        if (actionButtonText === "Wiederholen")
          navigate(`/carrierMaster/exams/details/${id}`)
        else {
          const link = document.createElement('a');
          link.href = data.certificate_url; // Verwenden Sie Ihren certificate_url
          link.download = data.certificate_url.split('/').pop(); // optional: Name der heruntergeladenen Datei
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);  
        }
      }} className="repeat-btn" id="ModulVerw_Btn">
        <div className="teilnehmen7">{actionButtonText}</div>
      </button>
      <button onClick={() => { navigate("/carrierMaster") }} className="othermodules-btn1" id="ModulVerw_Btn">
        <div className="teilnehmen8">{t("carrier.pruefung.fcalc-AndereModule")}</div>
      </button>
    </div>
  );
};

export default FailedCourseActionsListContain;
