import { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { useTranslation } from "react-i18next";
import PhishingStatusTable from "./PhishingStatusTable";
import PhishingAlgorithmUebersicht from "./PhishingAlgorithmUebersicht";
const PhishingUebersicht = () => {

    const [activeTab, setActiveTab] = useState('tab1'); // initialer aktiver Tab
    const [t] = useTranslation('common');



    return (
        <div className="akvw-kursverwaltung">
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                height: 'calc(100% - 149px + 122px + 13px)',
                width: 'calc(100% - 255px)',
                top: '7px',
                right: '12px',
                bottom: '7px',
                left: '243px',
                backgroundColor: '#fff',
                overflowY: 'auto',
                padding: '20px 50px 10px 50px',
                boxSizing: 'border-box'
            }}>
                <div className="akvw-tabs">
                    <button
                        className={`akvw-tab ${activeTab === 'tab1' ? 'active' : ''}`}
                        onClick={() => setActiveTab('tab1')}
                    >
                        {t("admin.pages.pages-akd-pishing-uebersicht")}
                    </button>
                    <button
                        className={`akvw-tab ${activeTab === 'tab2' ? 'active' : ''}`}
                        onClick={() => setActiveTab('tab2')}
                    >
                        {t("admin.pages.pages-akd-pishing-algorithmus")}
                    </button>
                    <div className="tab-highlight"></div> {/* Hervorhebung für den aktiven Tab */}
                </div>

                <div className="akvw-tab-content">
                    {activeTab === 'tab1' && (
                        <>
                            <PhishingStatusTable />
                        </>
                    )}
                    {activeTab === 'tab2' &&
                        <>
                            <PhishingAlgorithmUebersicht />
                        </>}
                </div>
            </div>
            <Sidebar />
        </div >
    );
}

export default PhishingUebersicht;