import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useGetPruefungsErgebnisseFraechterQuery } from "../../features/schulungen/carrierMaster/carrierMasterSchulungenSlice";
import "./FraechterFahrerverwaltung.css";
import { useLoading } from "../../components/LoadingSpinner/LoadingContext";
import ErgebnisListItem from "../../components/admin/fahrerverwaltung/ErgebnisListItem";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const FraechterFahrerverwaltung = () => {
    const { data, isLoading, isSuccess } = useGetPruefungsErgebnisseFraechterQuery();

    const {t} = useTranslation('common');
    
    const navigate = useNavigate();
    
    const [suggestedResults, setSuggestedResults] = useState([]);

    const [displayedData, setDisplayedData] = useState([])
    const [searchInput, setSearchInput] = useState("")

    const { startLoading, stopLoading } = useLoading();

    const updateSuggestedResults = (searchInput) => {
        if (searchInput === "") {
            setSuggestedResults([]);
        } else {
            const suggestions = data?.filter((data) =>
                data.fahrer.name.toLowerCase().includes(searchInput.toLowerCase())
            );
            setSuggestedResults(suggestions);
        }
    }

    useEffect(() => {
        if (isLoading) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoading, startLoading, stopLoading]);

    useEffect(() => {
        if (isSuccess) {
            console.log("ergebnisse: ", data)
        }
    }, [isSuccess, data]);

    //Search in data for searchInput in data.fahrer.name
    //setDisplayedData to filtered data
    //if searchInput is empty, setDisplayedData to data

    const setNeawDisplayedData = (searchInput) => {
        if (searchInput === "") {
            setDisplayedData([])
        } else {
            const filteredData = data?.filter((data) => {
                // Verwenden von toLowerCase() für beide Strings
                return data.fahrer.name.toLowerCase() === (searchInput.toLowerCase())
            })
            console.log("filteredData: ", filteredData) //filteredData is undefined
            setDisplayedData(filteredData)
        }
    }

    return (
        <div className="afvw-fahrerverwaltung">
            <Sidebar />
            <div className="afvw-inner-window">
                <div className="afvw-carrier-input-container">
                    <div className="afvw-input-wrapper">
                        <input
                            className="afvw-carrier-input"
                            placeholder={t("carrierMaster.fr-ffv-PlaceholderFahrername")}
                            value={searchInput}
                            type="text"
                            onChange={(e) => {
                                setSearchInput(e.target.value);
                                updateSuggestedResults(e.target.value);
                            }}
                        />
                        {searchInput && suggestedResults.length > 0 && (
                            <div className="afvw-suggestions-container">
                                {suggestedResults.map((result, index) => (
                                    <div
                                        key={index}
                                        className="afvw-suggestion-item"
                                        onClick={() => {
                                            setSearchInput(result.fahrer.name);
                                            setSuggestedResults([]);
                                            setNeawDisplayedData(result.fahrer.name);
                                        }}
                                    >
                                        {result.fahrer.name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <button onClick={() => setNeawDisplayedData(searchInput)} className="afvw-suchen-btn" id="Suchen-Btn">
                        <div className="afvw-suchen-btn-text">{t("carrierMaster.fr-ffv-Suchen")}</div>
                    </button>
                </div>

                {
                    displayedData.length > 0 ? (
                        <>
                            <div className="afvw-list-item-header">
                                <div className="afvw-list-item-header-item-begin">{t("carrierMaster.fr-ffv-Status")}</div>
                                <div className="afvw-list-item-header-item">{t("carrierMaster.fr-ffv-Thema")}</div>
                                <div className="afvw-list-item-header-item">{t("carrierMaster.fr-ffv-Versuche")}</div>
                                <div className="afvw-list-item-header-item-end">{t("carrierMaster.fr-ffv-Aktionen")}</div>
                            </div>

                            {displayedData.map((data, index) => (
                                <ErgebnisListItem
                                    thema={data.schulung.name}
                                    key={index}
                                    afvwListItemVersucheText={data.durchgefuehrtePruefungen.length}
                                    afvwListItemErgebnisseBtnBackgroundColor={data.bestanden ? "#00ff00" : "#b3b3b3"}
                                    bestanden={data.bestanden}
                                    afvwListItemFertigBtnBackgroundColor={data.bestanden ? "#00ff00" : "#b3b3b3"}
                                    onButtonClick={() => { navigate(`/carrierMaster/details/results/${data.schulung.id}`) }}
                                />
                            ))}
                        </>
                    ) : (
                        <div>{t("carrierMaster.fr-ffv-KeineSchulungenGefunden")}</div>
                    )
                }

            </div>
        </div>
    );
};

export default FraechterFahrerverwaltung;
