import { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import ModuleComponent from "../../../components/admin/Kursverwaltung/ModuleComponent";
import { useSelector } from "react-redux";
import "./AdminKursverwaltung.css";
import { useGetSchulungenQuery, selectSchulungenIds, selectAllSchulungen } from "../../../features/schulungen/admin/adminSchulungenSlice";
import { useLoading } from "../../../components/LoadingSpinner/LoadingContext";
import AddSchulung from "../schulungenHinzufuegen/AddSchulung";
import { useTranslation } from "react-i18next";
const AdminKursverwaltung = () => {

    const [showPopup, setShowPopup] = useState(false);

    const { error, isLoading, isSuccess, isError } = useGetSchulungenQuery()

    const [activeTab, setActiveTab] = useState('tab1'); // initialer aktiver Tab
    const [t] = useTranslation('common');

    const schulungsIDs = useSelector(selectSchulungenIds);

    const schulungen = useSelector(selectAllSchulungen);

    const { startLoading, stopLoading } = useLoading();

    useEffect(() => {
        if (isLoading) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoading, startLoading, stopLoading]);


    let content;
    let archivContent; // Inhalt für Archiv-Tab
    if (isSuccess) {
        content = schulungen.filter(schulung => !schulung.geloescht).map(schulung => (
            <ModuleComponent key={schulung.id} id={schulung.id} />
        ));
        archivContent = schulungen
            .filter(schulung => schulung.geloescht) // behält nur gelöschte Schulungen bei
            .map(schulung => (
                <ModuleComponent isDeleted={true} key={schulung.id} id={schulung.id} />
            ));
    } else if (isError) {
        content = <p>{error}</p>;
        archivContent = <p>{error}</p>;
    }



    return (
        <div className="akvw-kursverwaltung">
            <Sidebar />
            <Header />

            <div className="akvw-inner-window">
                <div className="akvw-tabs">
                    <button
                        className={`akvw-tab ${activeTab === 'tab1' ? 'active' : ''}`}
                        onClick={() => setActiveTab('tab1')}
                    >
                        {t("admin.pages.pages-akd-aktuelleSchulung")}
                    </button>
                    <button
                        className={`akvw-tab ${activeTab === 'tab2' ? 'active' : ''}`}
                        onClick={() => setActiveTab('tab2')}
                    >
                        {t("admin.pages.pages-akd-Archiv")}
                    </button>
                    <div className="tab-highlight"></div> {/* Hervorhebung für den aktiven Tab */}
                </div>

                <div className="akvw-tab-content">
                    {activeTab === 'tab1' && (
                        <>
                            <button onClick={() => setShowPopup(true)} className="akvw-fragen-btn">
                                <div className="akvw-btn-text">{t("admin.pages.pages-akv-SchulungHinzufuegen")}</div>
                            </button>
                            {showPopup}
                            {content}
                        </>
                    )}
                    {activeTab === 'tab2' && archivContent}
                </div>
            </div>
            {showPopup && <AddSchulung onClose={() => { setShowPopup(false); }} />}
        </div>
    );
};

export default AdminKursverwaltung;
