import { useLocation, useNavigate } from 'react-router-dom';
import NavigationItem from "./NavigationItem";
import "./Sidebar.css";
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logOut, selectCurrentUser } from "../../features/authSlice";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { use } from 'i18next';
import { useWhoamiQuery } from '../../features/authApiSlice';
const Sidebar = ({ navigationNotAllowed = false }) => {
    const location = useLocation();
    const basePath = location.pathname.split('/')[1]; // Extrahiert den Basispfad
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data: user, isLoading, isSuccess } = useWhoamiQuery();
    const [t] = useTranslation('common');


    useEffect(() => {
        if (isSuccess) {
            console.log("CURRENT USER: ", user)
        }
    }, [isSuccess])

    useEffect(() => {
        console.log("Navigation not allowed: ", navigationNotAllowed)
    }, [navigationNotAllowed])

    let navigationItems;
    switch (basePath) {
        case 'admin':
            if (user && user.authorities.includes("SUPER_ADMIN")) {
                navigationItems = [
                    { text: t("sidebar.sidebar-admin-Schulungen"), link: '/admin' },
                    { text: t("sidebar.sidebar-admin-Fahrersuche"), link: '/admin/carrier' },
                    { text: "Phishing", link: '/admin/phishing' },
                    { text: t("admin.rollenverwaltung.rollenverwaltung"), link: '/admin/roles' },
                ];

            } else {
                navigationItems = [
                    { text: t("sidebar.sidebar-admin-Schulungen"), link: '/admin' },
                    { text: t("sidebar.sidebar-admin-Fahrersuche"), link: '/admin/carrier' },
                ];
            }
            break;
        case 'carrier':
            navigationItems = [
                { text: t("sidebar.sidebar-carrier-Schulungen"), link: '/carrier' },
            ];
            break;
        case 'carrierMaster':
            navigationItems = [
                { text: t("sidebar.sidebar-carrierMaster-Schulungen"), link: '/carrierMaster' },
                { text: t("sidebar.sidebar-carrierMaster-Fahrersuche"), link: '/carrierMaster/search' },
            ];
            break;
        default:
            navigationItems = [
            ];
    }
    return (
        <nav className="sidebar">
            <div className="navigation-links">

                {navigationItems.map(item =>
                    <NavigationItem
                        key={item.text}
                        navigationAllowed={!navigationNotAllowed}
                        navItemText={item.text}
                        navLink={item.link}
                        isActive={location.pathname === item.link}
                    />
                )}
            </div>
            <div className="settings-logout-section">
                <div className="split-stroke" />
                <button onClick={() => { if (!navigationNotAllowed) navigate("/" + basePath + "/settings") }} className="settings-btn">
                    <img className="settings-icon" alt="" src="/settingsicon.svg" />
                    <div className="text1">{t("sidebar.sidebar-Einstellung")}</div>
                </button>
                <button onClick={() => { if (!navigationNotAllowed) { dispatch(logOut()); navigate("/"); } }} className="logout-btn">
                    <img className="logout-icon" alt="" src="/logouticon.svg" />
                    <div className="text1">{t("sidebar.sidebar-Logout")}</div>
                </button>
            </div>
            <a href="https://www.medlog.at/impressum/" style={{ position: "absolute", bottom: 20, left: 20, fontSize: 15, color: "white" }}>Impressum</a>
            <a href="https://www.medlog.at/datenschutz/" style={{ position: "absolute", bottom: 20, right: 20, fontSize: 15, color: "white" }}>Datenschutz</a>

        </nav>
    );
};

export default React.memo(Sidebar);
