import React, { useState, useEffect } from 'react';
import { createPortal } from "react-dom";
import Editor from './Editor'; // Ändern Sie den Pfad zu Ihrem Editor
import "./AddInfoSection.css";
import { useAddInfoBereichMutation, useUpdateInfoBereichMutation } from '../../../features/schulungen/admin/adminInfoSectionsSlice';
import { useTranslation } from "react-i18next";
const AddInfoSection = ({ onClose, id, initalInfoBereich, isEditing = false }) => {

    const [editorValue, setEditorValue] = useState(isEditing ? initalInfoBereich.inhalt : '');
    const [title, setTitle] = useState(isEditing ? initalInfoBereich.title : '');
    const [t] = useTranslation('common');
    const [addInfoBereich] = useAddInfoBereichMutation();
    const [editInfoBereich] = useUpdateInfoBereichMutation();
    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            onClose();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line 
    }, []);

    const handleSave = () => {
        if (!title || !editorValue) {
            alert('Bitte füllen Sie alle Felder aus!');
            return;
        }
        if (isEditing) {
            editInfoBereich({ schulungID: id, informationsbereichID: initalInfoBereich.id, informationsbereich: { title, inhalt: editorValue } });
        } else {
            addInfoBereich({ schulungID: id, informationsbereich: { title, inhalt: editorValue } });
        }
        onClose();
    };

    return createPortal(
        <div className="admin-modal-informationsbereic">
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder={t("admin.akd.akd-ais-PlaceholderTitel")} className="ami-input-frage" />

            <div className="ami-mid-section">
                <div className="ami-inner-section">
                    <Editor value={editorValue} onChange={setEditorValue} />
                </div>
            </div>

            <div className="ami-buttons">
                <button className="ami-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={() => onClose()}>
                    <div className="ami-uebersichtsnav-btn-text">{t("admin.akd.akd-ais-Zuruek")}</div>
                </button>
                <button className="ami-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={handleSave}>
                    <div className="ami-uebersichtsnav-btn-text">{t("admin.akd.akd-ais-Speichern")}</div>
                </button>
            </div>
        </div>,
        document.getElementById('modal-root')
    );
};

export default AddInfoSection;
