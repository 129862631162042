import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";

const fahrerSchulungenAdapter = createEntityAdapter()

const initialState = fahrerSchulungenAdapter.getInitialState()

export const fahrerSchulungenSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSchulungenForFahrer: builder.query({
            query: () => ({
                url: '/carrier/schulungen',
                method: 'GET'
            }),
            transformResponse: responseData => {
                console.log("responseData: ", responseData)
                return fahrerSchulungenAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => [
                { type: 'FahrerSchulung', id: "LIST" },
                ...result?.ids.map(id => ({ type: 'FahrerSchulung', id }))
            ]
        }),
        getSchulungenForFahrerById: builder.query({
            query: (id) => ({
                url: `/carrier/schulungen/${id}`,
                method: 'GET'
            }),
            providesTags: (result, error, id) => [
                { type: 'FahrerSchulung', id },
            ]
        }),
        watchInfoSection: builder.mutation({
            query: ({ schulungs_id, bereich_id }) => ({
                url: `/carrier/schulungen/${schulungs_id}/info/${bereich_id}`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, { schulungs_id }) => [{ type: 'FahrerSchulung', id: schulungs_id }],
        }),
        postPruefung: builder.mutation({
            query: ({ schulungs_id, invalidate_id, ...data }) => ({
                url: `/carrier/schulungen/${schulungs_id}/pruefung`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (result, error, { invalidate_id }) => [
                { type: 'FahrerSchulung', id: 'LIST' }, // Dies invalidiert die Liste der Schulungen für den Fahrer
                { type: 'FahrerSchulung', id: invalidate_id }, // Dies invalidiert die spezifische Schulung, falls benötigt
            ],
        }),
    })
})

export const {
    useGetSchulungenForFahrerQuery,
    useGetSchulungenForFahrerByIdQuery,
    useWatchInfoSectionMutation,
    usePostPruefungMutation
} = fahrerSchulungenSlice


export const selectGetSchulungenResult = fahrerSchulungenSlice.endpoints.getSchulungenForFahrer.select();

// Selektor zum Abruf der Daten aus dem 'getSchulungen' Ergebnis
export const selectSchulungenData = createSelector(
    // Verwende den oben definierten Selektor als Eingabe
    selectGetSchulungenResult,
    // Die zurückgegebene Datenstruktur ist in `data` enthalten
    getSchulungenResult => getSchulungenResult.data
);

export const {
    selectAll: selectAllSchulungen,
    selectById: selectSchulungById,
    selectIds: selectSchulungenIds
    // Pass in a selector that returns the posts slice of state
} = fahrerSchulungenAdapter.getSelectors(state => selectSchulungenData(state) ?? initialState)