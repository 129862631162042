import { createPortal } from "react-dom"
import Editor from "../../../components/admin/kursdetails/Editor";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useCreateEmailTemplateMutation, useLazyGenerateEmailTemplateQuery, useUpdateEmailTemplateMutation } from "../../../features/phishing/adminPhishingSlice";

const PhishingAddMailTemplateModal = ({ open, onClose, initialTemplate, isEditing = false }) => {

    const [t] = useTranslation('common');

    const [mailBody, setMailBody] = useState(initialTemplate ? initialTemplate.body : "");
    const [mailSubject, setMailSubject] = useState(initialTemplate ? initialTemplate.subject : "");
    const [mailTitle, setMailTitle] = useState(initialTemplate ? initialTemplate.title : "");
    const [addNewMail] = useCreateEmailTemplateMutation();
    const [updateMail] = useUpdateEmailTemplateMutation();

    const [isEmailTemplateLoading, setIsEmailTemplateLoading] = useState(false);

    const [fetchEmailTemplate] = useLazyGenerateEmailTemplateQuery();

    const handleAddMail = () => {
        if (isEditing) {
            updateMail({ id: initialTemplate.id, emailTemplate: { title: mailTitle, subject: mailSubject, body: mailBody } });
        } else {
            addNewMail({ title: mailTitle, subject: mailSubject, body: mailBody });
        }
        onClose();
    }


    const handleGenerateMailTemplate = () => {
        setIsEmailTemplateLoading(true);
        fetchEmailTemplate().unwrap().then(template => {
            if (template) {
                setMailBody(template.mailbody);
                setMailSubject(template.subject);
            }
            setIsEmailTemplateLoading(false);
        }).catch(error => {
            // Handle any errors here
            setIsEmailTemplateLoading(false);
            console.error('Failed to generate email template:', error);
        });
    };

    if (!open) return null;

    // return createPortal(
    //     <div className="admin-modal-informationsbereic">
    //         <input type="text"
    //             // value={ } 
    //             // onChange={(e) => setTitle(e.target.value)} 
    //             // placeholder={t("admin.akd.akd-ais-PlaceholderTitel")} 
    //             className="ami-input-frage" />

    //         <div className="ami-mid-section">
    //             <div className="ami-inner-section">
    //                 <Editor />
    //             </div>
    //         </div>

    //         <div className="ami-buttons">
    //             <button className="ami-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={() => onClose()}>
    //                 <div className="ami-uebersichtsnav-btn-text">{t("admin.akd.akd-ais-Zuruek")}</div>
    //             </button>
    //             <button className="ami-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={() => console.log("test")}>
    //                 <div className="ami-uebersichtsnav-btn-text">{t("admin.akd.akd-ais-Speichern")}</div>
    //             </button>
    //         </div>
    //     </div >,
    //     document.getElementById('modal-root')
    // );

    return createPortal(
        <div className="addfahrertoschulung" style={{ display: "flex", flexDirection: "column", padding: 50 }}>
            <div style={{ flex: 1, display: "flex", flexDirection: "column", width: "100%", gap: 10, alignSelf: "center" }}>
                {/* <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", alignItems: "center", height: 40, padding: 0, margin: 0 }}>
                    <div style={{ position: "absolute", marginLeft: 10, marginRight: 20, fontFamily: "Verdana", fontWeight: "bold" }}>Betreff:</div>
                    <input
                        className="ape-fahrer-input"
                        style={{ flex: 1, paddingLeft: "80px", alignSelf: "center", width: "100%", boxSizing: "border-box" }}
                        type="text"
                        onChange={(e) => setMailTitle(e.target.value)}
                        value={mailTitle}
                        placeholder={"Titel der Vorlage:"}
                    />
                </div> */}
                <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", justifyContent: "center", position: "relative" }}>
                    <div style={{ position: "absolute", marginLeft: 10, marginRight: 20, fontFamily: "Verdana", fontWeight: "bold" }}>Titel der Vorlage:</div>
                    <input
                        className="ape-fahrer-input"
                        style={{
                            flex: 1, paddingLeft: 150
                        }}

                        onChange={e => setMailTitle(e.target.value)}
                        value={mailTitle}
                    >
                    </input>
                </div>

                <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", justifyContent: "center", position: "relative" }}>
                    <div style={{ position: "absolute", marginLeft: 10, marginRight: 20, fontFamily: "Verdana", fontWeight: "bold" }}>Betreff:</div>
                    <input
                        className="ape-fahrer-input"
                        style={{
                            flex: 1, paddingLeft: 80
                        }}

                        onChange={e => setMailSubject(e.target.value)}
                        value={mailSubject}
                    >
                    </input>
                </div>
            </div>
            <div style={{ flex: 5, display: "flex", flexDirection: "column", width: "100%", gap: 10, backgroundColor: "white" }}>
                <Editor value={mailBody} onChange={setMailBody} hasPhishing={true} />
            </div>
            <div className="ami-buttons">
                <button className="ami-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={() => onClose()}>
                    <div className="ami-uebersichtsnav-btn-text">{t("admin.akd.akd-ais-Zuruek")}</div>
                </button>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 30, fontSize: 20 }}>
                    <button className="ami-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={handleGenerateMailTemplate} disabled={isEmailTemplateLoading}>
                        <div className="ami-uebersichtsnav-btn-text">Generiere Phishing Mail</div>
                    </button>
                    {isEmailTemplateLoading && <div>Laden...</div>}
                </div>

                <button className="ami-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={handleAddMail}>
                    <div className="ami-uebersichtsnav-btn-text">{t("admin.akd.akd-ais-Speichern")}</div>
                </button>
            </div>
        </div>,
        document.getElementById('modal-root-2') // This is the target container
    );


}

export default PhishingAddMailTemplateModal;