import { useTranslation } from "react-i18next";
import Sidebar from "../../../components/Sidebar/Sidebar";
import "../../carrier/pruefung/FahrerPruefungsstart.css";
import { useNavigate, useParams } from "react-router-dom";
const FraechterPruefungsstart = () => {

    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const { id } = useParams();

    return (
        <div className="fpu-fahrer-pruefungsstart">
            <div className="fpu-inner-window5">
                <div className="fpu-actions-list-1">
                    <button onClick={() => { navigate(`/carrierMaster/exams/exam/${id}/start`) }} className="fpu-starten-btn" id="ModulVerw_Btn">
                        <div className="fpu-teilnehmen4">{t("carrier.pruefung.fps-PruefungStarten")}</div>
                    </button>
                    <div className="fpu-infotext">{t("carrier.pruefung.fps-InfoPruefungStarten")}</div>
                    <button onClick={() => { navigate("/carrierMaster/exams/details/" + id) }} className="fpu-uebersicht-btn" id="ModulVerw_Btn">
                        <div className="fpu-teilnehmen4">{t("carrier.pruefung.fps-ZuruekZurUebersicht")}</div>
                    </button>
                </div>
            </div>
            <Sidebar />
        </div>
    );
};

export default FraechterPruefungsstart;
