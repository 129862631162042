import { apiSlice } from "../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: request => ({
                url: '/oauth2/access_token',
                method: 'POST',
                body: { ...request }
            })
        }),
        whoami: builder.query({
            query: () => ({
                url: '/oauth2/whoami',
                method: 'GET'
            })
        }),
        addName: builder.mutation({
            query: ({ vorname, nachname }) => ({
                url: `/oauth2/add-name?vorname=${vorname}&nachname=${nachname}`,
                method: 'POST',
            }),
        }),
    })
})

export const {
    useLoginMutation,
    useLazyWhoamiQuery,
    useWhoamiQuery,
    useAddNameMutation
} = authApiSlice