import "./FahrerKursbersicht.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useGetSchulungenForFahrerQuery, selectAllSchulungen } from "../../../features/schulungen/fahrer/fahrerSchulungenSlice";
import { useLoading } from "../../../components/LoadingSpinner/LoadingContext";
import { useSelector } from "react-redux";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import SchulungsmodulCard from "./SchulungsmodulCard";
import { useTranslation } from "react-i18next";
const FahrerKursbersicht = () => {
  const navigate = useNavigate();

  const { startLoading, stopLoading } = useLoading();
  const { data, error, isLoading, isSuccess, isError } = useGetSchulungenForFahrerQuery()
  const zugeordneteSchulungen = useSelector(selectAllSchulungen);
  const [t] = useTranslation('common');
  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
      console.log(data);
    }
  }, [startLoading, stopLoading]);

  let content;
  if (isSuccess) {
    content = zugeordneteSchulungen.map(zugeordneteSchulung => (
      zugeordneteSchulung.bestanden ? <>
        <SchulungsmodulCard title={zugeordneteSchulung.schulung.name} certificate_url={zugeordneteSchulung.certificate_url} id={zugeordneteSchulung.schulung.id} />
      </> : (

        zugeordneteSchulung.schulung.vorhergehendeSchulung !== null ? (() => {
          const vorgehendeSchulungId = zugeordneteSchulung.schulung.vorhergehendeSchulung.id;
          const vorgehendeSchulung = zugeordneteSchulungen.find(schulung =>
            schulung.schulung.id === vorgehendeSchulungId
          );

          if (vorgehendeSchulung && vorgehendeSchulung.bestanden) {
            return (
              <div key={zugeordneteSchulung.id} className="fku-modul-list-comp3">
                <b className="fku-modulname2">{zugeordneteSchulung.schulung.name}</b>
                <button onClick={() => navigate(`/carrier/details/${zugeordneteSchulung.schulung.id}`)}
                  className="fku-teilnehmen-btn3" id="ModulVerw_Btn">
                  <div className="fku-teilnehmen6">{t("carrier.fkue-Teilnehmen")}</div>
                </button>
              </div>
            );
          }
          return null;
        })() : (
          <div key={zugeordneteSchulung.id} className="fku-modul-list-comp3">
            <b className="fku-modulname2">{zugeordneteSchulung.schulung.name}</b>
            <button onClick={() => navigate(`/carrier/details/${zugeordneteSchulung.schulung.id}`)}
              className="fku-teilnehmen-btn3" id="ModulVerw_Btn">
              <div className="fku-teilnehmen6">{t("carrier.fkue-Teilnehmen")}</div>
            </button>
          </div>
        )
      )
    ))
  } else if (isError) {
    content = <p>{error}</p>;
  }

  return (
    <div className="fku-fahrer-kursbersicht">
      <div className="fku-inner-window7">
        {content}
      </div>
      <Header />
      <Sidebar
      />
    </div >
  );
};

export default FahrerKursbersicht;
