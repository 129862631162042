import "./FR-Kursverwaltung.css";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useGetSchulungenForCarrierMasterQuery, selectAllSchulungen } from "../../features/schulungen/carrierMaster/carrierMasterSchulungenSlice";
import { useGetSchulungenForFraechterQuery, selectAllFraechterSchulungen } from "../../features/schulungen/carrierMaster/carrierMasterPruefugenSlice";
import { useEffect, useState } from "react";
import { useLoading } from "../../components/LoadingSpinner/LoadingContext";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SchulungsmodulCard from "../carrier/kursuebersicht/SchulungsmodulCard";
const FraechterKursverwaltung = () => {
    const navigate = useNavigate();

    const [t] = useTranslation('common');
    const { data, isLoading, isSuccess, isError, error } = useGetSchulungenForCarrierMasterQuery();
    const schulungen = useSelector(selectAllSchulungen);
    const [activeTab, setActiveTab] = useState('tab1'); // initialer aktiver Tab
    const { data1, error1, isLoading1, isSuccess1, isError1 } = useGetSchulungenForFraechterQuery()
    const zugeordneteSchulungen = useSelector(selectAllFraechterSchulungen);
  
    const { startLoading, stopLoading } = useLoading();

    useEffect(() => {
        if (isLoading) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoading, startLoading, stopLoading]);


    let content;
    let archivContent; // Inhalt für Archiv-Tab
    if (isSuccess) {
        archivContent =
            <>
                {schulungen?.map((schulung, index) => (
                    <div key={index} className="frku-modul-list-comp3">
                        <b className="frku-modulname2">{schulung.name}</b>
                        <button onClick={() => { navigate(`/carrierMaster/details/${schulung.id}`) }} className="frku-teilnehmen-btn3" id="ModulVerw_Btn">
                            <div className="frku-teilnehmen6">{t("carrierMaster.fr-kv-ModulAnsehen")}</div>
                        </button>
                    </div>
                ))
                }
            </>

        content =     content = zugeordneteSchulungen.map(zugeordneteSchulung => (
            zugeordneteSchulung.bestanden ? <>
              <SchulungsmodulCard title={zugeordneteSchulung.schulung.name} certificate_url={zugeordneteSchulung.certificate_url} />
            </> : (
      
              zugeordneteSchulung.schulung.vorhergehendeSchulung !== null ? (() => {
                const vorgehendeSchulungId = zugeordneteSchulung.schulung.vorhergehendeSchulung.id;
                const vorgehendeSchulung = zugeordneteSchulungen.find(schulung =>
                  schulung.schulung.id === vorgehendeSchulungId
                );
      
                if (vorgehendeSchulung && vorgehendeSchulung.bestanden) {
                  return (
                    <div key={zugeordneteSchulung.id} className="fku-modul-list-comp3">
                      <b className="fku-modulname2">{zugeordneteSchulung.schulung.name}</b>
                      <button onClick={() => navigate(`/carrier/details/${zugeordneteSchulung.schulung.id}`)}
                        className="fku-teilnehmen-btn3" id="ModulVerw_Btn">
                        <div className="fku-teilnehmen6">{t("carrier.fkue-Teilnehmen")}</div>
                      </button>
                    </div>
                  );
                }
                // Nichts zurückgeben, wenn die vorherige Schulung nicht bestanden wurde
                return null;
              })() : (
                <div key={zugeordneteSchulung.id} className="fku-modul-list-comp3">
                  <b className="fku-modulname2">{zugeordneteSchulung.schulung.name}</b>
                  <button onClick={() => navigate(`/carrierMaster/exams/details/${zugeordneteSchulung.schulung.id}`)}
                    className="fku-teilnehmen-btn3" id="ModulVerw_Btn">
                    <div className="fku-teilnehmen6">{t("carrier.fkue-Teilnehmen")}</div>
                  </button>
                </div>
              )
            )
          ))
      
    } else if (isError) {
        content = <p>{error}</p>;
        archivContent = <p>{error}</p>;
    }



    return (
        <div className="frku-fahrer-kursbersicht">
            <div className="frku-inner-window7">

                <div className="akvw-tabs">
                    <button
                        className={`akvw-tab ${activeTab === 'tab1' ? 'active' : ''}`}
                        onClick={() => setActiveTab('tab1')}
                    >
                        {t("carrierMaster.fr-kd-tab1")}
                    </button>
                    <button
                        className={`akvw-tab ${activeTab === 'tab2' ? 'active' : ''}`}
                        onClick={() => setActiveTab('tab2')}
                    >
                        {t("carrierMaster.fr-kd-tab2")}
                    </button>
                    <div className="tab-highlight"></div> {/* Hervorhebung für den aktiven Tab */}
                </div>

                <div className="akvw-tab-content">
                    {activeTab === 'tab1' && (
                        <>
                            {content}
                        </>
                    )}
                    {activeTab === 'tab2' && archivContent}
                </div>
            </div>

            <Header />
            <Sidebar />
        </div>
    );

    return (
        <div className="frku-fahrer-kursbersicht">
            <div className="frku-inner-window7">
            </div>
            <Header />
            <Sidebar />
        </div >
    );
};

export default FraechterKursverwaltung;
