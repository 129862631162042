import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";

const fraechterSchulungenAdapter = createEntityAdapter()

const initialState = fraechterSchulungenAdapter.getInitialState()

export const fraechterSchulungenSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSchulungenForFraechter: builder.query({
            query: () => ({
                url: '/carrierMaster/schulungen',
                method: 'GET'
            }),
            transformResponse: responseData => {
                console.log("responseData: ", responseData)
                return fraechterSchulungenAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => [
                { type: 'FraechterSchulung', id: "LIST" },
                ...result?.ids.map(id => ({ type: 'FraechterSchulung', id }))
            ]
        }),
        getSchulungenForFraechterById: builder.query({
            query: (id) => ({
                url: `/carrierMaster/schulungen/${id}`,
                method: 'GET'
            }),
            providesTags: (result, error, id) => [
                { type: 'FraechterSchulung', id },
            ]
        }),
        watchInfoSectionFraechter: builder.mutation({
            query: ({ schulungs_id, bereich_id }) => ({
                url: `/carrierMaster/schulungen/${schulungs_id}/info/${bereich_id}`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, { schulungs_id }) => [{ type: 'FraechterSchulung', id: schulungs_id }],
        }),
        postPruefungFraechter: builder.mutation({
            query: ({ schulungs_id, invalidate_id, ...data }) => ({
                url: `/carrierMaster/schulungen/${schulungs_id}/pruefung`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (result, error, { invalidate_id }) => [
                { type: 'FraechterSchulung', id: 'LIST' }, // Dies invalidiert die Liste der Schulungen für den Fahrer
                { type: 'FraechterSchulung', id: invalidate_id }, // Dies invalidiert die spezifische Schulung, falls benötigt
            ],
        }),
    })
})

export const {
    useGetSchulungenForFraechterQuery,
    useGetSchulungenForFraechterByIdQuery,
    useWatchInfoSectionFraechterMutation,
    usePostPruefungFraechterMutation
} = fraechterSchulungenSlice


export const selectGetSchulungenResult = fraechterSchulungenSlice.endpoints.getSchulungenForFraechter.select();

// Selektor zum Abruf der Daten aus dem 'getSchulungen' Ergebnis
export const selectSchulungenData = createSelector(
    // Verwende den oben definierten Selektor als Eingabe
    selectGetSchulungenResult,
    // Die zurückgegebene Datenstruktur ist in `data` enthalten
    getSchulungenResult => getSchulungenResult.data
);

export const {
    selectAll: selectAllFraechterSchulungen,
    selectById: selectSchulungById,
    selectIds: selectSchulungenIds
    // Pass in a selector that returns the posts slice of state
} = fraechterSchulungenAdapter.getSelectors(state => selectSchulungenData(state) ?? initialState)