// Import the API slice foundation, presumably where you configure the base query etc.
import { apiSlice } from "../../app/api/apiSlice";

export const phishingApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // Endpoint to get phishing settings
    getPhishingSettings: builder.query({
      query: () => '/phishing',
      providesTags: ['PhishingSettings']
    }),
    getPhishingAttempts: builder.query({
      query: () => '/phishing/attempts',
      providesTags: ['PhishingAttempts']
    }),

    // Endpoint to update phishing settings
    updatePhishingSettings: builder.mutation({
      query: (settingsRequest) => ({
        url: '/phishing',
        method: 'PUT',
        body: settingsRequest
      }),
      invalidatesTags: ['PhishingSettings']
    }),
    // Endpoint to create an email template
    createEmailTemplate: builder.mutation({
      query: (emailTemplate) => ({
        url: '/phishing/email',
        method: 'POST',
        body: emailTemplate
      }),
      invalidatesTags: ['PhishingSettings']
    }),

    // Endpoint to update an email template
    updateEmailTemplate: builder.mutation({
      query: ({ id, emailTemplate }) => ({
        url: `/phishing/email/${id}`,
        method: 'PUT',
        body: emailTemplate
      }),
      invalidatesTags: ['PhishingSettings']
    }),
    deleteEmailTemplate: builder.mutation({
      query: (id) => ({
        url: `/phishing/email/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PhishingSettings']
    }),
    generateEmailTemplate: builder.query({
      query: () => '/openai',
      providesTags: ['EmailTemplates']
    }),

    sendPhishingRequest: builder.mutation({
      query: (request) => ({
        url: '/phishing/send',
        method: 'POST',
        body: request
      }),
      invalidatesTags: ['PhishingAttempts']
    })
  }),
});

// Export hooks for each endpoint
export const {
  useGetPhishingSettingsQuery,
  useUpdatePhishingSettingsMutation,
  useGetPhishingAttemptsQuery,
  useCreateEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
  useDeleteEmailTemplateMutation,
  useLazyGenerateEmailTemplateQuery,
  useSendPhishingRequestMutation
} = phishingApi;
