import { useNavigate } from "react-router-dom";
import "./NavigationItem.css";

const NavigationItem = ({ navItemText, isActive, navLink, navigationAllowed = true }) => {
    const navigate = useNavigate();

    return (
        <button onClick={() => { if (navigationAllowed) navigate(navLink); }} className={`navigation-item${isActive ? '-active' : ''}`} id="Schulungen_Btn">
            <img className="menuitem-image-icon" alt="" src={`${isActive ? '/menu-black.png' : '/menu-white.png'}`} />
            <div className="schulungen">{navItemText}</div>
        </button>
    );
};

export default NavigationItem;
