import React, { useEffect, useState } from 'react';
import '../kursdetails/Adminkursdetails.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSchulungByIDQuery, useUpdateSchulungMutation } from '../../../features/schulungen/admin/adminSchulungenSlice';
import { useLoading } from '../../../components/LoadingSpinner/LoadingContext';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { useDeleteInfoBereichMutation } from '../../../features/schulungen/admin/adminInfoSectionsSlice';
import AddInfoSection from '../../../components/admin/kursdetails/AddInfoSection';
import { useTranslation } from 'react-i18next';

const Adminkursarchiv = () => {

    const navigate = useNavigate()

    const { id } = useParams();

    const { data: schulung, error, isLoading, isSuccess, isError } = useGetSchulungByIDQuery(id);
    const [updateSchulung, { isLoading: isUpdating, isError: isUpdatingError, isSuccess: isUpdateSuccess }] = useUpdateSchulungMutation();
    const [isModalOpen, setIsModalOpen] = useState(false); // Zustand für das Modal
    const [selectedInformationsbereich, setSelectedInformationsbereich] = useState(null);
    const [informationsbereiche, setInformationsbereiche] = useState([]); // Zustand für die Informationsbereiche
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [deleteInformationsbereich, { isLoading: isDeletingInformationsbereich }] = useDeleteInfoBereichMutation()
    const [editingInfoBereich, setEditingInfoBereich] = useState(null);


    const { t } = useTranslation("common");

    const { startLoading, stopLoading } = useLoading();

    useEffect(() => {
        if (isLoading || isUpdating) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoading, isUpdating, startLoading, stopLoading]);


    useEffect(() => {
        if (isError) {
            navigate("/admin");
        }
    }, [isError, navigate])

    useEffect(() => {
        if (isSuccess)
            setInformationsbereiche(schulung.informationsbereiche)
    }, [isSuccess, schulung])


    useEffect(() => {
        if (isUpdateSuccess)
            navigate("/admin");
    }, [isUpdateSuccess, navigate])


    const handleRestoreSchulung = () => {
        if (schulung) {
            updateSchulung({ ...schulung, geloescht: false });
        }
    }

    const handleDeleteInformationsbereiche = () => {
        var informationsbereichToDelete = { schulungID: schulung.id, informationsbereichID: selectedInformationsbereich };
        deleteInformationsbereich(informationsbereichToDelete);
    }


    // Funktion zum Öffnen des Bearbeitungsmodals und Setzen des zu bearbeitenden Informationsbereichs
    const handleEditInformationsbereich = (informationsbereich) => {
        setEditingInfoBereich(informationsbereich);
        setIsEditModalOpen(true);
    }


    return (
        <div className="akd-admin-kursdetails">
            <Sidebar />
            <div className="akd-inner-window">
                <div className="akd-modul-comp">
                    <b className="akd-modulename"> {t("carrier.fka-Schulungsmodul")} {schulung?.name}
                    </b>
                </div>
                <div className="akd-inner-section">
                    <div className="akd-bereich-list">
                        <div className="akd-info-list-comp" onClick={() => navigate(`/admin/questions/${schulung?.id}`)}>
                            <b className="akd-modulename">{t("admin.pages.pages-akd-PruefungsfragenVerwalten")}</b>
                        </div>
                        <div className="akd-info-list-comp" style={{ backgroundColor: "#f68080" }} onClick={handleRestoreSchulung}>
                            <b className="akd-modulename">{t("admin.pages.pages-afrv-wiederherstellen")}</b>
                        </div>
                    </div>
                    <div className="akd-actions-list">
                        <div className="akd-upper-information-section">
                            <div className="akd-label">
                                <b className="akd-informationsbereiche">{t("carrierMaster.fr-kd-Informationsbereiche")}</b>
                            </div>
                            <div className="akd-actionsButtons">
                                <button className="akd-add-btn" onClick={() => setIsModalOpen(true)}>
                                    <div className="akd-hinzufgen">{t("admin.pages.pages-afts-Hinzufuegen")}</div>
                                </button>
                                <button className="akd-change-btn" onClick={() => {
                                    if (selectedInformationsbereich) {
                                        const informationsbereichToEdit = informationsbereiche.find(
                                            ib => ib.id === selectedInformationsbereich
                                        );
                                        handleEditInformationsbereich(informationsbereichToEdit);
                                    }
                                }}>
                                    <div className="akd-hinzufgen">{t("admin.pages.pages-akd-Bearbeiten")}</div>
                                </button>
                                <button className="akd-delete-btn2" onClick={() => { handleDeleteInformationsbereiche() }}>
                                    <div className="akd-hinzufgen">{t("admin.pages.pages-akd-Loeschen")}</div>
                                </button>
                            </div>
                        </div>
                        <div className="akd-frame-parent" style={{ height: "100%" }}>
                            <div className="akd-menuitems2" >
                                {
                                    schulung && informationsbereiche ?
                                        [...informationsbereiche] // Erstellen Sie eine Kopie des Arrays, um das ursprüngliche Array unverändert zu lassen
                                            .sort((a, b) => a.id - b.id) // Sortieren Sie das Array basierend auf der `id` in aufsteigender Reihenfolge
                                            .map((informationsbereich, index) => (
                                                <div
                                                    onClick={() => {
                                                        if (selectedInformationsbereich === informationsbereich.id)
                                                            setSelectedInformationsbereich(null);
                                                        else
                                                            setSelectedInformationsbereich(informationsbereich.id);
                                                        console.log("change in informationsbereich: " + selectedInformationsbereich);
                                                    }}
                                                    className="akd-menuitem2-1"
                                                >
                                                    <div
                                                        className="akd-menuitem2-1-child"
                                                        style={{ backgroundColor: selectedInformationsbereich === informationsbereich.id ? 'black' : 'white' }}
                                                    />
                                                    <div className="akd-grundlagen-wrapper">
                                                        <div className="akd-grundlagen">{informationsbereich.title}</div>
                                                    </div>
                                                </div>
                                            )) : null
                                }
                            </div>
                        </div>
                        {
                            isModalOpen && (
                                <AddInfoSection id={schulung?.id} onClose={() => { setIsModalOpen(false); }} />
                            )
                        }

                        {isEditModalOpen && (
                            <AddInfoSection id={schulung?.id} initalInfoBereich={editingInfoBereich} isEditing={true} onClose={() => { setIsEditModalOpen(false); }} />
                        )}
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Adminkursarchiv;