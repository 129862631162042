import { useEffect, useRef, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { useUpdateRoleMutation } from "../../../features/roles/adminRoleSlice";
import { useWhoamiQuery } from "../../../features/authApiSlice";
import { useTranslation } from "react-i18next";

const UserRoleTableComponent = ({ user, index }) => {

    const initialRole = useRef(user.role);

    const [selectedRole, setSelectedRole] = useState(initialRole.current);
    const [isChanged, setIsChanged] = useState(false);

    const [updateUserRole, { isLoading: isUpdateUserRoleLoading }] = useUpdateRoleMutation()

    const { data: userlogged, isLoading1, isSuccess1 } = useWhoamiQuery();

    const [t, i18n] = useTranslation("common");

    useEffect(() => {
        if (selectedRole !== user.role) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    }, [selectedRole, user.role]);

    const handleRoleChange = (e) => {
        setSelectedRole(e.target.value);
    };

    return (
        <>
            <div key={index} style={{ display: "flex", height: 60, padding: 10, backgroundColor: "lightgray" }}>
                <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", borderRight: "solid 2px black" }}>
                    {user.name}
                </div>
                <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", borderRight: "solid 2px black" }}>
                    {
                        (() => {
                            if (user.lastLogin === null) return "Keine Daten vorhanden"
                            let date = new Date(user.lastLogin);
                            let formattedDate = ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
                            return formattedDate;
                        })()
                    }
                </div>
                <div style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRight: "solid 2px black",
                    position: "relative",
                    textAlign: "center",
                }}>
                    <select
                        value={selectedRole}
                        onChange={(e) => handleRoleChange(e)}
                        style={{
                            alignSelf: "center",
                            width: "50%", // Adjust width to account for icon space
                            textAlign: "center",
                            backgroundColor: "white", // Set background to black
                            color: "black", // Set text color to white
                            appearance: "none",
                            WebkitAppearance: "none",
                            MozAppearance: "none",
                            paddingRight: '30px', // Make room for the icon
                            height: 30,
                            textAlignLast: "center",
                            fontSize: 14,
                        }}
                    >
                        <option disabled={user.name === "oberreiter" || userlogged.username === user.name} value="ROLE_SUPER_ADMIN">Super Admin</option>
                        <option disabled={user.name === "oberreiter" || userlogged.username === user.name} value="ROLE_ADMIN">Admin</option>
                        <option disabled={user.name === "oberreiter" || userlogged.username === user.name} value="ROLE_USER">User</option>
                    </select>
                    <div style={{
                        position: 'absolute',
                        right: '30%',
                        top: '51%',
                        transform: 'translateY(-50%)',
                        pointerEvents: 'none',
                    }}>
                        <AiFillCaretDown style={{ color: 'black', fontSize: '16px' }} />
                    </div>
                </div>

                <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <div style={{
                        backgroundColor: isChanged ? 'orange' : 'green', width: "50%", color: "white", textAlign: "center", padding: 10, borderRadius: 5, cursor: "pointer"
                    }}
                        onClick={() => {
                            console.log('updateUserRole', user, selectedRole)

                            if(user.username === "oberreiter") {
                                return;
                            }

                            updateUserRole({ user: { ...user, role: selectedRole } })
                        }
                        }>
                        {isChanged ?  t("admin.rollenverwaltung.rollenverwaltung-status-nicht-gespeichert") : t("admin.rollenverwaltung.rollenverwaltung-status-gespeichert")}
                    </div>
                </div>
            </div >

        </>
    )

}

export default UserRoleTableComponent;