import "./Login.css";
import React, { useState } from "react";
import CryptoJS from 'crypto-js';
import { useTranslation } from "react-i18next";

const Login = () => {

    const [t, i18n] = useTranslation('common');
    const codeVerifier = generateRandomString(64);
    window.localStorage.setItem('code_verifier', codeVerifier);
    const codeChallenge = generateCodeChallenge(codeVerifier);

    const handleLogin = () => {
        const clientId = "mts";
        const state = Math.random().toString(36).substring(7);
        window.sessionStorage.setItem('oauth2state', state);
        const url = `${process.env.REACT_APP_OAUTH_URL}?response_type=code&scope=carrier%20medlog&client_id=${clientId}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
        window.location.href = url;
    }

    function generateRandomString(length) {
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';

        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    function generateCodeChallenge(codeVerifier) {
        var hashedVerifier = CryptoJS.SHA256(codeVerifier);
        return base64UrlEncode(hashedVerifier);
    }

    function base64UrlEncode(str) {
        return str.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
    }


    return (
        <div className="lg-login">
            <img
                className="lg-background-image-icon"
                alt=""
                src="/medlog-pcbrowser-image-pasted-20230204-at-12-06-03-png-1@2x.png"
            />
            <div className="lg-form-container">
                <div className="lg-headline">
                    <span className="lg-headline-txt">
                        <p className="lg-headline-text">medlog Schulungsplattform</p>
                    </span>
                </div>
                <button onClick={() => handleLogin()} className="lg-login-btn" id="SignIn-Btn">
                    <b className="lg-login-btn-text">{t("login.login-Anmelden")}</b>
                </button>
                <p className="lg-headline-subtext" style={{ fontSize: 14 }}>Bei auftretenden Fehlern kontaktieren Sie bitte die zuständige Stelle unter:                <br></br>
                    <a href="mailto:wartung@helbal-it.com" style={{ color: '#007bff' }} >wartung@helbal-it.com</a>
                </p>

            </div>
        </div>
    );
};

export default Login;
