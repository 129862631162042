import { apiSlice } from "../../app/api/apiSlice";


export const adminRoleSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsersWithRoles: builder.query({
            query: () => ({
                url: `/admin/roles`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [
                { type: 'Medloguser', id: "LIST" },
                ...(result?.ids?.map(id => ({ type: 'Medloguser', id })) || [])
            ]
        }),
        updateRole: builder.mutation({
            query: ({ user }) => ({
                url: `/admin/roles/${user.id}`,
                method: 'PUT',
                body: user 
            }),
            invalidatesTags: [{ type: 'Medloguser', id: "LIST" }]
        })
    })
})

export const {
    useGetUsersWithRolesQuery,
    useUpdateRoleMutation
} = adminRoleSlice