import { createPortal } from "react-dom"
import Editor from "../../../components/admin/kursdetails/Editor";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { useGetPhishingSettingsQuery, useSendPhishingRequestMutation } from "../../../features/phishing/adminPhishingSlice";
import { useGetUsersWithRolesQuery } from "../../../features/roles/adminRoleSlice";

const PhishingSendMailModal = ({ open, onClose }) => {

    const [t] = useTranslation('common');

    const { data: phishingSettings, error, isLoading, isSuccess, isError } = useGetPhishingSettingsQuery()
    const [sendMail] = useSendPhishingRequestMutation();
    const { data, isSuccess: isDataSuccess } = useGetUsersWithRolesQuery()
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [fromMail, setFromMail] = useState("abrechnun@medlog.at");

    const [mailBody, setMailBody] = useState("");
    const [subject, setSubject] = useState("");


    const handleSendEmail = () => {

        console.log("selectedTemplate", selectedTemplate)
        console.log("selectedUser", selectedUser)
        console.log("fromMail", fromMail)
        sendMail({ templateId: selectedTemplate, employeeId: selectedUser, fromMail });
        onClose();
    }



    if (!open) return null;

    // return createPortal(
    //     <div className="admin-modal-informationsbereic">
    //         <input type="text"
    //             // value={ } 
    //             // onChange={(e) => setTitle(e.target.value)} 
    //             // placeholder={t("admin.akd.akd-ais-PlaceholderTitel")} 
    //             className="ami-input-frage" />

    //         <div className="ami-mid-section">
    //             <div className="ami-inner-section">
    //                 <Editor />
    //             </div>
    //         </div>

    //         <div className="ami-buttons">
    //             <button className="ami-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={() => onClose()}>
    //                 <div className="ami-uebersichtsnav-btn-text">{t("admin.akd.akd-ais-Zuruek")}</div>
    //             </button>
    //             <button className="ami-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={() => console.log("test")}>
    //                 <div className="ami-uebersichtsnav-btn-text">{t("admin.akd.akd-ais-Speichern")}</div>
    //             </button>
    //         </div>
    //     </div >,
    //     document.getElementById('modal-root')
    // );

    return createPortal(
        <div className="addfahrertoschulung" style={{ display: "flex", flexDirection: "column", padding: 50 }}>
            <div style={{ flex: 1, display: "flex", flexDirection: "column", width: "100%", gap: 10, alignSelf: "center" }}>
                <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", justifyContent: "center", position: "relative" }}>
                    <div style={{ position: "absolute", marginLeft: 10, marginRight: 20, fontFamily: "Verdana", fontWeight: "bold" }}>Von:</div>
                    <select
                        className="ape-fahrer-input"
                        style={{
                            flex: 1, alignSelf: "center", width: "100%", textAlign: "start", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white", color: "black",
                            appearance: "none", WebkitAppearance: "none", MozAppearance: "none", paddingRight: '30px', fontFamily: "Verdana", // adjust the right padding to make space for the icon
                            paddingLeft: '60px'  // Hier fügen Sie einen linken Innenabstand hinzu

                        }}

                    onChange={e => setFromMail(e.target.value)}
                    value={fromMail}
                    >
                        <option style={{ fontFamily: "Verdana" }} value="abrechnun@medlog.at">abrechnun@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="mikko.oberrreiter@medlog.at">mikko.oberrreiter@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="franz.holer@medlog.at">franz.holer@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="martin.micula@medlog.at">martin.micula@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="buchaltung@medlog.at">buchaltung@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="kundeservice@medlog.at">kundeservice@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="birgit.eber@medlog.at">birgit.eber@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="martin.nemez@medlog.at">martin.nemez@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="sunil.bilani@medlog.at">sunil.bilani@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="thomas.palan@medlog.at">thomas.palan@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="christian.knoteg@medlog.at">christian.knoteg@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="anna.davia@medlog.at">anna.davia@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="andrea.schmidt@medlog.at">andrea.schmidt@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="daniela.guenther@medlog.at">daniela.guenther@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="egin.agil@medlog.at">egin.agil@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="katrin.ratmann@medlog.at">katrin.ratmann@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="patrizia.barisic@medlog.at">patrizia.barisic@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="deian.marinkovic@medlog.at">deian.marinkovic@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="andreas.karner@medlog.at">andreas.karner@medlog.at</option>
                        <option style={{ fontFamily: "Verdana" }} value="birgit.vraner@medlog.at">birgit.vraner@medlog.at</option>
                    </select>

                    <div style={{
                        position: 'absolute',
                        right: "1%",
                        top: '55%',
                        transform: 'translateY(-50%)',
                        pointerEvents: 'none',
                    }}>
                        <AiFillCaretDown style={{ color: 'black', fontSize: '16px' }} />
                    </div>
                </div>

                <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", justifyContent: "center", position: "relative" }}>
                    <div style={{ position: "absolute", marginLeft: 10, marginRight: 20, fontFamily: "Verdana", fontWeight: "bold" }}>An:</div>
                    <select
                        className="ape-fahrer-input"
                        style={{
                            flex: 1, alignSelf: "center", width: "100%", textAlign: "start", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white", color: "black",
                            appearance: "none", WebkitAppearance: "none", MozAppearance: "none", paddingRight: '30px', fontFamily: "Verdana", // adjust the right padding to make space for the icon
                            paddingLeft: '60px'  // Hier fügen Sie einen linken Innenabstand hinzu
                        }}
                        onChange={e => setSelectedUser(e.target.value)}
                        value={selectedUser}
                    >
                        <option value={null}>Keinen</option>
                        {isDataSuccess && data.map((user) => (
                            <option style={{ fontFamily: "Verdana" }} value={user.id}>{user.name}</option>
                        ))}
                    </select>

                    <div style={{
                        position: 'absolute',
                        right: "1%",
                        top: '55%',
                        transform: 'translateY(-50%)',
                        pointerEvents: 'none',
                    }}>
                        <AiFillCaretDown style={{ color: 'black', fontSize: '16px' }} />
                    </div>
                </div>
                <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", justifyContent: "center", position: "relative", flexDirection: "row", gap: 10 }}>
                    <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", justifyContent: "center", position: "relative" }}>
                        <div style={{ position: "absolute", marginLeft: 10, marginRight: 20, fontFamily: "Verdana", fontWeight: "bold" }}>Vorlage:</div>
                        <select
                            className="ape-fahrer-input"
                            style={{
                                flex: 1, alignSelf: "center", width: "100%", textAlign: "start", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white", color: "black",
                                appearance: "none", WebkitAppearance: "none", MozAppearance: "none", paddingRight: '30px', fontFamily: "Verdana", // adjust the right padding to make space for the icon
                                paddingLeft: '80px'  // Hier fügen Sie einen linken Innenabstand hinzu

                            }}

                            onChange={e => {
                                const selectedId = e.target.value;
                                const foundTemplate = phishingSettings.templates.find(template => template.id === Number(selectedId));
                                setSelectedTemplate(e.target.value);
                                setSubject(foundTemplate.subject);
                                setMailBody(foundTemplate.body);
                            }}
                            value={selectedTemplate ? selectedTemplate.title : ""}
                        >
                            <option value={null}>Keine</option>
                            {isSuccess && phishingSettings.templates.map((template) => (
                                <option style={{ fontFamily: "Verdana" }} value={template.id}>{template.title}</option>

                            ))}
                        </select>

                        <div style={{
                            position: 'absolute',
                            right: "1%",
                            top: '55%',
                            transform: 'translateY(-50%)',
                            pointerEvents: 'none',
                        }}>
                            <AiFillCaretDown style={{ color: 'black', fontSize: '16px' }} />
                        </div>

                    </div>
                    <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", justifyContent: "center", position: "relative" }}>
                        <div style={{ position: "absolute", marginLeft: 10, marginRight: 20, fontFamily: "Verdana", fontWeight: "bold" }}>Betreff:</div>
                        <input
                            className="ape-fahrer-input"
                            style={{
                                flex: 1, paddingLeft: 80
                            }}

                            onChange={e => setSubject(e.target.value)}
                            value={subject}
                        >
                        </input>
                    </div>
                </div>

            </div>
            <div style={{ flex: 3, display: "flex", flexDirection: "column", width: "100%", gap: 10, backgroundColor: "white" }}>
                <Editor value={mailBody} onChange={setMailBody} hasPhishing={true}/>
            </div>
            <div className="ami-buttons">
                <button className="ami-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={() => onClose()}>
                    <div className="ami-uebersichtsnav-btn-text">{t("admin.akd.akd-ais-Zuruek")}</div>
                </button>
                <button className="ami-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={handleSendEmail}>
                    <div className="ami-uebersichtsnav-btn-text">Senden</div>
                </button>
            </div>
        </div>,
        document.getElementById('modal-root-2') // This is the target container
    );


}

export default PhishingSendMailModal;