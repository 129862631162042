import { apiSlice } from "../../../app/api/apiSlice";


export const adminFahrerSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFahrerList: builder.query({
            query: schulungs_id => ({
                url: `/admin/carrier/schulungen/${schulungs_id}`,
                method: 'GET'
            }),
            providesTags: (result, error, schulungs_id) => [
                { type: 'FahrerZugeordnetList', id: "LIST" }
            ]
        }),
        postFahrerToSchulung: builder.mutation({
            query: ({ schulungs_id, fahrer_id }) => ({
                url: `/admin/carrier/${fahrer_id}/schulungen/${schulungs_id}`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, { schulungs_id }) => [
                { type: 'FahrerZugeordnetList', schulungs_id },
                { type: 'FahrerPruefungen', id: 'LIST' } // Dies invalidiert das Tag für getPruefungsErgebnisse Query
            ],
        }),
        deleteFahrerFromSchulung: builder.mutation({
            query: ({ schulungs_id, fahrer_id }) => ({
                url: `/admin/carrier/${fahrer_id}/schulungen/${schulungs_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { schulungs_id }) => [
                { type: 'FahrerZugeordnetList', schulungs_id },
                { type: 'FahrerPruefungen', id: 'LIST' } // Dies invalidiert das Tag für getPruefungsErgebnisse Query
            ],
        }),
    })
})

export const {
    useGetFahrerListQuery,
    usePostFahrerToSchulungMutation,
    useDeleteFahrerFromSchulungMutation
} = adminFahrerSlice