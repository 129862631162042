import FailedCourseActionsListContain from "./FailedCourseActionsListContain";
import "./PruefungNichtBestanden.css";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
const FraechterPruefungNichtBestanden = () => {
  const [t] = useTranslation("common");
  const { id } = useParams(); 
  return (
    <div className="fahrer-pruefung-nichtbestanden">
      <div className="inner-window3">
        <FailedCourseActionsListContain
          courseResultMessage={t("carrier.pruefung.pnb-KursNichtBestanden")}
          actionButtonText={t("carrier.pruefung.pnb-Wiederholen")}
          id={id}
        />
      </div>
      <Sidebar />
    </div>
  );
};

export default FraechterPruefungNichtBestanden;
