import "./FR-Kursdetails.css";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useGetSchulungenForCarrierMasterByIDQuery } from "../../features/schulungen/carrierMaster/carrierMasterSchulungenSlice";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
const FraechterKursdetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const { data: schulung, isLoading, isSuccess } = useGetSchulungenForCarrierMasterByIDQuery(id);


    useEffect(() => {
        if (isLoading) {
            console.log("loading")
        } else {
            console.log("not loading")
        }
    }, [isLoading])

    useEffect(() => {
        if (isSuccess) {
            console.log("data: ", schulung)
        }
    }, [isSuccess, schulung]);


    return (
        <div className="frkd-kursdetails">
            <Sidebar />
            <div className="frkd-inner-window">
                <div className="frkd-modul-comp">
                    <b className="frkd-modulname"> {t("carrier.fka-Schulungsmodul")} {schulung?.name}
                    </b>
                </div>
                <div className="frkd-inner-section">
                    <div className="frkd-bereich-list">
                        <div className="frkd-info-list-comp" onClick={() => { navigate(`/carrierMaster/details/results/${id}`) }}>
                            <b className="frkd-modulname">{t("carrierMaster.fr-kd-Pruefungsergebnisse")}</b>
                        </div>
                        <div className="frkd-lower-details-section">
                            <div className="frkd-date2">
                                <div className="frkd-gltigkeitsdauer-ndern">
                                    {t("carrierMaster.fr-kd-Gueltigkeitsdauer")} {schulung?.gueltigkeitsdauer}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="frkd-actions-list">
                        <div className="frkd-upper-information-section">
                            <div className="frkd-label">
                                <b className="frkd-informationsbereiche">{t("carrierMaster.fr-kd-Informationsbereiche")}</b>
                            </div>
                            {
                                schulung?.informationsbereiche ?
                                    schulung.informationsbereiche.map((bereich, index) => (
                                        <div key={index} className="frkd-grundlagen-wrapper">
                                            <div className="frkd-grundlagen">{bereich.title}</div>
                                        </div>
                                    ))
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default FraechterKursdetails;


