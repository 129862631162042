import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";

const carrierMasterSchulungenAdapter = createEntityAdapter()

const initialState = carrierMasterSchulungenAdapter.getInitialState()

export const carrierMasterSchulungenSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSchulungenForCarrierMaster: builder.query({
            query: () => ({
                url: '/carrierMaster/carrier/schulungen',
                method: 'GET'
            }),
            transformResponse: responseData => {
                return carrierMasterSchulungenAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => [
                { type: 'CarrierMasterSchulung', id: "LIST" },
                ...result?.ids.map(id => ({ type: 'CarrierMasterSchulung', id }))
            ]
        }),
        getPruefungsErgebnisseFraechter: builder.query({
            query: () => ({
                url: `/carrierMaster/carrier/schulungen/pruefungen`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [
                { type: 'FahrerPruefungen', id: "LIST" },
            ]
        }),
        getSchulungenForCarrierMasterByID: builder.query({
            query: (id) => ({
                url: `/carrierMaster/carrier/schulungen/${id}`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [
                { type: 'CarrierMasterSchulung', id: arg },
            ]
        }),
    })
})

export const {
    useGetSchulungenForCarrierMasterQuery,
    useGetPruefungsErgebnisseFraechterQuery,
    useGetSchulungenForCarrierMasterByIDQuery
} = carrierMasterSchulungenSlice


export const selectGetSchulungenResult = carrierMasterSchulungenSlice.endpoints.getSchulungenForCarrierMaster.select();

// Selektor zum Abruf der Daten aus dem 'getSchulungen' Ergebnis
export const selectSchulungenData = createSelector(
    // Verwende den oben definierten Selektor als Eingabe
    selectGetSchulungenResult,
    // Die zurückgegebene Datenstruktur ist in `data` enthalten
    getSchulungenResult => getSchulungenResult.data
);

export const {
    selectAll: selectAllSchulungen,
    selectById: selectSchulungById,
    selectIds: selectSchulungenIds
    // Pass in a selector that returns the posts slice of state
} = carrierMasterSchulungenAdapter.getSelectors(state => selectSchulungenData(state) ?? initialState)