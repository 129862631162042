import { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import "./AdminFragenverwaltung.css";
import { useNavigate, useParams } from "react-router-dom";
import AddQuestionPicture from "../../../components/admin/fragenverwaltung/AddQuestionPicture";
import { useGetSchulungByIDQuery } from "../../../features/schulungen/admin/adminSchulungenSlice";
import { useUpdatePruefungMutation } from "../../../features/schulungen/admin/adminPruefungenSlice";
import { useDeleteQuestionMutation } from "../../../features/schulungen/admin/adminQuestionSlice";
import { useLoading } from "../../../components/LoadingSpinner/LoadingContext";
import { useTranslation } from "react-i18next";
const AdminFragenverwaltung = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [t] = useTranslation('common');
    //Modal
    const [isSecondBildModalOpen, setIsSecondBildModalOpen] = useState(false);

    const [editingQuestion, setEditingQuestion] = useState(null);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [editModalBildOpen, setEditModalBildOpen] = useState(false);


    const [fragenPool, setFragenPool] = useState(0);
    const [pruefungsWiederholungen, setPruefungsWiederholungen] = useState(0);
    const [bestehungsgrenze, setBestehungsgrenze] = useState(0);

    const { data: schulung, isLoading, } = useGetSchulungByIDQuery(id);
    const [deleteQuestion, { isLoading: isDeleting }] = useDeleteQuestionMutation();
    const [updatePruefung, { isLoading: isUpdating }] = useUpdatePruefungMutation();

    const { startLoading, stopLoading } = useLoading();

    useEffect(() => {
        if (isLoading || isDeleting || isUpdating) {
            startLoading();
        } else {
            stopLoading();
            setFragenPool(schulung?.pruefung?.fragenpool);
            setPruefungsWiederholungen(schulung?.pruefung?.wiederholungen);
            setBestehungsgrenze(schulung?.pruefung?.bestehungsgrenze)
            console.log(schulung)
        }
    }, [isLoading, isDeleting, isUpdating, startLoading, stopLoading, schulung]);


    const handleSaveClick = () => {
        if (schulung) {
            //Werte dürfen nicht negativ sein
            updatePruefung({ schulungsId: id, ...schulung.pruefung, fragenpool: Number(fragenPool) < 0 ? 0 : Number(fragenPool), wiederholungen: Number(pruefungsWiederholungen) < 0 ? 0 : Number(pruefungsWiederholungen), bestehungsgrenze: Number(bestehungsgrenze) < 0 ? 0 : Number(bestehungsgrenze) });
        }
    };

    const toggleSelectQuestion = (index) => {
        if (selectedQuestions.includes(index)) {
            setSelectedQuestions(prev => prev.filter(i => i !== index));
        } else {
            setSelectedQuestions(prev => [...prev, index]);
        }
    };
    const deleteSelectedQuestions = () => {
        // Hier können Sie den `removeQuestionFromSchulung` für jeden ausgewählten Index aufrufen
        // oder eine neue Methode im Kontext implementieren, um mehrere Fragen gleichzeitig zu entfernen.
        selectedQuestions.sort((a, b) => b - a).forEach(id => {
            deleteQuestion({ schulungsId: schulung.id, questionID: id, pruefungsID: schulung.pruefung.id })
        });
        setSelectedQuestions([]); // Reset ausgewählte Fragen
    };

    const openEditBildModal = (question) => {
        // Hier speichern Sie die aktuelle Frage und ihren Index, um sie später zu aktualisieren
        setEditingQuestion({ ...question, });
        setEditModalBildOpen(true);
    };


    return (
        <div className="admin-fragenverwaltung">
            <Sidebar
                dimensionCode="/settingsicon5.svg"
                carDimensions="/logouticon4.svg"
            />
            <div className="afw-innerwindow">
                <div>
                    {t("admin.pages.pages-afrv-Ueberschrift")} <b>{schulung?.name}</b>
                </div>
                <div className="afw-fragen-section">
                    {
                        schulung?.pruefung?.questions
                            ? [...schulung.pruefung.questions] // Erstellen einer Kopie des Arrays
                                .sort((a, b) => a.id - b.id) // Sortieren des Arrays nach der `id`
                                .map((question, index) => (
                                    <div className="afw-list-component" key={index}>
                                        <div className="afw-list-component-upper-secti">
                                            <div className="afw-lc-text-section">
                                                <b className="afw-fragennummer">{`Frage ${index + 1}:`}</b>
                                                <div className="afw-fragentext">
                                                    {question.frage}
                                                </div>
                                            </div>
                                            {/* Dieses Kontrollkästchen könnte für das Auswählen der gesamten Frage verwendet werden. 
                     Wenn es nicht benötigt wird, können Sie es entfernen */}
                                            <input className="afw-checkbox-upper" onChange={() => toggleSelectQuestion(question.id)}
                                                type="checkbox" />
                                        </div>

                                        <div className="afw-inner-fragen-section">

                                            {question.image && ( // Wenn die Frage ein Bild hat, zeigen Sie es an
                                                <div className="afw-questionImage">
                                                    <img width={100} src={question.image} alt="Fragebild" />
                                                </div>
                                            )}


                                            {question.answers.map((answer, ansIndex) => (
                                                <div className="afw-lc-text-section" key={ansIndex}>
                                                    {/* Dieses Kontrollkästchen zeigt an, ob die Antwort richtig oder falsch ist */}
                                                    <input className="afw-checkbox-upper" type="checkbox" checked={answer.true} readOnly />

                                                    {answer.image ? ( // Wenn die Antwort ein Bild hat, zeigen Sie es an}
                                                        <img width={100} src={answer.image} alt={`Antwortbild ${ansIndex + 1}`} />
                                                    ) : (
                                                        <div className="ambh-checkbox-text">{answer.text}</div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="afw-button-section">
                                            <button
                                                className="afw-filter-btn"
                                                id="afw-delete-btn"
                                                onClick={() => { deleteQuestion({ schulungsId: schulung.id, questionID: question.id, pruefungsID: schulung.pruefung.id }) }}//removeQuestionFromSchulung(schulung.schulungsName, index)}
                                            > <div className="afw-btn-text">{t("admin.pages.pages-akd-Loeschen")}</div>
                                            </button>

                                            <button onClick={() => {
                                                openEditBildModal(question, index)
                                            }
                                            }
                                                className="afw-filter-btn1" id="bearbeiten-btn">
                                                <div className="afw-btn-text">{t("admin.pages.pages-akd-Bearbeiten")}</div>
                                            </button>
                                        </div>
                                    </div>
                                )) : null
                    }
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignSelf: "stretch", height: "100px" }}>
                    <div style={{ flex: 1, alignSelf: "stretch", height: "30px", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "25px" }}>
                        <button style={{ flex: 1, backgroundColor: "#ffa433", border: "1px solid black", borderRadius: 5, padding: 10, fontSize: 12, fontFamily: "Verdana", color: "#fff", fontWeight: 600, cursor: "pointer", visibility: selectedQuestions.length > 1 ? "visible" : "hidden" }} onClick={() => setIsSecondBildModalOpen(true)}>
                            {t("admin.pages.pages-afrv-FrageAnlegen")}
                        </button>
                        <label className="afw-label" style={{ flex: 2, alignSelf: "flex-end" }}>{t("admin.pages.pages-afrv-Wiederholungen")}
                        </label>
                        <label className="afw-label" style={{ flex: 2, alignSelf: "flex-end" }}>{t("admin.pages.pages-afrv-Fragenpool")}
                        </label>
                        <label className="afw-label" style={{ flex: 2, alignSelf: "flex-end" }}>{t("admin.pages.pages-afrv-Bestehungsgrenze")}
                        </label>
                        <button className="afw-uebersichtsnav-btn" id="ModulVerw_Btn" style={{ flex: 1 }} onClick={() => handleSaveClick()}>
                            <div className="afw-uebersichtsnav-btn-text" >
                                {t("admin.pages.pages-afrv-Speichern")}
                            </div>
                        </button>
                    </div>
                    <div style={{ flex: 1, alignSelf: "stretch", alignItems: "stretch", height: "30px", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "25px" }}>
                        {selectedQuestions.length > 1 ? (
                            <button style={{ flex: 1, backgroundColor: "#3a3a3a", border: "1px solid black", borderRadius: 5, padding: 10, fontSize: 12, fontFamily: "Verdana", color: "#fff", fontWeight: 600, height: "100%" }} onClick={deleteSelectedQuestions}>
                                {t("admin.pages.pages-afrv-FrageLoeschen")}
                            </button>
                        ) : (
                            <button style={{ flex: 1, backgroundColor: "#ffa433", border: "1px solid black", borderRadius: 5, padding: 10, fontSize: 12, fontFamily: "Verdana", color: "#fff", fontWeight: 600, height: "100%" }} onClick={() => setIsSecondBildModalOpen(true)}>
                                {t("admin.pages.pages-afrv-FrageAnlegen")}
                            </button>
                        )}
                        <div style={{ flex: 2 }}
                        >
                            <input
                                value={pruefungsWiederholungen}
                                onChange={(e) => {
                                    const value = Math.max(0, Math.min(100, Number(e.target.value)));
                                    setPruefungsWiederholungen(value);
                                }}
                                placeholder="0"
                                type="number"
                                min="0"
                                max="100"
                                style={{ width: "100%", height: "90%" }}
                                className="afw-input-new"
                            />
                        </div>
                        <div style={{ flex: 2 }}
                        >
                            <input
                                value={fragenPool}
                                onChange={(e) => {
                                    const value = Math.max(0, Math.min(100, Number(e.target.value)));
                                    setFragenPool(value);
                                }}
                                placeholder="0"
                                type="number"
                                min="0"
                                style={{ width: "100%", height: "90%" }}
                                max="100"
                                className="afw-input-new"
                            />
                        </div>
                        <div style={{ flex: 2 }}
                        >
                            <input
                                value={bestehungsgrenze}
                                onChange={(e) => {
                                    const value = Math.max(0, Math.min(100, Number(e.target.value)));
                                    setBestehungsgrenze(value);
                                }}
                                placeholder="0"
                                type="number"
                                className="afw-input-new"
                                min="0"
                                style={{ width: "100%", height: "90%" }}
                                max="100"
                            />
                        </div>
                        <button className="afw-uebersichtsnav-btn" id="ModulVerw_Btn" style={{ flex: 1 }} onClick={() => navigate(-1)}>
                            <div className="afw-uebersichtsnav-btn-text" >
                                {t("admin.pages.pages-afrv-ZuruekZurUebersicht")}
                            </div>
                        </button>

                    </div>
                </div>



                {/* <div className="afw-lower-section">
                    <div className="afw-fragen-actions-buttons">
                        <button className="afw-fragen-btn" onClick={() => setIsSecondBildModalOpen(true)}>
                            <div className="afw-btn-text">{t("admin.pages.pages-afrv-FrageAnlegen")}</div>
                        </button>
                        {selectedQuestions.length > 1 && (
                            <button className="afw-fragen-btn1" onClick={deleteSelectedQuestions}>
                                <div className="afw-btn-text">{t("admin.pages.pages-afrv-FrageLoeschen")}</div>
                            </button>
                        )}
                    </div>
                    <div className="afw-fragen-actions-buttons" style={{ flexDirection: "row" }}>
                        <div >
                            <label className="afw-label">{t("admin.pages.pages-afrv-Wiederholungen")}
                                <input
                                    value={pruefungsWiederholungen}
                                    onChange={(e) => setPruefungsWiederholungen(e.target.value)}
                                    className="afw-input"
                                    placeholder="0"
                                    type="number"
                                />
                            </label>
                        </div>
                        <div>
                            <label className="afw-label">{t("admin.pages.pages-afrv-Fragenpool")}
                                <input
                                    value={fragenPool}
                                    onChange={(e) => setFragenPool(e.target.value)}
                                    className="afw-input"
                                    placeholder="0"
                                    type="number"
                                />
                            </label>
                        </div>
                        <div >
                            <label className="afw-label">{t("admin.pages.pages-afrv-Bestehungsgrenze")}
                                <input
                                    value={bestehungsgrenze}
                                    onChange={(e) => {
                                        const value = Math.max(0, Math.min(100, Number(e.target.value)));
                                        setBestehungsgrenze(value);
                                    }}
                                    className="afw-input"
                                    placeholder="0"
                                    type="number"
                                    min="0"
                                    max="100"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="afw-fragen-actions-buttons">
                        <button className="afw-uebersichtsspeichern-btn" id="ModulVerw_Btn" onClick={() => handleSaveClick()}>
                            <div className="afw-uebersichtsnav-btn-text" >
                                {t("admin.pages.pages-afrv-Speichern")}
                            </div>
                        </button>
                        <button className="afw-uebersichtsnav-btn" id="ModulVerw_Btn" onClick={() => navigate(-1)}>
                            <div className="afw-uebersichtsnav-btn-text" >
                                {t("admin.pages.pages-afrv-ZuruekZurUebersicht")}
                            </div>
                        </button>
                    </div> 
                </div>*/}
                {
                    (fragenPool < 1 || pruefungsWiederholungen < 1 || bestehungsgrenze < 1) &&
                    <div className="afw-label" style={{ color: "gray" }}>Hinweis: Bitte für jedes Eingabefeld einen Wert wählen!</div>
                }
            </div>

            {
                isSecondBildModalOpen && (
                    <AddQuestionPicture pruefungsID={schulung?.pruefung.id} schulungID={schulung?.id} onClose={() => { setIsSecondBildModalOpen(false); }} />
                )
            }

            {
                editModalBildOpen && (
                    <AddQuestionPicture pruefungsID={schulung?.pruefung.id} isEditing={true} schulungID={schulung?.id}
                        existingQuestion={editingQuestion}
                        onClose={() => { setEditModalBildOpen(false); setEditingQuestion(null); }} />
                )
            }


        </div >
    );
};

export default AdminFragenverwaltung;
