import { apiSlice } from "../../../app/api/apiSlice";

export const adminQuestionSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        postQuestion: builder.mutation({
            query: ({ schulungID, id, ...data }) => ({
                url: `/admin/questions/${id}`,
                method: 'POST',
                body: data.question
            }),
            invalidatesTags: (result, error, { schulungID }) => [{ type: 'Schulung', id: schulungID }],
        }),
        updateQuestion: builder.mutation({
            query: ({ schulungID, id, ...data }) => ({
                url: `/admin/questions/${id}`,
                method: 'PUT',
                body: data.question
            }),
            invalidatesTags: (result, error, { schulungID }) => [{ type: 'Schulung', id: schulungID }],
        }),
        deleteQuestion: builder.mutation({
            query: ({ schulungsId, pruefungsID, questionID, ...data }) => ({
                url: `/admin/questions/${pruefungsID}/${questionID}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { schulungsId }) => [{ type: 'Schulung', id: schulungsId }],
        }),
    })
})

export const {
    usePostQuestionMutation,
    useUpdateQuestionMutation,
    useDeleteQuestionMutation
} = adminQuestionSlice
